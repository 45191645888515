import { yupResolver } from '@hookform/resolvers/yup';
import { ImagePlus, Ticket01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import { getShortName, onError } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import defaultImage from '../../assets/img/default-image.jpg';

interface IOnBoardCustomer {
  action: string;
  setAction: any;
  handleClose: any;
}

const AddOnBoardCustomer = ({ handleClose }: IOnBoardCustomer) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const [preview, setPreview] = useState<string | null>(null);

  const validationSchema = yup.object().shape({
    customerName: yup.string().required('Name is required.'),
    email: yup
      .string()
      .required('Name is required.')
      .test({
        message: 'Invalid email address',
        test: function (value) {
          if (value != '') {
            const emailRegex =
              /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

            return emailRegex.test(value ?? '');
          }

          return true;
        },
      }),
    customerLogo: yup
      .mixed()
      .nullable()
      .test({
        name: 'fileSize',
        message: 'Image size should be less than 2MB.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= MAX_FILE_SIZE;
        },
      }),

    website: yup.string().required('Carrier is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      customerName: '',
      email: '',
      customerLogo: defaultImage,
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setPreview(null);
    const file = acceptedFiles[0];
    setValue('customerLogo', file, { shouldValidate: true });

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          setPreview(img.src);
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const onSubmit = async (formData: any) => {
    console.log('formData :>> ', formData);

    handleClose(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <CommonModal
      title="OnBoarding New Customer"
      titleDesc="You can edit your details"
      handleClose={() => handleClose(false)}
      headerIcon={<Ticket01 className="w-5 h-5" />}
      // headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      size={'max-w-[640px] md:min-h-[auto] min-h-[90vh] max-h-[668px]'}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass="md:!px-8 !p-5 hover-text-wrap"
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      closeBtnClass="[&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
      modalClassName="flex flex-col "
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnText="Send mail"
      secondaryBtnText="Cancel"
    >
      <div className="py-5 px-4">
        <div className="w-full md:flex pb-4 border-b border-utilityGray200 mb-4">
          <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-40">
            Customer Logo
          </p>
          <div className="position-relative flex-1">
            <div className="flex md:gap-4 gap-2 ">
              {preview ? (
                <img
                  className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                  src={preview}
                  alt=""
                  title=""
                  onError={onError}
                />
              ) : getValues('customerName') ? (
                <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                  {getShortName(`${getValues('customerName')}`)}
                </label>
              ) : (
                <label className="w-14 h-14 border border-black/[0.08] rounded-lg object-cover flex justify-center items-center bg-utilityGray100 text-gray500 flex-none">
                  <ImagePlus />
                </label>
              )}

              <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                <label className="cursor-pointer" {...getRootProps()}>
                  <progress
                    className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                    value="70"
                    max="100"
                  ></progress>
                  <div
                    className="text-grayLight600 text-xs font-normal"
                    id="uploadPic"
                  >
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop&nbsp;
                      <br className="sms:block hidden"></br>
                      <span className="text-[11px] leading-[18px]">
                        SVG, PNG or JPG
                      </span>
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="image"
                  id="uploadPic"
                  {...getInputProps()}
                  className="hidden"
                />
              </div>
            </div>
            <div className="w-full">
              {errors.customerLogo && (
                <ErrorMsg errorText={`${errors.customerLogo.message} `} />
              )}
            </div>
          </div>
        </div>
        <Controller
          name="customerName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Customer Name"
              inputName="customerName"
              placeholder="Customer Name"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              errorText={
                errors.customerName ? errors.customerName.message : null
              }
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Email"
              inputName="customerEmail"
              placeholder="Email"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              errorText={
                errors.customerName ? errors.customerName.message : null
              }
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="website"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Website"
              inputName="website"
              placeholder="Website"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              errorText={errors.website ? errors.website.message : null}
              labelClassName="block mb-1.5"
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default AddOnBoardCustomer;
