import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './CreditIndexChartCss.scss';

interface IProps {
  score: any;
  lastUpdated: any;
}

const CreditIndexChart = ({ score, lastUpdated }: IProps) => {
  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '65%',
        },
        track: {
          background: '#fff',
          strokeWidth: '100%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        colorStops: [
          {
            offset: 0,
            color: '#E72730', // Start color
            opacity: 1,
          },
          {
            offset: 20,
            color: '#D3A333',
            opacity: 1,
          },
          {
            offset: 80,
            color: '#F3992D',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#4EC648', // End color
            opacity: 1,
          },
        ],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      dashArray: 3,
    },
  };

  const series = [score];

  return (
    <div className="max-w-sm mx-auto text-center relative">
      <ReactApexChart
        options={options as any}
        series={series}
        type="radialBar"
        height={350}
      />
      <div className="chart-labels">
        <div
          className="text-sm text-gray-600"
          style={{
            position: 'absolute',
            left: '3%',
            bottom: '11%',
            transform: 'translateY(-50%)',
          }}
        >
          0
        </div>
        <div
          className="text-sm text-gray-600"
          style={{
            position: 'absolute',
            left: '16%',
            top: '25%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          25
        </div>
        <div
          className="text-sm text-gray-600"
          style={{
            position: 'absolute',
            left: '50%',
            top: '-4%',
            transform: 'translateX(-50%)',
          }}
        >
          50
        </div>
        <div
          className="text-sm text-gray-600"
          style={{
            position: 'absolute',
            right: '13%',
            top: '25%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          75
        </div>
        <div
          className="text-sm text-gray-600"
          style={{
            position: 'absolute',
            right: '-1%',
            bottom: '11%',
            transform: 'translateY(-50%)',
          }}
        >
          100
        </div>
      </div>

      <div className="-mt-20">
        <h3 className="text-3xl m-0">{score}</h3>
        <p className="text-base my-1 text-gray-600">High</p>
        <p className="text-base my-1 text-gray-600">
          Last Updated: {lastUpdated}
        </p>
      </div>
    </div>
  );
};

export default CreditIndexChart;
