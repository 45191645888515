import React from 'react';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

const CustomerGroupLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-[17rem] ">
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
          WA
        </div>

        <div className="flex items-center gap-1 custom-loading">
          <span className="truncate block max-w-48 text-grayLight900 font-semibold leading-5">
            name
          </span>
        </div>
      </div>
    </td>
    <td className="px-5 py-3 min-w-[270px]">
      <div className="flex [&>div:not(:first-child)]:border-white [&>div:not(:first-child)]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
        <div className="rounded-full bg-white custom-loading">
          <img
            src={TeamIcon1}
            className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full"
          />
        </div>
        <div className="rounded-full bg-white custom-loading">
          <img
            src={TeamIcon1}
            className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full "
          />
        </div>
        <div className="rounded-full bg-white custom-loading">
          <img
            src={TeamIcon1}
            className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full "
          />
        </div>
        <div className="rounded-full bg-white custom-loading">
          <img
            src={TeamIcon1}
            className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full "
          />
        </div>
        <div className="rounded-full bg-utilityGray100 custom-loading">
          <p className="text-grayLight600 text-sm font-semibold w-8 h-8 flex items-center justify-center flex-none">
            +5
          </p>
        </div>
      </div>
    </td>
  </>
);

export default CustomerGroupLoaderRow;
