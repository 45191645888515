import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CreditTabOrderRaw = ({ data }: { data: any }) => (
  <>
    <td className="px-5 py-6">
      <h6 className="text-grayLight900 font-semibold leading-tight ">
        {data?.fullId}
      </h6>
    </td>
    <td className="px-5 py-6 font-normal ">
      <div className="flex gap-1">
        {data?.orderStatus === 'invoice-sent' && (
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-utilityPink600"
            mainClassName=" mr-1"
          >
            Invoice Sent
          </BadgeCmp>
        )}
        {data?.orderStatus === 'delivered' && (
          <BadgeCmp style="modern" type="lightblue" mainClassName=" mr-1">
            Delivered
          </BadgeCmp>
        )}
        {data?.orderStatus === 'in-transit' && (
          <BadgeCmp style="modern" type="primary" mainClassName=" mr-1">
            In-transit
          </BadgeCmp>
        )}
        {data?.orderStatus === 'invoice-created' && (
          <BadgeCmp style="modern" type="error" mainClassName=" mr-1">
            Invoice Created
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-6 font-normal ">
      {data.orderCreatedAt &&
        moment(data.orderCreatedAt).format('MMM DD, YYYY')}
    </td>
    <td className="px-5 py-6 font-normal ">
      ${getFormattedNumber(data.combinedCost ?? 0)}
    </td>
    <td className="px-5 py-6 font-semibold">
      {data.combinedMargin > 0
        ? `$${getFormattedNumber(data.combinedMargin, true, true)}`
        : getFormattedNumber(data.combinedMargin, true, true)}
    </td>
    <td className="px-5 py-6 font-semibold">
      ${getFormattedNumber(data.combinedRevenue ?? 0)}
    </td>
    <td className="px-5 py-6 font-normal ">
      {data.orderCommissioneeFirstName && data.orderCommissioneeLastName ? (
        <div className="flex items-center min-w-92">
          {data.orderCommissioneeImage ? (
            <img
              className="w-5 h-5 rounded-full border-[0.75px] border-black/[0.08]"
              src={data.orderCommissioneeImageUrl + data.orderCommissioneeImage}
              alt={data.orderCommissioneeFirstName}
              title={data.orderCommissioneeFirstName}
              onError={onError}
            />
          ) : (
            <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase">
              {getShortName(
                `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
              )}
            </div>
          )}
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-28 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
          </div>
        </div>
      ) : (
        <></>
      )}
    </td>
  </>
);

export default CreditTabOrderRaw;
