export const PATH = {
  ADDRESS_DETAILS: '/quotes/dashboard/address-details',
  DIMENSIONS: '/quotes/dashboard/dimensions',
  MY_QUOTE: '/quotes/dashboard',
  REQ_MY_QUOTE: '/quotes/request',
  FEEDBACK_LIST: '/feedback',
  TARGET_SET: '/set-target',
  CUSTOMER_DETAILS: `/customers`,
  REQ_ADDRESS_DETAILS: '/quotes/request/address-details',
  REQ_DIMENSIONS: '/quotes/request/dimensions',
  TEAM_MANAGEMENT: '/team-management',
  LOAD_INSURANCE: '/load-insurance',
};
