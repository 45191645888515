import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import ToastBodyContainer from '../../components/ToastContainer';
import { AuthContext } from '../../context/AuthContext';

import Sidebar from './Sidebar';

const Layout = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div id="app-layout">
      {isAuthenticated && <Sidebar />}
      <Outlet />
      <ToastBodyContainer />
    </div>
  );
};

export default Layout;
