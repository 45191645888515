import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

// TODO : CLEAN UP
// export const listUnassignedOrderGroupByCustomerByUploadedFileId = async (
//   params: any,
//   signal?: any
// ) => {
//   const response = await axiosInterceptor.get(
//     API.ORDERS.GET_UNASSIGNED_ORDERS_BY_FILE,
//     { params, signal }
//   );

//   return response;
// };

export const orderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.LIST, {
    params,
    signal,
  });

  return response;
};
