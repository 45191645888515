import { Minus, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
} from 'src/utils/CommonFunctions';

import { ICustomerTargetRowProps } from './Target.interface';

const TargetSetRow = ({
  data,
  handleInputChange,
  updatedTargets,
}: ICustomerTargetRowProps) => {
  const [isUpdatingValue, setIsUpdatingValue] = useState<string>('');
  const [targetProfit, setTargetProfit] = useState<number>(0);
  const [targetLoads, setTargetLoads] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<number>(2.5);

  const getFieldValue = (
    customerId: number,
    field: 'targetLoads' | 'targetProfit',
    defaultValue: number
  ): number => {
    const existingTarget = updatedTargets.find(
      (target) => target.customerId === customerId
    );

    let finalValue = existingTarget ? existingTarget[field] || 0 : defaultValue;

    if (!existingTarget && finalValue <= 0) {
      finalValue =
        field === 'targetLoads'
          ? data.prevMonthTargetLoads
          : data.prevMonthTargetProfit;
    }

    return finalValue;
  };
  useEffect(() => {
    setTargetProfit(getFieldValue(data.id, 'targetProfit', data.targetProfit));
    setTargetLoads(getFieldValue(data.id, 'targetLoads', data.targetLoads));
  }, []);

  useEffect(() => {
    handleInputChange(data.id, 'targetProfit', targetProfit, {
      targetLoads: getFieldValue(data.id, 'targetLoads', data.targetLoads),
    });
  }, [targetProfit]);

  useEffect(() => {
    handleInputChange(data.id, 'targetLoads', targetLoads, {
      targetProfit: getFieldValue(data.id, 'targetProfit', data.targetProfit),
    });
  }, [targetLoads]);

  const newSegmentArray = data?.tags
    ?.split(',')
    ?.map((item: string) => item.trim());

  const handleProfitDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetProfit(Math.round(targetProfit * decreaseFactor));
  };

  const handleProfitIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetProfit(Math.round(targetProfit * increaseFactor));
  };

  const handleLoadsDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetLoads(Math.round(targetLoads * decreaseFactor));
  };

  const handleLoadsIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetLoads(Math.round(targetLoads * increaseFactor));
  };

  const handlePlusDoubleClick = () => {
    setPercentageChange((prev) => prev + 2.5);
  };

  const handleMinusDoubleClick = () => {
    setPercentageChange((prev) => Math.max(2.5, prev - 2.5));
  };

  return (
    <>
      <td className="px-5 py-[14px] min-w-[13rem] w-[13rem] max-w-[13rem]">
        <div className="flex items-center gap-2">
          <div className="flex-none">
            {data.image ? (
              <img
                className="w-8 h-8 rounded-full object-contain "
                src={data.imageUrl + data.image}
                alt=""
                title=""
              />
            ) : (
              <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                {getShortName(`${data.customerName}`)}
              </div>
            )}
          </div>
          <div className="w-32">
            <h6 className="text-grayLight900 font-semibold leading-5 truncate">
              {data.customerName.length > 20 ? (
                <>
                  {/* @ts-ignore */}
                  <Tooltip
                    delay={300}
                    arrow={true}
                    html={
                      <div
                        style={{ bottom: `0px` }}
                        className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                      >
                        {data.customerName}
                      </div>
                    }
                  >
                    {data.customerName + data.customerName.length}
                  </Tooltip>
                </>
              ) : (
                data.customerName + data.customerName.length
              )}
            </h6>
          </div>
        </div>
      </td>
      <td className="px-5 py-[14px] min-w-[14rem] w-[14rem] max-w-[14rem]">
        <div className="flex flex-wrap gap-1">
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TEST) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-yellow300"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.TEST}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TOP) && (
            <BadgeCmp
              style="modern"
              mainClassName=""
              badgeDotColor="bg-[#b2ac17]"
            >
              {CUSTOMER_SEGMENT_TYPES.TOP}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.DORMANT) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-utilityPink600"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.DORMANT}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.PROSPECT) && (
            <BadgeCmp style="modern" type="lightblue" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.PROSPECT}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.NEW) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-fgSuccessPrimary"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.NEW}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.ACTIVE) && (
            <BadgeCmp style="modern" type="primary" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.ACTIVE}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.INACTIVE) && (
            <BadgeCmp style="modern" type="error" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.INACTIVE}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.AT_RISK) && (
            <BadgeCmp style="modern" type="gray" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.AT_RISK}
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-[14px] min-w-[20%] w-[20%] max-w-[20%]">
        {/* @ts-ignore */}
        <Tooltip
          delay={300}
          arrow={true}
          html={
            <div
              style={{ bottom: `0px` }}
              className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
            >
              Current / Target / Completion
            </div>
          }
        >
          <span className="cursor-pointer">
            $
            {getFormattedNumber(
              data.prevMonthCompletedProfit,
              false,
              false,
              true
            )}
          </span>
          <span>&nbsp;/&nbsp;</span>

          <span className="cursor-pointer">
            $
            {getFormattedNumber(data.prevMonthTargetProfit, false, false, true)}
          </span>
          <span>&nbsp;/&nbsp;</span>

          <span
            className={`text-textSecondary cursor-pointer ${getProgressClass(
              data.prevMonthCompletedProfitPercentage,
              false
            )}`}
          >
            {data.prevMonthCompletedProfitPercentage}%
          </span>
        </Tooltip>
      </td>

      <td className="px-5 py-[14px] min-w-[20%] w-[20%] max-w-[20%]">
        {/* @ts-ignore */}
        <Tooltip
          delay={300}
          arrow={true}
          html={
            <div
              style={{ bottom: `0px` }}
              className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
            >
              Actual / Target / Completion
            </div>
          }
        >
          <span>{data.prevMonthCompletedLoads}</span> <span>&nbsp;/&nbsp;</span>
          <span>{data.prevMonthTargetLoads}</span>
          <span>&nbsp;/&nbsp;</span>
          <span
            className={`text-textSecondary ${getProgressClass(
              data.prevMonthCompletedLoadsPercentage,
              false
            )}`}
          >
            {data.prevMonthCompletedLoadsPercentage}%
          </span>
        </Tooltip>
      </td>

      <td className="px-5 py-[14px] min-w-[18%] w-[18%]">
        <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
          <ButtonCmp
            className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
            onClick={handleProfitDecrease}
            onDoubleClick={handleMinusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`-${percentageChange}%`}
                </div>
              }
            >
              <Minus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>

          <InputText
            inputName="targetProfit"
            className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
              isUpdatingValue ? '' : ''
            }`}
            value={
              (isUpdatingValue === 'targetProfit'
                ? targetProfit
                : `$${getFormattedNumber(targetProfit)}`) || ''
            }
            inputType={isUpdatingValue === 'targetProfit' ? 'number' : 'text'}
            placeholder=""
            onKeyPress={(event: any) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChangeFunc={(e: any) => {
              setTargetProfit(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              );
            }}
            parentClassName="max-w-full"
            onBlur={() => setIsUpdatingValue('')}
            onFocus={() => setIsUpdatingValue('targetProfit')}
          />

          <ButtonCmp
            className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
            onClick={handleProfitIncrease}
            onDoubleClick={handlePlusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`+${percentageChange}%`}
                </div>
              }
            >
              <Plus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
        </div>
      </td>
      <td className="px-5 py-[14px] min-w-[18%] w-[18%]">
        <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
          <ButtonCmp
            className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
            onClick={handleLoadsDecrease}
            onDoubleClick={handleMinusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`-${percentageChange}%`}
                </div>
              }
            >
              <Minus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>

          <InputText
            inputName="targetLoads"
            className="border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center"
            value={
              isUpdatingValue === 'targetLoads'
                ? targetLoads || ''
                : targetLoads || 0
            }
            // value={
            //   isUpdatingValue === 'targetLoads' ? targetLoads : targetLoads || 0
            // }
            inputType="number"
            placeholder=""
            parentClassName="max-w-full"
            onKeyPress={(event: any) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChangeFunc={(e: any) => {
              setTargetLoads(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              );
            }}
            onBlur={() => setIsUpdatingValue('')}
            onFocus={() => setIsUpdatingValue('targetLoads')}
          />

          <ButtonCmp
            className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
            onClick={handleLoadsIncrease}
            onDoubleClick={handlePlusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`+${percentageChange}%`}
                </div>
              }
            >
              <Plus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
        </div>
      </td>
    </>
  );
};

export default TargetSetRow;
