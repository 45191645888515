import { Mail01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { AuthContext } from 'src/context/AuthContext';
import { getShortName, onError } from 'src/utils/CommonFunctions';

interface IMailConfirmation {
  action?: string;
  setAction?: any;
  customerData?: any;
  handleClose: any;
}

const MailConfirmation = ({ handleClose, customerData }: IMailConfirmation) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <CommonModal
      title="Send Email"
      titleDesc="You can edit your details"
      handleClose={() => handleClose(false)}
      headerIcon={<Mail01 className="w-5 h-5" />}
      // headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      size={'max-w-[640px] md:min-h-[auto] min-h-[90vh] max-h-[668px]'}
      modalHeaderClass="md:!px-8 !p-5 hover-text-wrap"
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      closeBtnClass="[&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
      modalClassName="flex flex-col "
      primaryBtnOnClick={() => {}}
      primaryBtnText="Submit"
    >
      <div className="w-full p-5 ">
        <div className="flex-col gap-3.5 flex py-4 ">
          <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
            <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
              From:
            </span>
            <div className="items-center gap-2 flex text-textSecondary text-xs font-semibold flex-wrap">
              {currentUser?.image ? (
                <img
                  alt=""
                  src={currentUser.imageUrl + currentUser.image}
                  onError={onError}
                  className="w-7 h-7 relative rounded-full border border-black/[0.08]"
                />
              ) : (
                <div className="w-7 h-7 bg-primary rounded-full text-white font-semibold flex items-center justify-center uppercase border border-black/[0.08] flex-none">
                  {getShortName(
                    `${currentUser?.firstName} ${currentUser?.lastName}`
                  )}
                </div>
              )}
              <span className="font-medium sm:w-auto break-all">
                {currentUser?.firstName} {currentUser?.lastName}
              </span>
              <span className="underline font-medium sm:w-auto break-all">
                {currentUser.email}
              </span>
            </div>
          </div>

          {customerData.email && (
            <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
              <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
                To:
              </span>
              <InputText
                inputName="Email"
                placeholder="-"
                parentClassName="flex-1"
                value={customerData.email ? customerData.email : '-'}
              />
            </div>
          )}
          <div className="flex flex-col border border-utilityGray200 rounded-xl px-4 py-3 gap-4 ">
            <p className="text-grayLight600 text-xs font-normal">
              Hi, {customerData.customerName}
            </p>
            <p className="text-grayLight600 text-xs font-normal">
              Please find our account setup portal below;{' '}
            </p>
            <p className="text-primary text-xs font-normal">
              onboarding.youknowwesternalliancelogistics.com{' '}
            </p>
            <p className="text-grayLight600 text-xs font-normal">
              I’ve CC’ed my finance team should you have any questions or
              concerns. <br />
            </p>
            <p className="text-grayLight600 text-xs font-normal">
              I’ll send you your BOL as soon as your account is opened.
            </p>
            <p className="text-grayLight600 text-xs font-normal">
              Welcome aboard! Regards
            </p>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default MailConfirmation;
