import moment from 'moment';
import React from 'react';
import CheckBox from 'src/components/CheckBox';

import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

const QuotedLoading = () => (
  <>
    <td className="px-5 py-4 min-w-60 w-60">
      <div className=" flex items-center ">
        <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading ">
          <img
            className="w-full h-full"
            src={defaultImage}
            alt="IMAGE"
            title="IMAGE"
          />
        </div>
        <div className="pl-3 max-w-48">
          <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none custom-loading">
            *****************
          </h6>
          <p className="max-w-36 truncate inline-block align-middle custom-loading">
            #11111
          </p>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 min-w-36 w-36 max-w-36">
      <div className="flex items-center ">
        <div className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08] custom-loading">
          <img
            className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08] custom-loading "
            src={FreightcomIcon}
            alt="FC"
          />
        </div>

        <div className="pl-3 font-medium custom-loading">Freightcom</div>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading"> {`Est. 5 days`} </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">
        {moment().format('MMM. Do - YYYY')}{' '}
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4">
      {' '}
      <span className="text-textSecondary font-bold custom-loading">
        {' '}
        {'2000000000'}
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">1000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4 min-w-20 w-20">
      <div className="flex gap-3">
        <span className="px-3">
          <CheckBox
            className="w-4 h-4 custom-loading"
            onChangeFunc={() => {}}
          />
        </span>
        <span className="px-3">
          <CheckBox
            className="w-4 h-4 custom-loading"
            onChangeFunc={() => {}}
          />
        </span>
      </div>
    </td>
  </>
);

export default QuotedLoading;
