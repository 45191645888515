import React from 'react';

const Loader = ({ spinnerClass }: any) => (
  <div className="max-w-sm dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
    <div
      role="status"
      className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
    >
      <span
        className={`loading loading-spinner text-gray-600 h-10 w-10 ${spinnerClass}`}
      ></span>
    </div>
  </div>
);

export default Loader;
