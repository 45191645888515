import React from 'react';

const ContactTabOrderRaw = ({ data }: any) => (
  <>
    <td className="px-5 py-4 w-1/4 min-w-1/4 max-w-72">
      <h6 className="truncate">{data?.contactFullName}</h6>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span>{data?.contactRole}</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span>{data?.contactPhone}</span>
    </td>
    <td className="px-5 py-4 w-1/4 min-w-1/4 max-w-72">
      <p className="truncate">{data?.contactEmail}</p>
    </td>
  </>
);

export default ContactTabOrderRaw;
