import { SearchLg, UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  handleClose: any;
}

const CreateCustomerGroupModal = ({ handleClose }: IProps) => {
  const handleUpdateSalesHistory = () => {};

  return (
    <CommonModal
      title={'Create a new customer group'}
      titleDesc={'Managing customers in effective group formation.'}
      handleClose={() => handleClose(false)}
      headerIcon={<UsersPlus />}
      size={'max-w-[500px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleUpdateSalesHistory}
      primaryBtnLoading={false}
      primaryBtnDisabled={false}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={false}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        <InputText
          label="Group Name"
          labelClassName="block mb-1.5"
          inputName="groupName"
          placeholder="Enter Group Name"
          parentClassName=""
          value="Watson Team"
        />
        <div className="relative">
          <InputText
            label={'Select customers'}
            inputName="selectcustomers"
            placeholder="Select customers"
            className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
            icon={
              <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
            }
            value={'abc'}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default CreateCustomerGroupModal;
