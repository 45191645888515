import {
  ClockFastForward,
  Plus,
  XClose,
  HelpCircle,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PERMISSION } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { createUpdateSummary } from 'src/services/QuoteService';
// import { PATH } from 'src/constants/path';
// import { modifyQuote } from 'src/services/QuoteService';
import {
  displayTimeDifference,
  getDateWithSuffixFormat,
  getFormattedDate,
  // getDateWithSuffixFormat,
  // getFormattedDate,
  getShortName,
  onError,
  useRolePermission,
} from 'src/utils/CommonFunctions';
// import WalToast from 'src/utils/WalToast';

const QuotingDashboardToQuoteRow = ({
  onRowClick,
  data,
  setAction,
  setSingleQuote,
}: // setAction,
// setSingleQuote,
any) => {
  const navigate = useNavigate();
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();

  // const modifyQuoteStatus = (quoteStatus: any) => () => {
  //   modifyQuote({ quoteId: quoteStatus.id })
  //     .then((response) => {
  //       if (response && response.data) {
  //         const newQuoteId = response.data?.id;
  //         const isModifyQuoteFromQuote: any = quoteStatus.id;
  //         localStorage.setItem(
  //           'isModifyQuoteFromQuote',
  //           isModifyQuoteFromQuote
  //         );
  //         localStorage.setItem('modifyQuoteCode', quoteStatus?.code);

  //         if (response.data?.isDateChange) {
  //           localStorage.setItem(
  //             'isPickupDateChanged',
  //             response.data?.isDateChange
  //           );
  //         }

  //         // if (isCustomerDeleted) {
  //         //     navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
  //         // } else {
  //         navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
  //         // }
  //       }
  //     })
  //     .catch(() => WalToast.error('Quote has not been modified', ''));
  // };

  const handleRedirectToQuote = (id: any) => {
    createUpdateSummary({ quoteId: id, quickQuote: true }).then(() =>
      navigate(`${PATH.MY_QUOTE}/${id}`)
    );
  };

  const handleActionType =
    (keyAction: any, mode: any = null, quote: any = {}) =>
    () => {
      setSingleQuote(quote);
      setAction((old: any) => ({ ...old, [keyAction]: true, mode }));
    };

  return (
    <>
      <td
        className="px-5 py-4 min-w-48 w-48 max-w-48"
        onClick={onRowClick(data.id)}
      >
        <div className="flex items-center">
          {data.image ? (
            <>
              <img
                className="w-7 h-7 rounded-full border border-black/[0.08] flex-none"
                src={data.imageUrl + data.image}
                alt={data.firstName}
                title={data.firstName}
                onError={onError}
              />
            </>
          ) : (
            <div className="w-7 h-7 bg-primary rounded-full text-white font-semibold flex items-center justify-center uppercase border border-black/[0.08] flex-none">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
          <div className="ml-2 text-xs font-medium max-w-32">
            <div
              data-test-id="wal-QuotingDashboard-fullName"
              className="text-grayLight900 font-medium leading-tight truncate"
            >{`${data.fullName}`}</div>
            <div
              data-test-id="wal-QuotingDashboard-code"
              className="text-grayLight600 font-normal leading-tight truncate"
            >
              #{data.code}
            </div>
          </div>
        </div>
      </td>
      {/* <td>{data?.urgency}</td> */}
      <td className="px-5 py-4 ">
        <BadgeCmp style="pill" type="success">
          {data?.urgency}
        </BadgeCmp>
        {/* <BadgeCmp style="pill" type="primary">
          RFQ
        </BadgeCmp> */}
      </td>

      <td className="px-5 py-4 min-w-[125px]">
        <BadgeCmp
          style="modern"
          // type={getUserAccountTypeColor(data.role.roleName)}
          type="success"
        >
          {/* {data?.classification?.toUpperCase()} */}Volume LTL
        </BadgeCmp>
        {/* <BadgeCmp style="modern" type="primary">
          FTL
        </BadgeCmp>
        <BadgeCmp style="modern" type="error">
          Volume LTL
        </BadgeCmp>
        <BadgeCmp style="modern" type="lightblue">
          Other
        </BadgeCmp> */}
      </td>

      <td
        data-test-id="wal-QuotingDashboard-customerName"
        className="px-5 py-4 max-w-40 min-w-40 w-40"
        onClick={onRowClick(data.id)}
      >
        <TooltipCmp
          message={data.customerName.length > 24 ? data.customerName : null}
          parentClassName="md:!p-3 !p-1.5 max-w-90"
        >
          <p className="truncate">{data.customerName}</p>
        </TooltipCmp>
      </td>
      <td
        className="px-5 py-4 text-xs max-w-40 w-40 min-w-40 "
        onClick={onRowClick(data.id)}
      >
        <TooltipCmp
          message={data.shipperFullAddress}
          type="dark"
          parentClassName=""
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">
              {data.shipperFullAddress}
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          message={data.consigneeFullAddress}
          type="dark"
          parentClassName="max-w-90"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">
              {data.consigneeFullAddress}
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-3 py-4 ">
        <span>{data?.handleUnit}</span>
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalUnits"
        className="px-5 py-4"
        onClick={onRowClick(data.id)}
      >
        <span className="">{data.totalUnits}</span>
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalWeight"
        className="px-5 py-4"
        onClick={onRowClick(data.id)}
      >
        <span className="">{`${data.totalWeight}  ${
          data.weightMeasurement ? data.weightMeasurement?.toLowerCase() : ''
        }`}</span>
      </td>
      {/* <td className="px-5 py-4" onClick={onRowClick(data.id)}>
                            <span className="">
                                {data.expiryDay}
                                {data.expiryDay !== "-" ? (data.expiryDay === "1" || data.expiryDay === "0" ? " Day" : " Days") : ""}
                            </span>
                        </td> */}
      <td
        data-test-id="wal-QuotingDashboard-createdAt"
        className="px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]"
        onClick={onRowClick(data.id)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.pickupDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(data.pickupDate, `MMM Do - YYYY`)
                )
              : '-',
          }}
        />
      </td>
      <td className="px-5 py-4 ">
        <BadgeCmp style="pill" type="success">
          <ClockFastForward className="mr-1 h-3 w-3 text-lg text-success500" />
          {displayTimeDifference(data.receivedDateTime)}
        </BadgeCmp>
      </td>

      {/* <td
        data-test-id="wal-QuotingDashboard-quotePrice"
        className="px-5 py-4"
        onClick={onRowClick(data.id)}
      >
        <span className="">
          {currency === CURRENCY.CAD
            ? `$ ${data.quotePrice.toFixed(2)} ${CURRENCY.CAD}`
            : `$ ${data.quotePriceUSD.toFixed(2)} ${CURRENCY.USD}`}
        </span>
      </td> */}
      {/* <td
        className={`px-5 py-4 min-w-[130px] max-w-[130px]`}
        onClick={onRowClick(data.id)}
      >
        <BadgeCmp
          style="modern"
          type={
            data.status === 'Won'
              ? 'success'
              : data.status === 'Active'
              ? 'lightblue'
              : data.status === 'Opened'
              ? 'primary'
              : data.status === 'Expired' || data.status === 'Lost'
              ? 'error'
              : 'success'
          }
        >
          <span data-test-id="wal-QuotingDashboard-status" className="text-xs">
            {data.status === 'Opened'
              ? 'Open'
              : data.status === 'Active'
              ? 'Quoted'
              : data.status}
          </span>
        </BadgeCmp>
      </td> */}

      {hasRoleV2('admin') && (
        <td className="px-5 py-4">
          <div className="justify-center items-center gap-1 flex ">
            <TooltipCmp message="Quick quote" type="dark">
              <Plus
                className="mt-0.5 w-4 h-4 relative cursor-pointer text-primary"
                onClick={() => handleRedirectToQuote(data.id)}
              />
            </TooltipCmp>
            {hasPermissionV2(PERMISSION.DELETE_SMART_QUOTE) && (
              <TooltipCmp message="No quote" type="dark">
                <XClose
                  className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgErrorPrimary"
                  onClick={handleActionType('quote', 'delete', data)}
                />
              </TooltipCmp>
            )}
            <TooltipCmp message="Request help" type="dark">
              <HelpCircle className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgSuccessPrimary" />
            </TooltipCmp>
          </div>
        </td>
      )}
    </>
  );
};

export default QuotingDashboardToQuoteRow;
