import { yupResolver } from '@hookform/resolvers/yup';
import { User01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import CountryInput from 'src/components/CountryInput/CountryInput';
import CropperModal from 'src/components/CropperModal/CropperModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import { updateCustomerDetail } from 'src/services/CustomerService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

// Define the validation schema using yup
const schema = yup.object().shape({
  image: yup.mixed(),
  name: yup.string().required('Name is required.'),
  phone: yup.string().required('Phone number is required.'),
  email: yup
    .string()
    .email('Email must be a valid email address.')
    .required('Email address is required.'),
  address1: yup.string().required('Address 1 is required.'),
  address2: yup.string().required('Address 2 is required.'),
  city: yup.string().required('City is required.'),
  state: yup.string().required('State is required.'),
  postal: yup.string().required('Postal code is required.'),
  country: yup.string().required('Country is required.'),
  website: yup
    .string()
    .url('Website must be a valid URL.')
    .required('Website is required.'),
  about: yup.string().required('About is required.'),
});

interface IProps {
  customerDetail: any;
  handleClose: any;
}

const CustomerDetailEditModal = ({ customerDetail, handleClose }: IProps) => {
  const [isCustomerUpdateLoading, setIsCustomerUpdateLoading] = useState(false);
  const [displayImage, setDisplayImage] = useState<any>(null);
  const [upload, setUpload] = useState(false);
  const [imageError, setImageError] = useState('');

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: undefined,
      name: customerDetail.name || '',
      phone: customerDetail.phone || '',
      email: customerDetail.email || '',
      address1: customerDetail.address1 || '',
      address2: customerDetail.address2 || '',
      city: customerDetail.city || '',
      state: customerDetail.state || '',
      postal: customerDetail.postal || '',
      country: customerDetail.country || '',
      website: customerDetail.website || '',
      about: customerDetail.about || '',
    },
  });

  const onSubmit = (formData: any) => {
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      if (key !== 'image') appendFormData.append(key, value);
    });
    appendFormData.append('image', formData.image);

    // Add your API call here
    setIsCustomerUpdateLoading(true);
    updateCustomerDetail(customerDetail?.id, appendFormData)
      .then((response: any) => {
        WalToast.success(
          response?.message ?? 'Customer details updated successfully'
        );
        handleClose(true);
      })
      .finally(() => {
        setIsCustomerUpdateLoading(false);
      })
      .catch((e) => {
        console.log(e);
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong updating customer detail.'
        );
      });
  };

  const chooseFile = (e: any) => {
    e.preventDefault();
    setImageError('');
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const { result } = checkFileTypeValidation(files, MAX_FILE_SIZE);

    if (result) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        setDisplayImage('');
        setUpload(false);
      };

      reader.onloadend = () => {
        setDisplayImage(reader.result as any);
        setUpload(true);
      };
      reader.readAsDataURL(files[0]);
      e.target.value = null;
    } else {
      setImageError('Image size should be less than 2MB.');
    }
  };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    setValue('image', fileInfo?.convertedFile);
  };

  return (
    <CommonModal
      title={'Edit Customer Details'}
      titleDesc={'You can edit your details'}
      handleClose={() => (isCustomerUpdateLoading ? false : handleClose(false))}
      headerIcon={<User01 />}
      size={'xl:max-w-[647px] max-w-[551px] '}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isCustomerUpdateLoading}
      primaryBtnDisabled={isCustomerUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isCustomerUpdateLoading}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        <div className="w-full md:flex pb-4 border-b border-utilityGray200">
          <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-40">
            Image Update
          </p>

          {upload && (
            <CropperModal
              modalTitle="Edit Customer Profile Image"
              modalDesc="Edit customer profile image as per your requirement"
              imageUpload={imageUpload}
              defaultImage={
                customerDetail?.imageUrl + customerDetail?.image || ''
              }
              imageUrl={displayImage}
              setUpload={setUpload}
              setCropData={setDisplayImage}
              isCropType={false}
              defaultCropType="Square"
            />
          )}

          <div className="position-relative flex-1">
            <div className="flex md:gap-4 gap-2 ">
              {displayImage ? (
                <img
                  className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                  src={displayImage}
                />
              ) : customerDetail?.image && customerDetail?.imageUrl ? (
                <img
                  className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                  src={`${customerDetail?.imageUrl}${customerDetail?.image}`}
                />
              ) : (
                <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                  {getShortName(customerDetail?.name)}
                </label>
              )}

              <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                <label htmlFor="fileSelectInModal" className="cursor-pointer">
                  <progress
                    className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                    value="70"
                    max="100"
                  ></progress>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop&nbsp;
                      <br className="sms:block hidden"></br>
                      <span className="text-[11px] leading-[18px]">
                        SVG, PNG or JPG
                      </span>
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="image"
                  id="fileSelectInModal"
                  accept="image/*"
                  onChange={chooseFile}
                  className="hidden"
                />
              </div>
            </div>
            <span className="text-danger text-xs">{imageError}</span>
          </div>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="name"
              label="Name"
              labelClassName="block mb-1.5"
              placeholder="Enter Name"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.name ? errors.name.message : ''}
            />
          )}
        />
        <div className="flex max-sm:flex-col -mx-1.5">
          <div className="px-1.5 max-sm:mb-4 sm:w-1/2">
            <label className="form_label block mb-1.5">Phone number</label>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="phone"
                  onNumberChange={(country: any, phone: any, code: any) => {
                    onChange(`+${code}${phone}`);
                  }}
                  errors={errors.phone ? errors.phone.message : ''}
                  value={value}
                />
              )}
            />
          </div>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="email"
                label="Email"
                labelClassName="block mb-1.5"
                placeholder="Enter Email"
                parentClassName="sm:w-1/2 px-1.5"
                value={value}
                onChangeFunc={onChange}
                errorText={errors.email ? errors.email.message : ''}
              />
            )}
          />
        </div>
        <Controller
          name="address1"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="address1"
              label="Address 1"
              labelClassName="block mb-1.5"
              placeholder="Enter Address"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.address1 ? errors.address1.message : ''}
            />
          )}
        />
        <Controller
          name="address2"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="address2"
              label="Address 2"
              labelClassName="block mb-1.5"
              placeholder="Enter Street"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.address2 ? errors.address2.message : ''}
            />
          )}
        />
        <div className="flex max-sm:flex-col -mx-1.5">
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="city"
                label="City"
                labelClassName="block mb-1.5"
                placeholder="Enter City"
                parentClassName="sm:w-1/2 px-1.5 max-sm:mb-4"
                value={value}
                onChangeFunc={onChange}
                errorText={errors.city ? errors.city.message : ''}
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="state"
                label="State"
                labelClassName="block mb-1.5"
                placeholder="Enter State"
                parentClassName="sm:w-1/2 px-1.5 max-sm:mb-4"
                value={value}
                onChangeFunc={onChange}
                errorText={errors.state ? errors.state.message : ''}
              />
            )}
          />
        </div>
        <div className="flex max-sm:flex-col -mx-1.5">
          <Controller
            name="postal"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="postal"
                label="Postal"
                labelClassName="block mb-1.5"
                placeholder="Enter Postal"
                parentClassName="sm:w-1/2 px-1.5 max-sm:mb-4"
                value={value}
                onChangeFunc={onChange}
                errorText={errors.postal ? errors.postal.message : ''}
              />
            )}
          />
          <div className="px-1.5 sm:w-1/2">
            <label className="form_label block mb-1.5">Country</label>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CountryInput
                  name="country"
                  value={value}
                  onChangeFunc={(e: any) => {
                    console.log('country input ', e);
                    onChange(e);
                  }}
                  errors={errors.country ? errors.country.message : ''}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="website"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="website"
              label="Customer Website"
              placeholder="Enter Customer Website"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.website ? errors.website.message : ''}
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="about"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="about"
              label="About"
              placeholder="Enter Customer Information"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.about ? errors.about.message : ''}
              labelClassName="block mb-1.5"
              className="h-32"
              asTextarea="textarea"
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default CustomerDetailEditModal;
