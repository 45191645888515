// eslint-disable-next-line import/no-extraneous-dependencies
import mapboxgl from 'mapbox-gl';
import React, { useState, useEffect } from 'react';

import InputText from '../InputText/InputText';

const SearchBox = ({ onSearch }: any) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [radius, setRadius] = useState(50);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [isDisplayFromSuggestion, setIsDisplayFromSuggestion] = useState(false);
  const [isDisplayToSuggestion, setIsDisplayToSuggestion] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSearch(from, to, radius);
  };

  const getSuggestions = async (query: any, setter: any) => {
    if (query.length > 2) {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          query
        )}.json?access_token=${
          mapboxgl.accessToken
        }&types=address&country=us,ca`
      );
      const data = await response.json();
      setter(data.features.map((f: any) => f.place_name));
    } else {
      setter([]);
    }
  };
  useEffect(() => {
    console.log('fromSuggestions :>> ', fromSuggestions);
  }, [fromSuggestions]);

  useEffect(() => {
    getSuggestions(from, setFromSuggestions);
  }, [from]);

  useEffect(() => {
    getSuggestions(to, setToSuggestions);
  }, [to]);

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '50px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
        maxWidth: '1000px',
      }}
    >
      <div style={{ position: 'relative', flex: 1, marginRight: '10px' }}>
        <InputText
          inputName="searchMap"
          value={from}
          onChangeFunc={(e: any) => setFrom(e.target.value)}
          onFocus={() => setIsDisplayFromSuggestion(true)}
          placeholder="From"
          required
        />
        {fromSuggestions.length > 0 && isDisplayFromSuggestion && (
          <ul
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '0 0 8px 8px',
              padding: 0,
              margin: 0,
              listStyle: 'none',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 2,
            }}
          >
            {fromSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => {
                  setFrom(suggestion);
                  setFromSuggestions([]);
                  setIsDisplayFromSuggestion(false);
                }}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #eee',
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div style={{ position: 'relative', flex: 1, marginRight: '10px' }}>
        <InputText
          inputName="to"
          value={to}
          onChangeFunc={(e: any) => setTo(e.target.value)}
          placeholder="To"
          onFocus={() => setIsDisplayToSuggestion(true)}
          required
        />

        {toSuggestions.length > 0 && isDisplayToSuggestion && (
          <ul
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '0 0 8px 8px',
              padding: 0,
              margin: 0,
              listStyle: 'none',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 2,
            }}
          >
            {toSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => {
                  setTo(suggestion);
                  setToSuggestions([]);
                  setIsDisplayToSuggestion(false);
                }}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #eee',
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
      >
        <InputText
          inputName="radius"
          inputType="number"
          value={radius}
          onChangeFunc={(e) => setRadius(Math.max(1, parseInt(e.target.value)))}
          placeholder="Km"
          required
          min="1"
        />
        {/* 
       
        <label htmlFor="radius" style={{ marginLeft: '5px', fontSize: '14px' }}>
          km
        </label> */}
      </div>
      <button
        type="submit"
        style={{
          padding: '10px 20px',
          backgroundColor: '#FF5A5F',
          color: 'white',
          border: 'none',
          borderRadius: '25px',
          fontSize: '14px',
          cursor: 'pointer',
        }}
      >
        Search
      </button>
    </form>
  );
};

export default SearchBox;
