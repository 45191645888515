import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageSectionLayout from 'src/components/PageSectionLayout';

const LoadInsuranceDetails = () => {
  console.log('object :>> ');
  const navigate = useNavigate();

  return (
    <PageSectionLayout
      header={<></>}
      contentClassName="!p-0"
      headerClassName="!p-0"
    >
      <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[86px] before:h-[93px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
        <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
          <div
            className="flex items-center cursor-pointer gap-1.5"
            onClick={() => {
              navigate('/load-insurance', {
                state: { from: location.pathname },
              });
            }}
          >
            <ArrowLeft className="h-4 w-4" />
            <span className="text-grayLight600 text-xs font-semibold">
              Back to Load Insurance
            </span>
          </div>
          <div
            className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer transition-all duration-[0.3s] hover:bg-borderPrimary"
            onClick={() => {
              if (history.length > 3) {
                navigate(-1);
              } else {
                navigate('/load-insurance');
              }
            }}
          >
            <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
          </div>
        </div>
        {/* <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col "></div> */}
      </div>
    </PageSectionLayout>
  );
};

export default LoadInsuranceDetails;
