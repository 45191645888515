export const LoadInsuranceData = [
  {
    id: 1,
    orderId: 'LI-Q-200238',
    commodity: 'Gold',
    commercial: '1000',
  },
  {
    id: 2,
    orderId: 'LI-Q-200258',
    commodity: 'Silver',
    commercial: '500',
  },
  {
    id: 3,
    orderId: 'LI-Q-200438',
    commodity: 'Oil',
    commercial: '300',
  },
];
