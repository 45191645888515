import { Copy06, Share03 } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  cancelQuote,
  pushQuoteToRoseRocket,
  updateStatus,
} from 'src/services/QuoteService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

import CloseQuoteReason from './CloseQuoteReason';

interface IProps {
  selectedCarrierRates: any;
  addressDetails: any;
  handleModalClose: any;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProceedWithOrderFlow = ({
  selectedCarrierRates,
  addressDetails,
  handleModalClose,
  setIsRefresh,
}: IProps) => {
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
  const [isSecondary2Loading, setIsSecondary2Loading] = useState(false);
  const [isShowCloseReasonModal, setIsShowCloseReasonModal] = useState(false);
  const [isShowProceedWithOrderModal, setIsShowProceedWithOrderModal] =
    useState(true);
  const { currency } = useContext(BasicContext);
  // console.log(selectedCarrierRates, addressDetails);

  const handlePushToRoseRocket = () => {
    setIsMainLoading(true);
    pushQuoteToRoseRocket(selectedCarrierRates[0].id)
      .then((response: any) => {
        if (response && response.data) {
          WalToast.success('Order Pushed to Rose Rocket Successfully');
          setIsRefresh(true);
          // handleModalClose(true)();
        }
      })
      .finally(() => {
        setIsMainLoading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const handleQuoteClose = (closeReasonPayload: any) => {
    setIsShowCloseReasonModal(false);
    setIsShowProceedWithOrderModal(true);
    setIsSecondaryLoading(true);
    cancelQuote(closeReasonPayload, selectedCarrierRates[0].quoteId)
      .then((response: any) => {
        if (response && response.data) {
          WalToast.success('Order Closed Successfully');
          handleModalClose(true)();
        }
      })
      .finally(() => {
        setIsSecondaryLoading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const handleSetToWon = () => {
    setIsSecondary2Loading(true);
    updateStatus(selectedCarrierRates[0].id)
      .then((response: any) => {
        if (response && response.data) {
          WalToast.success('Order Set to Won Successfully');
          handleModalClose(true)();
        }
      })
      .finally(() => {
        setIsSecondary2Loading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <>
      {isShowProceedWithOrderModal && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Book Order"
          titleDesc={'Confirm and finalize final quote pricing.'}
          size={'max-w-[620px]'}
          modalFooterClass="md:!flex-row !flex-col"
          // primary
          primaryBtnText={`${
            addressDetails?.roserocketPushDate && addressDetails?.rossRocketId
              ? `Pushed at ${moment(addressDetails?.roserocketPushDate).format(
                  'MMM DD, YYYY'
                )}`
              : 'Push Order to RoseRocket'
          }`}
          modalPrimaryBtnClass="w-full flex-none order-[0]"
          modalSecondaryBtnTextClass="md:order-[1] order-[3]"
          modalSecondaryBtnText2Class="order-[2]"
          primaryBtnOnClick={handlePushToRoseRocket}
          primaryBtnLoading={isMainLoading}
          primaryBtnDisabled={
            isMainLoading ||
            (addressDetails?.roserocketPushDate && addressDetails?.rossRocketId)
          }
          // secondary
          secondaryBtnText="Close"
          secondaryBtnOnClick={() => {
            setIsShowProceedWithOrderModal(false);
            setIsShowCloseReasonModal(true);
          }}
          secondaryBtnLoading={isSecondaryLoading}
          secondaryBtnDisabled={isSecondaryLoading}
          // secondary 2nd
          secondaryBtnText2="Set as Won"
          secondaryBtnOnClick2={handleSetToWon}
          secondaryBtnLoading2={isSecondary2Loading}
          secondaryBtnDisabled2={
            isSecondary2Loading || !addressDetails?.rossRocketId
          }
        >
          <div className="p-5">
            <div className="rounded-lg border border-utilityGray200 xl:my-1">
              <div className="sm:p-4 p-3 flex items-center gap-3 border-b border-utilityGray200">
                <div className="rounded-full border-[0.75px] border-utilityBlack border-opacity-[0.08] overflow-hidden">
                  <img
                    src={
                      selectedCarrierRates[0]?.image
                        ? `${selectedCarrierRates[0]?.imageUrl}${selectedCarrierRates[0]?.image}`
                        : defaultImage
                    }
                    className="w-11 h-11 flex-none"
                  />
                </div>
                <div>
                  <h6 className="text- grayLight600 text-xs font-normal">
                    Carrier
                  </h6>
                  <p className="text-grayLight900 text-sm font-medium">
                    {selectedCarrierRates[0]?.name}
                  </p>
                </div>
              </div>

              <div className="sm:px-4 px-3">
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Quote ID</p>
                  <div className="flex gap-2 items-center">
                    <p className="font-semibold text-right break-all">
                      {selectedCarrierRates[0]?.carrierQuoteId}{' '}
                    </p>
                    <Copy06
                      className="flex items-center cursor-pointer font-normal text-sm text-gray500 w-3 h-3"
                      onClick={(event) => {
                        event.stopPropagation();
                        copyDebounce(selectedCarrierRates[0]?.carrierQuoteId);
                      }}
                    />
                  </div>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Provider</p>
                  <p className="font-semibold text-right">
                    <div className="flex items-center">
                      {(selectedCarrierRates[0]?.isFreightcom ||
                        selectedCarrierRates[0]?.isFreightcom === 1) && (
                        <>
                          <img
                            className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                            src={FreightcomIcon}
                            alt="FC"
                          />
                          <div className="pl-3 font-medium">Freightcom</div>
                        </>
                      )}
                      {(selectedCarrierRates[0]?.isBanyan ||
                        selectedCarrierRates[0]?.isBanyan === 1) && (
                        <>
                          <img
                            className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                            src={BanyanIcon}
                            alt="BANYAN"
                          />
                          <div className="pl-3 font-medium">Banyan</div>
                        </>
                      )}
                      {!selectedCarrierRates[0]?.isBanyan &&
                        !selectedCarrierRates[0]?.isFreightcom && (
                          <>
                            <img
                              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                              src={
                                selectedCarrierRates[0]?.image
                                  ? `${selectedCarrierRates[0]?.imageUrl}${selectedCarrierRates[0]?.image}`
                                  : defaultImage
                              }
                              alt="CR"
                            />
                            <div className="pl-3 font-medium">Direct</div>
                          </>
                        )}
                      {selectedCarrierRates[0]?.quoteUrl && (
                        <TooltipCmp message="Redirect">
                          <a
                            href={selectedCarrierRates[0]?.quoteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none ml-2" />
                          </a>
                        </TooltipCmp>
                      )}
                    </div>
                  </p>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">WALHQ ID</p>
                  <p className=" flex font-semibold text-right">
                    {addressDetails?.code}{' '}
                  </p>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Base Cost</p>
                  <p className="font-semibold text-right">
                    ${getFormattedNumber(selectedCarrierRates[0]?.totalCharge)}{' '}
                    {currency}
                  </p>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Additional Services</p>
                  <p className="font-semibold text-right">
                    $
                    {getFormattedNumber(
                      selectedCarrierRates[0]?.additionalCharges
                    )}{' '}
                    {currency}
                  </p>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Total Cost</p>
                  <p className="font-semibold text-right">
                    {currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(
                          selectedCarrierRates[0]?.totalCostCAD
                        )}`
                      : `$${getFormattedNumber(
                          selectedCarrierRates[0]?.totalCostUSD
                        )}`}{' '}
                    {currency}
                  </p>
                </div>
                <div className="border-b border-utilityGray200 py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Margin</p>
                  <p className="font-semibold text-right">
                    ${getFormattedNumber(selectedCarrierRates[0]?.margin)}{' '}
                    {currency}
                  </p>
                </div>
                <div className="py-4 gap-3 text-grayLight600 text-sm flex justify-between">
                  <p className="font-normal flex-none">Total Price</p>
                  <p className="font-semibold text-right">
                    ${getFormattedNumber(selectedCarrierRates[0]?.finalCharge)}{' '}
                    {currency}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CommonModal>
      )}

      {isShowCloseReasonModal && (
        <CloseQuoteReason
          id={selectedCarrierRates[0].id}
          handleSuccess={handleQuoteClose}
          handleClose={() => {
            setIsShowCloseReasonModal(false);
            handleModalClose(true)();
          }}
        />
      )}
    </>
  );
};

export default ProceedWithOrderFlow;
