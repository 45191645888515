import { yupResolver } from '@hookform/resolvers/yup';
import { Ticket01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import * as yup from 'yup';

interface ILoadInsurance {
  handleClose: any;
}

const RequestLoadInsurance = ({ handleClose }: ILoadInsurance) => {
  const validationSchema = yup.object().shape({
    orderId: yup.string().required('orderId is required.'),
    commodity: yup.string().required('orderId is required.'),
    commercial: yup.string().required('commercial is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      orderId: '',
      commodity: '',
      commercial: '',
    },
  });

  const onSubmit = async (formData: any) => {
    console.log('formData :>> ', formData);
    handleClose();
  };

  return (
    <CommonModal
      title="Request Load Insurance"
      titleDesc="Request Load Insurance"
      handleClose={() => handleClose(false)}
      headerIcon={<Ticket01 className="w-5 h-5" />}
      // headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      size={'max-w-[500px] md:min-h-[auto] min-h-[90vh] max-h-[668px]'}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass="md:!px-8 !p-5 hover-text-wrap"
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      closeBtnClass="[&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
      modalClassName="flex flex-col "
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
    >
      <div className=" flex md:flex-row flex-col flex-1 overflow-auto py-5">
        <div className="flex-1 md:px-8 px-5 md:order-[0] order-2 flex flex-col">
          <Controller
            name="orderId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Order Id"
                inputName="orderId"
                placeholder="Order Id"
                parentClassName="mb-4"
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.orderId ? errors.orderId.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />

          <Controller
            name="commodity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commodity"
                inputName="commodity"
                placeholder="Commodity"
                parentClassName="mb-4"
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.orderId ? errors.orderId.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />

          <Controller
            name="commercial"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commercial"
                inputName="commercial"
                inputType="number"
                placeholder="Commercial"
                parentClassName="mb-4"
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.orderId ? errors.orderId.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default RequestLoadInsurance;
