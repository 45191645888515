import {
  ClockPlus,
  Cube01,
  Cube03,
  Package,
  Plane,
  Signal01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';

const MessageBodyLoading = () => (
  <>
    <div className="flex flex-wrap xxl:gap-4 gap-3 w-full items-center justify-between border-b border-utilityGray200 px-5 py-3.5">
      <div className="w-12 h-12 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none custom-loading">
        <span className="text-primary text-xl font-semibold">HP</span>
      </div>
      <div className="flex-1 flex flex-wrap xxl:gap-4 gap-2 md:items-center md:flex-row flex-col ">
        <div className="flex-1">
          <div className="flex flex-wrap gap-x-2 gap-y-1 items-center mb-0.5">
            <h6 className="text-grayLight900 text-base font-semibold flex xxl:gap-4 gap-3 items-center custom-loading">
              Harsh Patel
            </h6>
            <div className="rounded-md border border-utilityGray200 bg-gray50 flex px-1.5 py-0.5 custom-loading">
              <a
                href="mailto:katherine.moss@wal.com"
                className="text-textSecondary text-xs font-medium"
              >
                harshpatel@logisticinfotech.co.in
              </a>
            </div>
          </div>
          <div className="text-grayLight600 text-xs flex sm:flex-row flex-col items-start custom-loading">
            <span className="font-bold mr-1">Subject: </span>
            <span className="font-normal">test</span>
          </div>
        </div>
        <div className="text-right">
          <div className="text-grayLight600 text-xs font-normal mb-1 custom-loading">
            Monday, July 29, 2024 8:42 AM
          </div>
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName="rounded-[8px] border border-success200 bg-success50 custom-loading"
            badgeTextColor="!text-success700"
            isHidePillDot
          >
            <ClockPlus className="w-3 h-3 me-1" /> 00:39:23
          </BadgeCmp>
        </div>
      </div>
    </div>
    <div className="flex-1 w-full flex-col justify-start items-start gap-4 flex p-5 h-[calc(100vh_-_311px)] min-h-[calc(100vh_-_311px)] max-h-[calc(100vh_-_311px)] overflow-y-auto custom-scrollbar-v2">
      <div className="custom-loading h-full w-full"></div>
    </div>
    <div className="flex flex-wrap gap-5 sticky bottom-0 bg-gray50 border-t border-utilityGray200 px-5 py-3">
      <div>
        <label className="form_label block mb-1.5 custom-loading">
          Classification
          <span className="text-red-600 leading-4">*</span>
        </label>
        <div className="flex gap-2">
          <ButtonCmp
            icon={<Package className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            LTL
          </ButtonCmp>
          <ButtonCmp
            icon={<Cube01 className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            FTL
          </ButtonCmp>
          <ButtonCmp
            icon={<Cube03 className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            Volume LTL
          </ButtonCmp>
          <ButtonCmp
            icon={<Plane className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            Other
          </ButtonCmp>
        </div>

        {/* <TabButton
            className="min-w-30 w-30 flex justify-center items-center"
            tabArray={quoteTypeArr}
            isActive={classifyQuote}
            activeClassName="bg-primary text-white"
            handleOnClick={(e: any) => {
              setClassifyQuote(e.target.dataset.value);
            }}
          /> */}
      </div>
      <div className="flex-1">
        <label className="form_label block mb-1.5 custom-loading">
          Urgency
          <span className="text-red-600 leading-4">*</span>
        </label>
        <div className="flex gap-2">
          <ButtonCmp
            icon={<Signal01 className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            Live
          </ButtonCmp>
          <ButtonCmp
            icon={<Signal01 className="w-4 h-4" />}
            iconSide="left"
            className="btn_primary custom-loading"
          >
            RFQ
          </ButtonCmp>
        </div>
      </div>
      <div>
        <label className="form_label block mb-1.5 custom-loading">
          Actions
          <span className="text-red-600 leading-4">*</span>
        </label>
        <div className="flex gap-2">
          <ButtonCmp
            className="btn_secondary_black custom-loading"
            icon={<Signal01 className="w-4 h-4" />}
          >
            Dismiss
          </ButtonCmp>
          <ButtonCmp
            className="btn_secondary_black custom-loading"
            icon={<Signal01 className="w-4 h-4" />}
          >
            Reply
          </ButtonCmp>

          <ButtonCmp
            className="btn_primary custom-loading"
            iconSide="right"
            icon={<Signal01 className="w-4 h-4" />}
          >
            Confirm Quote Details
          </ButtonCmp>
        </div>
      </div>
    </div>
  </>
);

export default MessageBodyLoading;
