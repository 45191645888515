import {
  ClockFastForward,
  HelpCircle,
  Plus,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { PERMISSION } from 'src/constants/common';
import { useRolePermission } from 'src/utils/CommonFunctions';

import TooltipCmp from '../../../components/TooltipCmp';

const SmartQuoteListLoading = () => {
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4 min-w-48 w-48 max-w-48">
        <div className="flex items-center ">
          <div className="w-7 h-7 bg-primary rounded-md text-white flex items-center justify-center uppercase custom-loading">
            KD
          </div>
          <div className="ml-2 text-xs font-medium max-w-32 custom-loading">
            <div className="text-grayLight900 font-medium leading-tight truncate skeleton-loading">
              Kishan Dodiya
            </div>
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #SQ-L-100502
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4">
        <BadgeCmp style="pill" type="primary" mainClassName="custom-loading">
          RFQ
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 min-w-[125px]">
        <BadgeCmp style="modern" type="error" mainClassName="custom-loading">
          Volume LTL
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 max-w-40 min-w-40 w-40">
        <p className="truncate custom-loading"> Multi-Formes Inc (USD)</p>
      </td>
      <td className="px-5 py-4 text-xs max-w-40 w-40 min-w-40">
        <TooltipCmp
          type="dark"
          parentClassName=""
          childrenClassName="custom-loading"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">
              3496 Rue Vautelet, Québec, QC G1W 4V8, Canada
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          type="dark"
          parentClassName="max-w-90"
          childrenClassName="custom-loading"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">
              Federal Way, WA 98001, USA
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4">
        <span className=" custom-loading">Pallet</span>
      </td>
      <td className="px-5 py-4">
        <span className=" custom-loading">1</span>
      </td>

      <td className="px-5 py-4">
        <span className="custom-loading">4lbs</span>
      </td>
      <td className="px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]">
        <span className="custom-loading">Yesterday</span>
      </td>
      <td className="px-5 py-4 ">
        <BadgeCmp style="pill" type="success" mainClassName="custom-loading">
          <ClockFastForward className="mr-1 h-3 w-3 text-lg text-success500" />
          17d ago
        </BadgeCmp>
      </td>
      {hasRoleV2('admin') && (
        <td className="px-5 py-4">
          <div className="justify-center items-center gap-1 flex ">
            <TooltipCmp
              message="Quick quote"
              type="dark"
              childrenClassName="custom-loading"
            >
              <Plus className="mt-0.5 w-4 h-4 relative cursor-pointer text-primary" />
            </TooltipCmp>
            {hasPermissionV2(PERMISSION.DELETE_SMART_QUOTE) && (
              <TooltipCmp
                message="No quote"
                type="dark"
                childrenClassName="custom-loading"
              >
                <XClose className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgErrorPrimary" />
              </TooltipCmp>
            )}
            <TooltipCmp
              message="Request help"
              type="dark"
              childrenClassName="custom-loading"
            >
              <HelpCircle className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgSuccessPrimary" />
            </TooltipCmp>
          </div>
        </td>
      )}
    </>
  );
};

export default SmartQuoteListLoading;
