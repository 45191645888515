import 'cropperjs/dist/cropper.css';

import { Crop02 } from '@untitled-ui/icons-react/build/cjs';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

import CommonModal from '../CommonModal';

const CropOrganizationImage = ({
  imageUpload,
  setCropData,
  imageUrl,
  setUpload,
  modalTitle,
  modalDesc,
  defaultImage,
}: any) => {
  const [zoomLevel, setZoomLevel] = useState<any>(0.5);
  const cropperRef = createRef<ReactCropperElement>();
  const zoomSliderRef = useRef<HTMLInputElement>(null);

  const getCropData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (cropperRef.current?.cropper) {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas({
        rounded: true,
        imageSmoothingEnabled: true,
      });
      const imageData = croppedCanvas.toDataURL();

      if (croppedCanvas) {
        setCropData(imageData);
        setUpload(false);

        if (imageUpload) {
          imageUpload(imageData as any);
        }
      } else {
        console.error('Failed to get cropped canvas.');
      }
    } else {
      console.error('Cropper instance not available.');
    }
  };

  useEffect(() => {
    if (
      cropperRef.current &&
      typeof cropperRef.current?.cropper.zoomTo === 'number'
    ) {
      cropperRef.current?.cropper.zoomTo(Number(zoomSliderRef.current!.value));
    }
  }, [zoomLevel]);

  const handleZoomChange = (e: any) => {
    const newZoomLevel = parseFloat(e.target.value);
    setZoomLevel(newZoomLevel);

    if (cropperRef.current) {
      cropperRef.current?.cropper.zoomTo(Number(newZoomLevel));
    }
  };

  return (
    <>
      <CommonModal
        title={modalTitle}
        titleDesc={modalDesc}
        handleClose={() => {
          setUpload(false);
          setCropData(defaultImage);
        }}
        headerIcon={<Crop02 className="w-5 h-5" />}
        size={'max-w-[580px]'}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        primaryBtnOnClick={getCropData}
        secondaryBtnOnClick={() => {
          setUpload(false);
          setCropData(defaultImage);
        }}
      >
        <div className="p-5">
          <div className="h-[320px] mb-6 overflow-hidden rounded-3xl app-logo-img-block bg-baseBg">
            <Cropper
              ref={cropperRef}
              dragMode="move"
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={false}
              checkOrientation={true}
              cropBoxMovable={false}
              cropBoxResizable={false}
              // autoCropArea={0.8}
              // autoCrop={true}
              aspectRatio={1 / 1}
              style={{ height: 320, width: '100%' }}
              src={imageUrl}
              disabled={true}
              highlight={false}
              guides={false}
              zoomTo={0.5}
              zoomOnTouch={false}
              zoomOnWheel={false}
              preview=".img-preview"
            />
          </div>
          <div className="Crop-Controls w-full ">
            <div className="flex w-full items-center custom-range-slider">
              <input
                ref={zoomSliderRef}
                className="w-full"
                id="zoomSlider"
                type="range"
                min="0"
                max="3"
                step="0.01"
                value={zoomLevel}
                onChange={handleZoomChange}
              />
            </div>
            <div className="w-full flex justify-end">
              <h1 className="text-[14px] leading-[140%] -tracking-[0.18px] ">
                {Math.round((zoomLevel / 3) * 100)}%
              </h1>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default CropOrganizationImage;
