import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listIntegrationType = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.TYPE, {
    params,
  });

  return response;
};

export const listIntegration = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.LIST, {
    params,
  });

  return response;
};

export const mapService = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.MAP_SERVICE, {
    params,
  });

  return response;
};

export const setConfiguration = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.INTEGRATIONS.SET_CONFIGURATIONS}/${id}`,
    data
  );

  return response;
};

export const enableIntegration = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.INTEGRATIONS.ENABLE_INTEGRATION}/${id}`,
    data
  );

  return response;
};
