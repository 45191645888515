import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchBox from 'src/components/SearchBox';
import Toggle from 'src/components/Toggle/Toggle';
import { GetTopCustomerList } from 'src/services/CustomerService';

const MapIt = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;

  const mapContainerRef = useRef(null);
  const mapRef = useRef<any>(null);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [customers, setCustomers] = useState<any>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [mapFilters, setMapFilters] = useState<any>({
    isDisplayCustomers: false,
    isDisplayDrivers: false,
  });
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55], // Adjusted center to fit Canada and USA better
        zoom: 4, // Adjusted zoom level for a wider view of North America
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });
      mapRef.current.addControl(new mapboxgl.NavigationControl());
      mapRef.current.on('load', () => {
        setMapLoaded(true);
        const layers = mapRef.current.getStyle().layers;
        const labelLayerId = layers.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        ).id;

        mapRef.current.addLayer(
          {
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height'],
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height'],
              ],
              'fill-extrusion-opacity': 0.6,
            },
          },
          labelLayerId
        );
        mapRef.current.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                // feature for Mapbox DC
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [-77.03238901390978, 38.913188059745586],
                },
                properties: {
                  title: 'Mapbox DC',
                },
              },
              {
                // feature for Mapbox SF
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [-122.414, 37.776],
                },
                properties: {
                  title: 'Mapbox SF',
                },
              },
            ],
          },
        });
      });
    }
  }, []);

  console.log('customers :>> ', customers, '  ', isCustomerLoading);
  useEffect(() => {
    initializeMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initializeMap]);

  // Function to calculate distance between two points using Haversine formula
  const getDistance = (
    point1: [number, number],
    point2: [number, number]
  ): number => {
    const [lon1, lat1] = point1;
    const [lon2, lat2] = point2;
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  // Function to get population based on various properties
  const getPopulation = (feature: any): number => {
    let population =
      feature.properties?.population ||
      feature.properties?.population_rank ||
      (feature.properties?.place_rank
        ? feature.properties.place_rank * 10000
        : 0);

    if (population === 0) {
      const importance = feature.properties?.importance || 0;

      if (feature.place_type.includes('city')) {
        population = 500000 + importance * 1000000; // Larger estimate for cities
      } else if (feature.place_type.includes('town')) {
        population = 50000 + importance * 100000; // Medium estimate for towns
      } else {
        population = 5000 + importance * 10000; // Smaller estimate for other places
      }
    }

    return Math.round(population);
  };

  // Function to find cities along the route within a specified radius
  const getCitiesAlongRoute = async ({
    route,
    radius,
  }: {
    route: [number, number][];
    radius: number;
  }) => {
    const cities = [];
    const checkedCities = new Set();

    const numSamples = Math.min(500, route.length);
    const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));

    for (let i = 0; i < route.length; i += sampleInterval) {
      const [lon, lat] = route[i];

      try {
        const query = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`
        );
        const json = await query.json();

        if (json.features && json.features.length > 0) {
          for (const feature of json.features) {
            const cityName = feature.text;
            const fullName = feature.place_name;
            const cityCoords = feature.center;

            if (!checkedCities.has(fullName)) {
              const straightLineDistance = getDistance(route[i], cityCoords);

              if (straightLineDistance <= radius) {
                const population = getPopulation(feature);
                const cityInfo = {
                  name: cityName,
                  fullName: fullName,
                  distance: straightLineDistance,
                  population: population,
                  cordinates: cityCoords,
                  importance: feature.properties?.importance || 0,
                  placeType: feature.place_type || [],
                };

                // var marker = new mapboxgl.Marker()
                //   .setLngLat(cityCoords)
                //   .addTo(mapRef.current);

                // console.log('marker :>> ', marker);
                cities.push(cityInfo);
                checkedCities.add(fullName);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching place data:', error);
      }
    }

    // Sort cities by population (descending) and take top 10
    const sortedCities = cities
      .sort((a, b) => (b.population || 0) - (a.population || 0))
      .slice(0, 10);

    return sortedCities;
  };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (address: string): Promise<[number, number]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    return json.features[0].center;
  };

  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: string, to: string, radius: number) => {
      if (!mapLoaded || !mapRef.current) {
        console.error('Map not fully loaded yet');
        setSearchResult({
          status: 'error',
          error: 'Map not fully loaded yet. Please try again.',
        });

        return;
      }

      try {
        setHasSearched(true);
        setSearchResult({ status: 'loading', message: 'Fetching route...' });

        const fromCoords = await geocodeAddress(from);
        const toCoords = await geocodeAddress(to);

        if (mapRef.current.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${fromCoords[0]},${fromCoords[1]};${toCoords[0]},${toCoords[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
          { method: 'GET' }
        );
        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];

        const route = data.geometry.coordinates;
        const geojson = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route,
          },
        };

        mapRef.current.addSource('route', {
          type: 'geojson',
          data: geojson,
        });
        mapRef.current.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': 'purple',
            'line-width': 5,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }
        mapRef.current.fitBounds(bounds, { padding: 50 });

        const cities = await getCitiesAlongRoute({ route, radius });

        setSearchResult({
          status: 'success',
          from,
          to,
          distance: `${Math.round(data.distance / 1000)} km`,
          duration: `${Math.round(data.duration / 60)} minutes`,
          cities,
          radius,
        });
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded]
  );

  const getCustomer = async () => {
    setIsCustomerLoading(true);

    try {
      setHasSearched(false);
      setSearchResult(null);
      const result = await GetTopCustomerList();

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);

        for (const customer of filteredData) {
          const cityCoords = await geocodeAddress(customer.fullAddress);
          new mapboxgl.Marker().setLngLat(cityCoords).addTo(mapRef.current);
        }

        setCustomers(filteredData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    if (mapFilters.isDisplayCustomers) {
      mapRef.current.remove();
      mapRef.current = null;
      initializeMap();
      getCustomer();
    }

    if (!mapFilters.isDisplayCustomers) {
      mapRef.current.remove();
      mapRef.current = null;
      initializeMap();
    }
  }, [mapFilters]);

  // const getDrivingDistance = async (pointA: any, pointB: any) => {
  //   try {
  //     const query = await fetch(
  //       `https://api.mapbox.com/directions/v5/mapbox/driving/${pointA[0]},${pointA[1]};${pointB[0]},${pointB[1]}?access_token=${mapboxgl.accessToken}`
  //     );
  //     const json = await query.json();

  //     if (json.routes && json.routes.length > 0) {
  //       return json.routes[0].distance / 1000; // Convert to km
  //     } else {
  //       throw new Error('No route found');
  //     }
  //   } catch (error) {
  //     console.error('Error calculating driving distance:', error);

  //     return Infinity; // Return a large value to effectively exclude this city
  //   }
  // };

  const handleToggle = (event: any) => {
    const { checked, name } = event.target;
    setMapFilters((old: any) => ({ ...old, [name]: checked }));
  };

  return (
    <div className="relative h-[100vh] w-full">
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      <SearchBox onSearch={handleSearch} />

      <div className="fixed bottom-5 right-5 w-[300px] max-h-[700px] overflow-y-auto p-4 bg-white rounded-lg shadow-md z-[2]">
        <div className="flex flex-col gap-2 mb-4">
          <div className="flex">
            <Toggle
              id=""
              name="isDisplayCustomers"
              labelClassName="mr-2"
              isChecked={mapFilters.isDisplayCustomers}
              onChange={handleToggle}
            />
            <span className="text-sm fw-medium text-textSecondary">
              Display Customers
            </span>
          </div>
          <div className="flex">
            <Toggle
              id=""
              name="isDisplayTransit"
              labelClassName="mr-2"
              isChecked={mapFilters.isDisplayTransit}
              onChange={handleToggle}
            />
            <span className="text-sm fw-medium text-textSecondary">
              Display in-transit shipments
            </span>
          </div>
        </div>
        {isCustomerLoading ? <div>Loading.....</div> : ''}
        {hasSearched && searchResult && (
          <div className="text-sm">
            <p className="text-utilityBlack text-sm font-semibold mb-2">
              Search Results
            </p>
            {/* <h3 style={{ margin: '0 0 5px 0' }}>Search Results</h3> */}
            {searchResult.status === 'loading' && (
              <p>{searchResult?.message}</p>
            )}
            {searchResult.status === 'error' && (
              <p style={{ color: 'red' }}>{searchResult.error}</p>
            )}
            {searchResult.status === 'success' && (
              <>
                <p>
                  <strong>From:</strong> {searchResult.from}
                </p>
                <p className="mt-2">
                  <strong>To:</strong> {searchResult.to}
                </p>
                <p className="mt-2">
                  <strong>Distance:</strong> {searchResult.distance}
                </p>
                <p className="mt-2">
                  <strong>Duration:</strong> {searchResult.duration}
                </p>
                <p className="mt-4">
                  <strong>
                    Top 10 largest cities within {searchResult.radius} km of the
                    route:
                  </strong>
                </p>
                {searchResult.cities.length > 0 ? (
                  <ul style={{ paddingLeft: '20px', margin: '5px 0' }}>
                    {searchResult.cities.map((city: any, index: number) => (
                      <li key={index}>
                        {city.fullName} ({Math.round(city.distance)} km)
                        {city.population !== undefined
                          ? ` - Pop: ${city.population.toLocaleString()}`
                          : ' - Pop: Unknown'}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No cities found within the specified radius.</p>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
};
export default MapIt;
