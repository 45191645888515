import { CalendarCheck01, User01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import { updateSalesRepHistory } from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

interface IProps {
  editAllocationHistoryModal: any;
  handleClose: any;
}

const EditAllocationHistoryModal = ({
  editAllocationHistoryModal,
  handleClose,
}: IProps) => {
  const [isUpdateSalesRepHistoryLoading, setIsUpdateSalesRepHistoryLoading] =
    useState(false);
  const [startDateValue, setStartDateValue] = useState(
    editAllocationHistoryModal.startDate
  );
  const [endDateValue, setEndDateValue] = useState(
    editAllocationHistoryModal.endDate
  );

  const handleUpdateSalesHistory = () => {
    setIsUpdateSalesRepHistoryLoading(true);
    updateSalesRepHistory(editAllocationHistoryModal?.id, {
      startDate: startDateValue
        ? moment(startDateValue).format('YYYY-MM-DD')
        : null,
      endDate: endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null,
    })
      .then((response: any) => {
        WalToast.success(response?.message);
        handleClose(true);
      })
      .catch((e: any) => {
        console.log('Error ', e?.response?.data?.message);
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong updating sales rep history.'
        );
      })
      .finally(() => {
        setIsUpdateSalesRepHistoryLoading(false);
      });
  };

  const handleStartDateChange = (event: any) => {
    const { value } = event;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: any) => {
    const { value } = event;
    setEndDateValue(value);
  };

  return (
    <CommonModal
      title={'Edit Sales Representative'}
      titleDesc={'Update and mange your sales representative'}
      handleClose={() => handleClose(false)}
      headerIcon={<User01 />}
      size={'xl:max-w-[647px] max-w-[551px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleUpdateSalesHistory}
      primaryBtnLoading={isUpdateSalesRepHistoryLoading}
      primaryBtnDisabled={isUpdateSalesRepHistoryLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isUpdateSalesRepHistoryLoading}
    >
      <div className="w-full p-5">
        <form className="flex flex-col gap-4">
          <InputText
            inputName="firstName"
            placeholder="Enter first name"
            parentClassName="flex-1"
            value={editAllocationHistoryModal?.salesRepFullName}
            label="Current Sales Representative"
            labelClassName="block mb-1.5"
            disabled={true}
          />

          <div className="flex max-sm:flex-col sm:-mx-3">
            <DateSelect
              inputName="startDate"
              className={`form_control`}
              parentClassName="sm:px-3 sm:w-1/2 one-month-datepicker"
              label="Start Date"
              labelClassName="block"
              placeholder="Start Date"
              dateFormat="dd/MM/yyyy"
              selected={
                startDateValue ? moment(startDateValue).toDate() : undefined
              }
              onChangeFunc={handleStartDateChange}
              icon={<CalendarCheck01 className="h-5 w-5" />}
            />

            <DateSelect
              inputName="endDate"
              className={`form_control`}
              parentClassName="sm:px-3 sm:w-1/2 one-month-datepicker max-sm:mt-4"
              label="End Date"
              labelClassName="block"
              placeholder="End Date"
              dateFormat="dd/MM/yyyy"
              selected={
                endDateValue ? moment(endDateValue).toDate() : undefined
              }
              onChangeFunc={handleEndDateChange}
              icon={<CalendarCheck01 className="h-5 w-5" />}
              isClearable={!editAllocationHistoryModal.endDate}
            />
          </div>
        </form>
      </div>
    </CommonModal>
  );
};

export default EditAllocationHistoryModal;
