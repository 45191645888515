import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

import { CURRENCY, MAP_API, TRIMBLE_KEY } from '../constants/common';

export const BasicContext = createContext<any>(null);

export const BasicProvider = (props: any) => {
  const { children } = props;
  const [currency, setCurrency] = useState<string>(CURRENCY.CAD);
  const [mapService, setMapService] = useState<string>(MAP_API);
  const [googleKey, setGoogleKey] = useState<string>(window.GOOGLE_API_KEY);
  const [trimbleKey, setTrimbleKey] = useState<string>(TRIMBLE_KEY);
  const [tooltipGlobalOpen, setTooltipGlobalOpen] = useState(false);
  const [lastSyncDateTimeActual, setLastSyncDateTimeActual] =
    useState(undefined);
  const [allTimeStartDateActual, setAllTimeStartDateActual] =
    useState(undefined);

  const [actionTooltipGlobalOpen, setActionTooltipGlobalOpen] = useState(false);
  const [actionDelayTimeout, setActionDelayTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const value = {
    currency,
    setCurrency,
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    actionTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
    actionDelayTimeout,
    setActionDelayTimeout,
    mapService,
    setMapService,
    googleKey,
    setGoogleKey,
    trimbleKey,
    setTrimbleKey,
    lastSyncDateTimeActual,
    setLastSyncDateTimeActual,
    allTimeStartDateActual,
    setAllTimeStartDateActual,
  };

  return (
    <BasicContext.Provider value={value}>{children}</BasicContext.Provider>
  );
};

BasicProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
