import React from 'react';

const OverviewTabOrderLoadingRaw = () => (
  <>
    <td className="px-5 py-4 min-w-36 max-w-36">
      <h6 className="text-grayLight900 font-semibold leading-tight truncate h-8 flex items-center ">
        <span className="custom-loading">WAL-TC3B-163</span>
      </h6>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">Dec 14, 2023</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">1234</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">123</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">123456</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <div className="flex items-center min-w-92">
        <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase custom-loading">
          J D
        </div>

        <div className="pl-2">
          <h6 className="text-grayLight900 font-medium leading-tight custom-loading">
            ASDF ASDF_ASDF
          </h6>
        </div>
      </div>
    </td>
  </>
);

export default OverviewTabOrderLoadingRaw;
