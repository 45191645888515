import { yupResolver } from '@hookform/resolvers/yup';
import {
  ArrowLeft,
  Check,
  Plus,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import ButtonCmp from 'src/components/ButtonCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { AuthContext } from 'src/context/AuthContext';
import { listUser, removeUserFromTeam } from 'src/services/TeamMemberService';
import { TeamDetailById, updateTeam } from 'src/services/TeamService';
import { getShortName, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import Team1 from '../../../assets/img/default-team-images/team1.png';
import Team10 from '../../../assets/img/default-team-images/team10.png';
import Team2 from '../../../assets/img/default-team-images/team2.png';
import Team3 from '../../../assets/img/default-team-images/team3.png';
import Team4 from '../../../assets/img/default-team-images/team4.png';
import Team5 from '../../../assets/img/default-team-images/team5.png';
import Team6 from '../../../assets/img/default-team-images/team6.png';
import Team7 from '../../../assets/img/default-team-images/team7.png';
import Team8 from '../../../assets/img/default-team-images/team8.png';
import Team9 from '../../../assets/img/default-team-images/team9.png';
import AccessControlModal from '../AccessControlModal';
import CreateUpdateMemberModal from '../CreateUpdateMemberModal';

import MemberProfile from './MemberProfile';
import TeamDetailRow from './TeamDetailRow';

const initAction = {
  mode: null,
  team: false,
};

interface IMember {
  id: number;
  name: string;
  // add other properties as needed
}

const TeamDetail = () => {
  const teamId = window.location.pathname?.split('/').pop();
  const navigate = useNavigate();
  const { reloadUser } = useContext(AuthContext);
  const [teamMember, setTeamMember] = useState<any>();
  const [isTeamDetailLoading, setIsTeamDetailLoading] = useState(false);
  const [teamData, setTeamData] = useState<any>();
  const [preview, setPreview] = useState<string | null>(null);
  const [action, setAction] = useState(initAction);
  const [teamManager, setTeamManager] = useState<IMember[]>([]);
  const [teamUsers, setTeamUser] = useState<IMember[]>([]);
  const [isTeamEdit, setIsTeamEdit] = useState(false);
  const [isShowManagerList, setIsShowManagerList] = useState(false);
  const [totalMember, setTotalMember] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  const [showManagerList, setShowManagerList] = useState([]);
  // const [showManagerListOriginal, setShowManagerListOriginal] = useState([]);
  const [showManagerListSearchValue, setShowManagerListSearchValue] =
    useState('');

  const [isShowMemberList, setIsShowMemberList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showMemberList, setShowMemberList] = useState([]);
  const [showManagerListOriginal, setShowManagerListOriginal] = useState([]);
  const [showMemberListOriginal, setShowMemberListOriginal] = useState([]);
  const [showMemberListSearchValue, setShowMemberListSearchValue] =
    useState('');

  const teamDefaultImages = [
    { id: 'team1', image: Team1 },
    { id: 'team2', image: Team2 },
    { id: 'team3', image: Team3 },
    { id: 'team4', image: Team4 },
    { id: 'team5', image: Team5 },
    { id: 'team6', image: Team6 },
    { id: 'team7', image: Team7 },
    { id: 'team8', image: Team8 },
    { id: 'team9', image: Team9 },
    { id: 'team10', image: Team10 },
  ];

  const supportedFormats = [
    'image/svg+xml',
    'image/png',
    'image/jpeg',
    'image/gif',
  ];

  const updateSchema = yup.object().shape({
    teamName: yup.string().required('Team name is required.'),
    image: yup.string().nullable(),
    imageFile: yup
      .mixed()
      .test({
        name: 'fileFormat',
        message: 'Unsupported image format.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return supportedFormats.includes(value.type);
        },
      })
      .test({
        name: 'fileSize',
        message: 'Image size is too large.',
        test: (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= 1048576; // 1MB in bytes
        },
      })
      .test(
        'fileResolution',
        'The image must be 800x800 pixels or smaller',
        (value) =>
          new Promise((resolve, reject) => {
            if (!value) {
              return resolve(true);
            }

            if (!(value instanceof Blob)) {
              return resolve(true);
            }

            const file: any = value;
            const reader = new FileReader();

            reader.onload = (event: any) => {
              const img = new Image();

              img.onload = () => {
                const width = img.width;
                const height = img.height;
                resolve(width <= 800 && height <= 800);
              };
              img.onerror = reject;
              img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      ),
  });

  const {
    handleSubmit,
    control,
    setValue,
    // setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      image: teamData?.defaultImageId,
      teamName: teamData?.name,
    },
  });

  const onCancel = () => {
    setValue('teamName', teamData?.name);

    if (teamData?.defaultImageId) {
      setValue('image', teamData?.defaultImageId, {
        shouldValidate: true,
      });
    }
    const managers = teamData?.members.filter((sm: any) =>
      sm.roleInTeam === 'Manager' ? sm : false
    );
    const users = teamData?.members.filter((sm: any) =>
      sm.roleInTeam === 'User' ? sm : false
    );

    setTeamManager(managers);
    setTeamUser(users);
    setIsTeamEdit(false);
  };

  const getTeamById = () => {
    setIsTeamDetailLoading(true);
    TeamDetailById(teamId)
      .then((response: any) => {
        setTeamData(response.data);
        setValue('teamName', response?.data?.name);

        // set default image
        if (response?.data?.defaultImageId) {
          setValue('image', response?.data?.defaultImageId, {
            shouldValidate: true,
          });
        }

        if (response?.data?.iconUrl && response?.data?.icon) {
          setPreview(`${response?.data?.iconUrl}${response?.data?.icon}`);
        }

        // set default image
        const managers = response?.data?.members.filter((sm: any) =>
          sm.roleInTeam === 'Manager' ? sm : false
        );
        const users = response?.data?.members.filter((sm: any) =>
          sm.roleInTeam === 'User' ? sm : false
        );

        setTeamManager(managers);
        setTeamUser(users);
      })
      .finally(() => {
        setIsTeamDetailLoading(false);
      })
      .catch(console.log);
  };

  const getShowFilteredMemberList = (
    searchValue: string,
    selectedMembersIdList: number[],
    memberListOriginal: any
  ) => {
    const filteredList = memberListOriginal.filter(
      (originalMemberData: any) => {
        const fullName = `${originalMemberData.firstName} ${originalMemberData.lastName}`;

        const isFullNameMatched = fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isFirstNameMatched = originalMemberData.firstName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isLastNameMatched = originalMemberData.lastName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isEmailMatched = originalMemberData.email
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        // const isIdMatchedWithSelectedMembers = selectedMembersIdList.includes(
        //   originalMemberData.id
        // );

        // return (
        //   (isFullNameMatched ||
        //     isFirstNameMatched ||
        //     isLastNameMatched ||
        //     isEmailMatched) &&
        //   !isIdMatchedWithSelectedMembers
        // );
        return (
          isFullNameMatched ||
          isFirstNameMatched ||
          isLastNameMatched ||
          isEmailMatched
        );
      }
    );

    return [...filteredList] as any;
  };

  const loadMembersList = (userType?: string) => {
    let listUserPayload: any = {};

    if (userType) {
      listUserPayload.type = userType;
    }

    listUser(listUserPayload)
      .then((response: any) => {
        // filtering response members based on current popup state
        let responseMembers = [];

        responseMembers = response.data
          .filter(
            (resDataMember: any) =>
              resDataMember.role.roleName === 'admin' ||
              resDataMember.role.roleName === 'manager'
          )
          .filter((rec: any) => rec);
        setShowManagerList(responseMembers);
        setShowMemberList(response.data);

        setShowMemberListOriginal(response.data);
        setShowManagerListOriginal(responseMembers);
        // let selectedMembersId: any = [];
        // const filteredList = getShowFilteredMemberList(
        //   '',
        //   selectedMembersId,
        //   responseMembers
        // );
        // setShowMemberList(filteredList);
      })
      .catch((e) => {
        WalToast.error(
          e?.response?.data?.message ?? 'Issue fetching available members',
          ''
        );
        console.log(e);
      });
  };

  useEffect(() => {
    loadMembersList();
  }, []);

  useEffect(() => {
    const selectedMembersIds = teamManager.map((sm) => sm.id);
    const filteredList = getShowFilteredMemberList(
      showManagerListSearchValue,
      selectedMembersIds,
      showManagerListOriginal
    );
    setShowManagerList(filteredList);
  }, [teamManager, showManagerListSearchValue]);

  useEffect(() => {
    setTotalMember(teamManager?.length + teamUsers?.length);
  }, [teamManager, teamUsers]);

  useEffect(() => {
    const selectedMembersIds = teamUsers.map((sm) => sm.id);
    const filteredList = getShowFilteredMemberList(
      showMemberListSearchValue,
      selectedMembersIds,
      showMemberListOriginal
    );
    setShowMemberList(filteredList);
    setTotalMember(teamManager?.length + teamUsers?.length);
  }, [teamUsers, showMemberListSearchValue]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setPreview(null);
    const file = acceptedFiles[0];
    setValue('imageFile', file, { shouldValidate: true });
    setValue('image', undefined, { shouldValidate: true });

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          setPreview(img.src);
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const createPayloadForm = new FormData();
    createPayloadForm.append('name', data.teamName);

    if (data.image) {
      const selectedImage = teamDefaultImages.find(
        (tdi) => tdi.id === data.image
      )!;
      createPayloadForm.append('icon', selectedImage.image);
      createPayloadForm.append('defaultImageId', selectedImage.id);
    } else if (data.imageFile) {
      createPayloadForm.append('icon', data.imageFile);
    }

    try {
      const managerIds = teamManager.map((e: any) => e.id);
      createPayloadForm.append('managerIds', managerIds.toString());
      const userIds = teamUsers.map((e: any) => e.id);
      createPayloadForm.append('userIds', userIds.toString());
      await updateTeam(createPayloadForm, teamData.id);
      getTeamById();
      setIsTeamEdit(false);
      setIsLoading(false);
      WalToast.success('Team updated Successfully');
    } catch (e) {
      setIsLoading(false);
      WalToast.error('New team not updated Successfully');
    }
    // }
  };

  const handleDrawerClose = useCallback(() => {
    reloadUser();
    setAction(initAction);
    setTeamMember({});
  }, []);

  useEffect(() => {
    setIsTeamDetailLoading(true);
    getTeamById();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      setIsTeamDetailLoading(true);
      getTeamById();
    }
  }, [isRefresh]);

  const handleActionType =
    (actionKey: any, mode: any = null, teamMemberRec: any = null) =>
    () => {
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));

      if (teamMemberRec) {
        setTeamMember(teamMemberRec);
      }
      // setTooltipGlobalOpen(false);
      // setActionTooltipGlobalOpen(false);
    };

  const handleUserDeleteConfirmationClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        setTeamMember(null);

        let isMemebrExit = teamData?.members?.some(
          (user: any) => user.teamId == teamId && user.id === teamMember?.id
        );

        if (isMemebrExit) {
          removeUserFromTeam({ userId: teamMember?.id, teamId: teamData?.id })
            .then(() => {
              WalToast.success(
                `${teamMember?.firstName ?? 'Member'} ${
                  teamMember?.lastName ?? ''
                } removed successfully`
              );
              getTeamById();
            })
            .catch(() => WalToast.error('Member not removed'));
        } else {
          setTeamManager([
            ...teamManager.filter((sm) => sm.id !== teamMember?.id),
          ]);
          setTeamUser([...teamUsers.filter((sm) => sm.id !== teamMember?.id)]);
        }
      }
    },
    [teamMember, teamData]
  );

  const headCells = useMemo(
    () => [
      {
        id: 'firstName',
        name: 'Name',
      },
      {
        id: 'jobRole',
        name: 'Role',
      },
      {
        id: 'displayName',
        name: 'Account Type',
      },
      {
        id: 'lastLoginAt',
        name: 'Last Active',
      },
      {
        id: 'action',
      },
    ],
    []
  );

  return (
    <>
      <PageSectionLayout
        header={
          <>
            {/* <ul className="mb-4 flex flex-wrap items-center gap-x-2 pb-1">
              <li className="p-1 text-borderPrimary">
                <div className="cursor-pointer" onClick={() => navigate('/')}>
                  <Home01 className="w-4 h-4" />
                </div>
              </li>
              <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <div
                  className="px-2 cursor-pointer"
                  onClick={() => navigate('/customers')}
                >
                  Customer
                </div>
              </li>
              <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <div className="px-2 cursor-pointer">Customer Detail</div>
              </li>
            </ul> */}
          </>
        }
        contentClassName="!p-0"
        headerClassName="!p-0"
      >
        <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[102px] before:h-[70px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
          <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
            <div
              className="flex items-center cursor-pointer gap-1.5"
              onClick={() =>
                history.length > 3 ? navigate(-1) : navigate('/team-management')
              }
            >
              <ArrowLeft className="h-4 w-4" />
              <span className="text-grayLight600 text-xs font-semibold">
                Back to Teams{' '}
              </span>
            </div>
            <div
              className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer"
              onClick={() =>
                history.length > 3 ? navigate(-1) : navigate('/team-management')
              }
            >
              <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
            </div>
          </div>

          <div className=" xlm:px-8 px-5 relative w-full xl:pt-[10px] sm:pt-2 pt-[26px] pb-[26px] flex max-sm:flex-col sms:gap-4 gap-2 sm:items-end justify-between">
            <div className="flex ms:gap-4 gap-2 smd:items-end w-full">
              <label
                className={`relative [&>div:nth-child(2)]:opacity-0 hover:[&>div:nth-child(2)]:opacity-100 xlm:w-36 xl:w-36 mdm:w-28 sm:w-24 w-16 xlm:h-36 xl:h-36 mdm:h-28 sm:h-24 h-16 rounded-full overflow-hidden justify-center items-center flex border-4 border-white bg-utilityGray100 shadow-lg text-primary xl:text-6xl sm:text-4xl sms:text-2xl text-base font-semibold tracking-[-1.2px] flex-none ${
                  isTeamDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {preview ? (
                  <img
                    className="w-full h-full relative"
                    src={preview}
                    alt="House Team"
                    title="House Team"
                    onError={onError}
                  />
                ) : (
                  <img
                    className="w-full h-full relative "
                    src={TeamIcon1}
                    alt="House Team"
                    title="House Team"
                  />
                )}
              </label>

              <div className="smd:flex-1 flex smd:flex-row flex-col justify-between sm:mt-2 gap-2 smd:items-end">
                {/* <div className="flex justify-between"> */}
                <div className="">
                  <h6
                    className={`mr-3 text-grayLight900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]  ${
                      isTeamDetailLoading
                        ? 'custom-loading px-5 mt-1 !inline-block h-7'
                        : ''
                    }`}
                  >
                    {teamData?.name ?? 'House Team'}
                  </h6>

                  <div
                    className={`flex mt-1 ${
                      isTeamDetailLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <span className="text-grayLight600 sm:text-sm text-xs sm:mt-0 mt-0.5 font-normal">
                      {totalMember} {totalMember > 1 ? 'Members' : 'Member'}
                    </span>
                  </div>
                </div>

                {isTeamEdit ? (
                  <div className="flex gap-4 smd:pt-2">
                    <ButtonCmp
                      type="button"
                      className="btn_secondary_black shadow py-2 px-3"
                      onClick={onCancel}
                    >
                      Cancel
                    </ButtonCmp>
                    <ButtonCmp
                      type="submit"
                      className="btn_primary  shadow py-2 px-3"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isLoading}
                    >
                      Save Changes
                    </ButtonCmp>
                  </div>
                ) : (
                  <ButtonCmp
                    type="button"
                    className="btn-outline-primary shadow py-2 px-3"
                    onClick={() => setIsTeamEdit(true)}
                  >
                    Edit Team
                  </ButtonCmp>
                )}
              </div>
            </div>
          </div>
          <div className="border-t border-utilityGray200 xlm:px-8 px-5 py-[30px] bg-white flex flex-wrap flex-1">
            <form className="h-full w-full">
              <div className="">
                <div className="border-b border-utilityGray200 py-4 max-md:pt-0 flex xls:gap-8 gap-4 sm:flex-row flex-col flex-wrap">
                  <label className="xls:flex-[0_0_280px] flex-[0_0_100%]">
                    <div className="text-textSecondary text-sm font-semibold">
                      Team name
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      Add Your team details
                    </p>{' '}
                  </label>

                  <Controller
                    name="teamName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="teamName"
                        placeholder="Enter team name"
                        parentClassName="max-w-[770px] flex-1"
                        value={value}
                        disabled={!isTeamEdit}
                        onChangeFunc={onChange}
                        errorText={errors.teamName ? 'Team is required' : null}
                      />
                    )}
                  />
                </div>
                {isTeamEdit && (
                  <div className="border-b border-utilityGray200 py-4 flex xls:gap-8 gap-4 sm:flex-row flex-col flex-wrap">
                    <label className="xls:flex-[0_0_280px] flex-[0_0_100%]">
                      <h6 className="text-textSecondary text-sm font-semibold">
                        Team Icon
                      </h6>
                      <p className="text-grayLight600 font-normal text-xs">
                        Add your team logo
                      </p>{' '}
                    </label>
                    <div className="xls:max-w-[770px] flex-1 border border-utilityGray200 rounded-xl p-3 flex flex-wrap xlm:gap-[17px] md:gap-4 gap-3">
                      <Controller
                        name="image"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            {teamDefaultImages.map((defaultImage) => (
                              <>
                                <div
                                  className="relative cursor-pointer"
                                  key={defaultImage.id}
                                  onClick={() => {
                                    onChange(defaultImage.id);
                                    setPreview(defaultImage.image);
                                    setValue('imageFile', false, {
                                      shouldValidate: true,
                                    });
                                  }}
                                >
                                  <div
                                    className={`sm:w-[52px] w-11 sm:h-[52px] h-11 rounded-full  flex-none p-1.5 border-2  ${
                                      value === defaultImage.id
                                        ? 'border-primary'
                                        : 'border-transparent'
                                    } `}
                                  >
                                    <img
                                      src={defaultImage.image}
                                      alt=""
                                      className="h-full w-full border-[0.75px] border-black/[0.08] rounded-full "
                                    />
                                  </div>

                                  {/* <div className="absolute bottom-0 right-0">
                                  <Radio
                                    id={`team_default_image_${defaultImage.id}`}
                                    onChangeFunc={() => {}}
                                    inputName={'teamDefaultImage'}
                                    value={defaultImage.id}
                                    checked={value === defaultImage.id}
                                  />
                                </div> */}
                                </div>
                              </>
                            ))}
                            <TooltipCmp message="Upload Your Own Icon">
                              <div
                                {...getRootProps()}
                                className={`sm:w-[52px] w-10 sm:h-[52px] h-10 rounded-full  flex-none p-1.5 border-2 ${
                                  getValues('imageFile')
                                    ? 'border-primary'
                                    : 'border-transparent '
                                } cursor-pointer`}
                              >
                                <div className="w-full h-full bg-utilityGray100  border-[0.75px] border-black/[0.08] rounded-full flex items-center justify-center">
                                  <Plus className="w-[22px] h-[22px] text-gray500" />
                                </div>
                              </div>
                            </TooltipCmp>
                            <input
                              accept=".svg, .png, .jpg, .jpeg"
                              {...getInputProps()}
                            />
                          </>
                        )}
                      />
                      <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                        {errors.imageFile && (
                          <ErrorMsg errorText={errors.imageFile.message} />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="border-b border-utilityGray200 py-4 flex xls:gap-8 gap-4 sm:flex-row flex-col flex-wrap">
                  <label className="xls:flex-[0_0_280px] flex-[0_0_100%]">
                    <div className="text-textSecondary text-sm font-semibold">
                      Team Managers
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      Manage your team manager roles/permissions.
                    </p>
                  </label>
                  <div className="xls:max-w-[770px] flex-1 xls:w-[calc(100%_-_333px)] w-full">
                    {isTeamEdit && (
                      <div className="relative mb-3">
                        <InputText
                          inputName="teamSearch"
                          placeholder="Search"
                          isClearable
                          className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                          icon={
                            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                          }
                          value={showManagerListSearchValue}
                          onChangeFunc={(e: any) => {
                            setIsShowManagerList(e?.target?.value !== '');
                            setShowManagerListSearchValue(e.target.value);
                          }}
                          onFocus={() => {
                            setIsShowManagerList(true);
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsShowManagerList(false);
                            }, 200);
                          }}
                        />
                        {showManagerList?.length > 0 && (
                          <div
                            className={`mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                              isShowManagerList ? 'absolute' : 'hidden'
                            }`}
                          >
                            {showManagerList.map((memberList: any) => (
                              <div
                                key={`member_select_list_${memberList.id}`}
                                className={`w-full flex justify-between rounded-lg cursor-pointer hover:bg-gray50 mb-2 ${
                                  teamData.members.some(
                                    (member: any) => member.id === memberList.id
                                  )
                                    ? 'bg-gray50'
                                    : ''
                                }`}
                                onClick={() => {
                                  setTeamManager([...teamManager, memberList]);
                                  setShowManagerListSearchValue('');
                                }}
                              >
                                <div className="px-3.5 py-2.5 justify-start  gap-2 flex items-center">
                                  {memberList.image ? (
                                    <img
                                      className="w-9 h-9 rounded-full border-black/[0.08] border-[0.75px]"
                                      src={
                                        memberList.imageUrl + memberList.image
                                      }
                                      alt={memberList.firstName}
                                      title={memberList.firstName}
                                      onError={onError}
                                    />
                                  ) : (
                                    <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase border-black/[0.08] border-[0.75px] flex-none">
                                      {getShortName(
                                        `${memberList.firstName} ${memberList.lastName}`
                                      )}
                                    </div>
                                  )}
                                  <div className="flex-col justify-start items-start flex ">
                                    <div className="text-grayLight900 text-xs font-medium leading-tight">{`${memberList.firstName} ${memberList.lastName}`}</div>
                                    <div className="text-grayLight600 text-sm font-normal leading-tight break-all">
                                      {/* {memberList.email} */}
                                      {memberList.jobRole}
                                    </div>
                                  </div>
                                </div>
                                {teamData.members.some(
                                  (member: any) => member.id === memberList.id
                                ) ? (
                                  <div className="flex-col justify-center items-center flex ">
                                    <Check className="w-5 h-5 text-primary m-2" />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {/* <div className="[&:empty]:d-none [&:empty]:mb-0 mb-5 "> */}

                    <div
                      data-test-id="userTest"
                      className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col"
                    >
                      <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination rounded-xl ">
                        <TableCmp
                          headCells={headCells}
                          tableDataArr={teamManager}
                          TableRowCmp={TeamDetailRow}
                          tableRowCmpProps={{
                            handleActionType: handleActionType,
                            roleInTeam: 'Manager',
                            isTeamEdit: isTeamEdit,
                          }}
                          isTableRowClickable={false}
                        />
                        {!teamManager.length && !isTeamDetailLoading && (
                          <div className="h-full w-full flex justify-center items-center object-cover">
                            <NotFoundUI
                              title="No managers found"
                              desc="There are no data for team manager you can add new team manager for this team."
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-utilityGray200 py-4 flex xls:gap-8 gap-4 sm:flex-row flex-col flex-wrap">
                  <label className="xls:flex-[0_0_280px] flex-[0_0_100%]">
                    <div className="text-textSecondary text-sm font-semibold">
                      Team members
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      Manage your existing team and change roles/permissions.
                    </p>
                  </label>
                  <div className="xls:max-w-[770px] flex-1 xls:w-[calc(100%_-_333px)] w-full">
                    {isTeamEdit && (
                      <div className="relative mb-3">
                        <InputText
                          inputName="teamSearch"
                          placeholder="Search"
                          isClearable
                          className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                          icon={
                            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                          }
                          value={showMemberListSearchValue}
                          onChangeFunc={(e: any) => {
                            setIsShowMemberList(e?.target?.value !== '');
                            setShowMemberListSearchValue(e.target.value);
                          }}
                          onFocus={() => setIsShowMemberList(true)}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsShowMemberList(false);
                            }, 200);
                          }}
                        />
                        {showMemberList?.length > 0 && (
                          <div
                            className={`mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                              isShowMemberList ? 'absolute' : 'hidden'
                            }`}
                          >
                            {/* <div
                            className={` mt-3 xlm:w-[50%] xls:w-[75%] lg:w-[75%] sm:w-[70%] max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                              isShowMemberList ? 'absolute' : 'hidden'
                            }`}
                          > */}
                            {showMemberList.map((memberList: any) => (
                              <div
                                key={`member_select_list_${memberList.id}`}
                                className={`w-full flex justify-between items-center rounded-lg cursor-pointer hover:bg-gray50 mb-2 ${
                                  teamData.members.some(
                                    (member: any) => member.id === memberList.id
                                  )
                                    ? 'bg-gray50'
                                    : ''
                                }`}
                                onClick={() => {
                                  setTeamUser([...teamUsers, memberList]);
                                  setShowMemberListSearchValue('');
                                }}
                              >
                                <div className="px-3.5 py-2.5  justify-start  gap-2 flex items-center">
                                  {memberList.image ? (
                                    <img
                                      className="w-9 h-9 rounded-full border-black/[0.08] border-[0.75px]"
                                      src={
                                        memberList.imageUrl + memberList.image
                                      }
                                      alt={memberList.firstName}
                                      title={memberList.firstName}
                                      onError={onError}
                                    />
                                  ) : (
                                    <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase border-black/[0.08] border-[0.75px] flex-none">
                                      {getShortName(
                                        `${memberList.firstName} ${memberList.lastName}`
                                      )}
                                    </div>
                                  )}
                                  <div className="flex-col justify-start items-start flex ">
                                    <div className="text-grayLight900 text-xs font-medium leading-tight">{`${memberList.firstName} ${memberList.lastName}`}</div>
                                    <div className="text-grayLight600 text-sm font-normal leading-tight break-all">
                                      {memberList.jobRole}
                                    </div>
                                  </div>
                                </div>
                                {teamData.members.some(
                                  (member: any) => member.id === memberList.id
                                ) ? (
                                  <Check className="w-5 h-5 text-primary m-2" />
                                ) : (
                                  ''
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      data-test-id="userTest"
                      className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col"
                    >
                      <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination rounded-xl">
                        <TableCmp
                          headCells={headCells}
                          tableDataArr={teamUsers}
                          TableRowCmp={TeamDetailRow}
                          tableRowCmpProps={{
                            handleActionType: handleActionType,
                            roleInTeam: 'User',
                            isTeamEdit: isTeamEdit,
                          }}
                          isTableRowClickable={false}
                        />
                        {!teamUsers.length && !isTeamDetailLoading && (
                          <div className="h-full w-full flex justify-center items-center object-cover">
                            <NotFoundUI
                              title="No members found"
                              desc="There are no data for team member you can add new team member for this team."
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isTeamEdit ? (
                  <div className="flex gap-4 py-4 justify-end">
                    <ButtonCmp
                      type="button"
                      className="btn_secondary_black shadow py-2 px-3"
                      onClick={onCancel}
                    >
                      Cancel
                    </ButtonCmp>
                    <ButtonCmp
                      type="submit"
                      className="btn_primary shadow py-2 px-3"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isLoading}
                    >
                      Save Changes
                    </ButtonCmp>
                  </div>
                ) : // Render something else or nothing if isTeamEdit is false
                null}
              </div>
            </form>
            {action.mode === 'user-update' && (
              <CreateUpdateMemberModal
                mode={action.mode}
                team={teamMember}
                handleClose={handleDrawerClose}
                setIsRefresh={setIsRefresh}
              />
            )}

            {action.mode === 'access-control' && (
              <AccessControlModal
                handleClose={handleDrawerClose}
                team={teamMember}
                setIsRefresh={setIsRefresh}
              />
            )}

            {action.mode === 'user-remove-from-team' && (
              <DeleteModal
                handleClose={handleUserDeleteConfirmationClose}
                moduleName="team"
                moduleTitle={
                  <>
                    Delete {teamMember.firstName ?? ''} from Team{' '}
                    {teamData?.name ?? ''}
                  </>
                }
              />
            )}
            {action.mode === 'view_profile' && (
              <MemberProfile
                handleClose={handleDrawerClose}
                teamMemberId={teamMember?.id}
              />
            )}
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default TeamDetail;
