/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';

import { predictivePriceOptions } from './PredictivePrice.interface';

const PredictivePriceChart = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    getPredictivePrice(275)
      .then((response: any) => {
        if (response && response.data) {
          setPredictivePriceData(response?.data ?? []);
          setSeries(response?.data?.graphData?.series ?? []);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Predictive Pricing"
            desc="Track, manage and forecast your customers and orders."
          />
        }
      >
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col flex-1 overflow-hidden">
          <div className="table-bottom-header table-tab-wrap">
            <div className="table-header-bottom-left">
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md text-xs whitespace-nowrap font-semibold sm:flex hidden`}
              >
                <div className={`py-4 text-xs `}>
                  <TooltipCmp
                    message={`${predictivePriceData?.from}`}
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="flex mb-1">
                      <span className="text-gray500 font-normal">
                        From:&nbsp;
                      </span>
                      <span
                        className={`font-medium truncate max-w-52 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {predictivePriceData?.from}
                      </span>
                    </p>
                  </TooltipCmp>
                  <TooltipCmp
                    message={`${predictivePriceData?.to}`}
                    parentClassName="md:!p-4 !p-1.5 max-w-90"
                  >
                    <p className="flex">
                      <span className="text-gray500 font-normal">
                        To:&nbsp;
                      </span>
                      <span
                        className={`font-medium truncate max-w-52 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {predictivePriceData?.to}
                      </span>
                    </p>
                  </TooltipCmp>
                </div>
              </div>
            </div>
            <div className="gap-4">
              <div className="flex">
                <div className="mr-8">
                  <span className="text-sm truncate max-w-52">
                    Confidence Level
                  </span>
                  <div className="flex gap-2">
                    <div className="progress-range-container">
                      <div
                        className={`progress-range-bar  ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                        style={{
                          width: `${predictivePriceData?.confidenceLevel}%`,
                        }}
                      ></div>
                    </div>
                    <span className={`${isLoading ? 'custom-loading' : ''}`}>
                      {predictivePriceData?.confidenceLevel}
                    </span>
                  </div>
                  <div className="flex mt-3 gap-4">
                    <p className="flex text-xs">
                      <span className="text-gray500 font-normal">
                        Start:&nbsp;
                      </span>
                      <span
                        className={`font-medium truncate max-w-52 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        ${predictivePriceData?.start}
                      </span>
                    </p>
                    <p className="flex text-xs">
                      <span className="text-gray500 font-normal">
                        Fuel:&nbsp;
                      </span>
                      <span
                        className={`font-medium truncate max-w-52 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        ${predictivePriceData?.fuel}
                      </span>
                    </p>
                    <p className="flex text-xs">
                      <span className="text-gray500 font-normal">
                        Mileage:&nbsp;
                      </span>
                      <span
                        className={`font-medium truncate max-w-52 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {predictivePriceData?.mileage}
                      </span>
                    </p>
                  </div>
                </div>
                <div className={`flex items-center`}>
                  <div className={`flex items-center gap-1.5 `}>
                    <span
                      className={`text:ext-grayLight900 text-2xl font-semibold ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      ${predictivePriceData?.total}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="chart">
              <Chart
                options={predictivePriceOptions}
                series={series}
                type="line"
                height={350}
                className={`${isLoading ? 'custom-loading graph-loading' : ''}`}
              />
            </div>
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default PredictivePriceChart;
