import {
  Check,
  ClockFastForward,
  Edit01,
  Lock02,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext } from 'react';
import { DATE_FORMAT, USER_ROLE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';

import BadgeCmp from '../../../../components/BadgeCmp';
import TooltipCmp from '../../../../components/TooltipCmp';
import {
  getShortName,
  onError,
  useRolePermission,
} from '../../../../utils/CommonFunctions';

interface IProps {
  data: any;
  keyValue: string;
  handleActionType: (action: any, mode?: any, team?: any) => () => void;
}

const MemberRow = ({ data, handleActionType }: IProps) => {
  const { currentUser } = useContext(AuthContext);
  const { hasPermissionV2 } = useRolePermission();

  const getUserAccountTypeColor = (accountType: string) => {
    if (accountType === USER_ROLE.ADMIN) {
      return 'primary';
    } else if (accountType === USER_ROLE.MANAGER) {
      return 'success';
    } else if (accountType === USER_ROLE.SALES) {
      return 'pink';
    } else if (accountType === USER_ROLE.OPERATIONS) {
      return 'lightblue';
    } else if (accountType === USER_ROLE.CLAIMS) {
      return 'error';
    }
  };

  return (
    <>
      {/* <tr
      key={keyValue}
      className="text-grayLight600 border-b border-utilityGray200  font-normal text-xs"
    > */}
      <td
        className="flex items-center px-5 py-4 whitespace-nowrap min-w-96 cursor-pointer"
        onClick={handleActionType('team_management', 'view_profile', data)}
      >
        {data.image ? (
          <img
            className="w-8 h-8 rounded-full"
            src={data.imageUrl + data.image}
            alt={data.firstName}
            title={data.firstName}
            onError={onError}
          />
        ) : (
          <div className="w-8 h-8 bg-primary rounded-full text-white flex items-center justify-center uppercase">
            {getShortName(`${data.firstName} ${data.lastName}`)}
          </div>
        )}
        <div className="pl-3">
          <h6 className="text-grayLight900 font-medium leading-tight">{`${data.firstName} ${data.lastName}`}</h6>
          <p className="text-grayLight600 font-normal leading-tight">{`${data.email}`}</p>
        </div>
      </td>
      <td className="px-5 py-4 min-w-40">
        <div className="flex">
          {data?.role ? (
            <BadgeCmp
              style="modern"
              type={getUserAccountTypeColor(data.role.roleName)}
            >
              {data.role ? data.role.displayName : '-'}
            </BadgeCmp>
          ) : (
            '-'
          )}
        </div>
      </td>
      <td className="px-5 py-4 min-w-8 ">
        <span>
          {data.lastLoginAt
            ? moment(data.lastLoginAt).format(DATE_FORMAT)
            : '-'}
        </span>
      </td>
      <td className="px-5 py-4 min-w-8">
        <div className="flex">
          {data.isVerified && data.userId ? (
            <BadgeCmp style="pill" type="success">
              <Check className="text-green500 mr-1 h-3 w-3" /> Active
            </BadgeCmp>
          ) : (
            <BadgeCmp style="pill" type="warning">
              <ClockFastForward className="mr-1 h-3 w-3 text-lg text-warning500" />{' '}
              Pending
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-32">
        <div className="items-center flex gap-4 justify-end">
          {hasPermissionV2('team_member_update') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Change Permission'}>
                <Lock02
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'access-control', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_delete') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Delete Member'}>
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'user-delete', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_update') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Edit Member'}>
                <Edit01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'user-update', data)}
                />
              </TooltipCmp>
            )}
        </div>
      </td>
    </>
  );
};

export default MemberRow;
