import {
  ChevronLeftDouble,
  Eye,
  EyeOff,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce, throttle, unionBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
// import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
// import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  deleteQuoteEmail,
  getIsActiveToken,
  getQuoteEmail,
  setEmailRead,
} from 'src/services/QuoteEmailService';
import { customFromNow, getShortName } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import MailDetailView from '../../QuotingHub/QuotingRequest/SmartQuote/MailDetailView';

import ConnectionModal from './ConnectionModal';
import NoEmailUI from './NoEmailUI';
import NotFoundUI from './NotFoundUI';
import MessageListCloseLoading from './Skeleton/MessageListCloseLoading';
import MessageListLoading from './Skeleton/MessageListLoading';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 10,
  isQuoteEmail: true,
};

const QuotingRequest = () => {
  const [params, setParams] = useState(initParams);
  const [isOpenQuoteRequest, setIsOpenQuoteRequest] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [totalEmails, setTotalEmails] = useState<any>(0);
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [isActive, setIsActive] = useState(true);
  // const [emailToDelete, setEmailToDelete] = useState<any>(null);
  // const [isDeleteLoading, setIsDeleteLoading] = useState<any>(false);
  const scrollContainerRef = useRef<any>(null);
  // const [scrollPosition, setScrollPosition] = useState(1);
  const [search, setSearch] = useState('');

  const getQuoteEmails = async (isDeleteId = null) => {
    try {
      let filter = ``;

      if (loadMore) {
        if (scrollContainerRef.current) {
          console.log('object');
          setTimeout(() => {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollHeight + 500;
          }, 200);
        }
      }

      if (isDeleteId === null) {
        setLoading(true);
      }

      if (params?.page) {
        filter += `?page=${params?.page}&limit=${params.limit}`;
      }

      if (params?.search) {
        filter += `&search=${params?.search}`;
      }

      if (params?.isQuoteEmail) {
        filter += `&isQuoteEmail=true`;
      }
      const quoteEmails = (await getQuoteEmail(filter)) as any;
      let emailFilterData = quoteEmails?.data?.filter(
        (v: any) => v?.isConvertedToQuote === 0
      );
      setLoading(false);
      setLoadMore(false);
      emailFilterData = emailFilterData?.map((email: any) => {
        const value = { ...email, emailBody: JSON.parse(email?.emailBody) };

        return value;
      });
      const deletedIndex = emailData.findIndex(
        (x: any) => x.id === isDeleteId || false
      );

      if (params?.page === 1) {
        setEmailData(emailFilterData);
      } else {
        if (isDeleteId) {
          setEmailData((prevItems: any) => {
            const combinedData = unionBy(prevItems, emailFilterData, 'id');
            const filteredData = combinedData.filter(
              (item: any) => item.id !== isDeleteId
            );

            return filteredData;
          });
        } else {
          setEmailData((prevItems: any) =>
            unionBy(prevItems, emailFilterData, 'id')
          );
        }
      }
      setTotalEmails(quoteEmails?.total || 0);

      if (!selectedEmail?.id) {
        setSelectedEmail(emailData?.[0] || emailFilterData?.[0]);
      } else if (isDeleteId) {
        console.log('🚀 ~ getQuoteEmails ~ isDeleteId:', isDeleteId);
        setTimeout(() => {
          const selectedEmailData =
            emailData?.[deletedIndex + 1] || emailData?.[deletedIndex - 1];
          console.log(
            '🚀 ~ setTimeout ~ selectedEmailData:',
            selectedEmailData
          );
          setSelectedEmail(selectedEmailData);
        }, 200);
      }
    } catch (e: any) {
      console.log(e);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  const makeEmailRead = async (messageId: string) => {
    try {
      (await setEmailRead({ messageId: messageId })) as any;
    } catch (e: any) {
      console.log(e);
    }
  };

  const fetchTokenStatus = async () => {
    try {
      setLoading(true);
      const response = await getIsActiveToken();
      setIsActive(response.data?.isActive);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching token status:', error);
      setIsActive(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenStatus();
  }, []);

  useEffect(() => {
    if (isActive) {
      getQuoteEmails();
    }
  }, [params, isActive]);

  const handleSelectedEmail = (emailDetails: any) => {
    setSelectedEmail(emailDetails);

    if (!emailDetails?.isRead) {
      makeEmailRead(emailDetails?.messageId);
      const emailDataCopy = emailData.map((email: any) => {
        if (email?.messageId === emailDetails?.messageId) {
          email.isRead = 1;
        }

        return email;
      });
      setEmailData(emailDataCopy);
    }
  };

  // const handleScroll = useCallback(() => {
  //   if (!scrollContainerRef.current) return;
  //   const { scrollTop, scrollHeight, clientHeight } =
  //     scrollContainerRef.current;

  //   if (
  //     !loadMore &&
  //     scrollTop + clientHeight >= scrollHeight &&
  //     totalEmails > emailData.length
  //   ) {
  //     // setScrollPosition(scrollTop + clientHeight);
  //     setTimeout(() => {
  //        - 52;
  //     }, 200);

  //     if (!loadMore) {
  //       setLoadMore(true);
  //     }
  //     setParams((old: any) => ({
  //       ...old,
  //       page: params?.page + 1,
  //     }));
  //   }
  // }, [loading, emailData]);

  const handleScroll = useCallback(
    throttle(() => {
      if (!scrollContainerRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;

      // Check if we've reached the bottom and need to load more
      if (
        !loadMore &&
        scrollTop + clientHeight >= scrollHeight - 1 &&
        totalEmails > emailData.length
      ) {
        setLoadMore(true);
        // Load more emails (simulated API call)
        setParams((old) => ({
          ...old,
          page: old.page + 1,
        }));
      }
    }, 200),
    [loadMore, emailData.length, totalEmails]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current as any;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  // useEffect(() => {
  //   if (scrollContainerRef.current && scrollPosition) {
  //     // scrollContainerRef.current.scrollTop = scrollPosition; // Restore the scroll position
  //   }
  // }, [emailData]);

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    searchDebounce(value);
  };
  useEffect(() => {
    setLoading(true);
  }, [search, params?.isQuoteEmail]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleEmail = () => {
    setParams((old: any) => ({
      ...old,
      page: 1,
      isQuoteEmail: !params?.isQuoteEmail,
    }));
  };

  // const handleDeleteEmail = (email: any) => {
  //   // setDeleteModalOpen(true);
  //   setEmailToDelete(email);
  // };

  // const handleDeleteModalClose = useCallback(
  //   (confirm: boolean) => async () => {
  //     setEmailToDelete(null);

  //     if (confirm && emailToDelete) {
  //       setIsDeleteLoading(true);

  //       deleteQuoteEmail({ emailId: emailToDelete.id })
  //         .then(() => {
  //           getQuoteEmails(emailToDelete.id);
  //           setIsDeleteLoading(false);
  //           WalToast.success('Email deleted successfully');
  //         })
  //         .catch((err) => {
  //           console.error('Error deleting email:', err);
  //           WalToast.error(err.message || 'Failed to delete email');
  //           setIsDeleteLoading(false);
  //         });
  //     }
  //   },
  //   [emailToDelete]
  // );
  const handleDeleteQuote = async (item: any) => {
    // const deletedIndex = emailData.findIndex(
    //   (x: any) => x.id === item.id || false
    // );
    // const deletedData = emailData?.filter((x: any) => x.id !== item.id);
    // setEmailData(deletedData);
    // setTimeout(() => {
    //   setSelectedEmail(deletedData?.[deletedIndex + 1]);
    // }, 300);
    deleteQuoteEmail({ emailId: item.id })
      .then(() => {
        getQuoteEmails(item.id);
        // setIsDeleteLoading(false);
        // WalToast.success('Email deleted successfully');
      })
      .catch((err) => {
        console.error('Error deleting email:', err);
        // WalToast.error(err.message || 'Failed to delete email');
        // setIsDeleteLoading(false);
      });
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quote Requests"
          desc="Track, manage and forecast your customers and orders."
        />
      }
    >
      {isActive ? (
        <>
          <div className="w-full relative bg-white rounded-xl border border-gray-200 flex flex-wrap overflow-hidden shadow">
            <div
              className={` w-full border-r border-gray-200 flex-col justify-start items-start flex transition-all duration-[0.5s] ${
                isOpen ? 'md:w-[25%]' : 'md:w-[74px] inbox-closed'
              }`}
            >
              <div className="w-full px-4 py-5 justify-between items-center gap-4 flex">
                <div className={`flex gap-2 items-center inbox-collapse`}>
                  <h5 className={`text-grayLight900 text-lg font-semibold`}>
                    Inbox
                  </h5>
                  <BadgeCmp
                    type="primary"
                    style="modern"
                    isHidePillDot
                    mainClassName="bg-utilityPurple50 border-utilityPurple200 "
                    badgeTextColor="!text-primary fon-medium"
                  >
                    {totalEmails}
                  </BadgeCmp>
                </div>
                <div className="flex items-center gap-3">
                  {emailData.length != 0 && (
                    <ButtonCmp
                      className={`${
                        params?.isQuoteEmail
                          ? 'btn_primary'
                          : 'btn_secondary_black'
                      } lg:px-[9px] px-2 inbox-collapse`}
                    >
                      {params?.isQuoteEmail ? (
                        <Eye className={`w-4 h-4`} onClick={toggleEmail} />
                      ) : (
                        <EyeOff className={`w-4 h-4`} onClick={toggleEmail} />
                      )}
                    </ButtonCmp>
                  )}
                  <ButtonCmp
                    className="btn_secondary_black lg:px-[9px] px-2"
                    icon={
                      <ChevronLeftDouble
                        className={`w-4 h-4 ${isOpen ? '' : 'rotate-180'}`}
                        onClick={toggleSidebar}
                      />
                    }
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>

              <InputText
                inputName="search"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg
                    onClick={toggleSidebar}
                    className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4"
                  />
                }
                value={search}
                isClearable
                inputType="text"
                parentClassName="w-full px-4 pb-3 inbox-search-collapse "
                onChangeFunc={handleFilter}
                inputWrapClass="!justify-start"
              />

              <div
                className="w-full h-[calc(100vh_-_257px)] min-h-[calc(100vh_-_257px)] max-h-[calc(100vh_-_257px)] overflow-auto custom-scrollbar-v2"
                ref={scrollContainerRef}
              >
                {(!loading || loadMore) &&
                  emailData?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="last:border-b-0 border-b border-utilityGray200"
                    >
                      {isOpen ? (
                        <>
                          <div
                            className={`p-4 pl-3 flex gap-1.5 items-center cursor-pointer  ${
                              item?.emailBody?.id ===
                              selectedEmail?.emailBody?.id
                                ? 'bg-utilityGray400'
                                : ''
                            }`}
                            key={index}
                            onClick={() => handleSelectedEmail(item)}
                          >
                            <p
                              className={` ${
                                item?.isRead
                                  ? 'w-2 h-2'
                                  : 'w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start'
                              }`}
                            ></p>
                            <div className="flex-1">
                              <h6
                                className={`text-sm textSecondary  ${
                                  item?.isRead ? 'font-medium' : 'font-bold'
                                }`}
                              >
                                {item?.emailBody?.from?.emailAddress?.name}
                              </h6>
                              <p
                                className={`text-xs leading-[18px] font-medium line-clamp-1 ${
                                  item?.isRead
                                    ? 'text-textSecondary'
                                    : 'text-gray500'
                                }`}
                              >
                                {`${item?.emailBody?.bodyPreview?.substring(
                                  0,
                                  40
                                )} ...`}
                              </p>
                            </div>
                            <div
                              className={`text-xs text-grayLight600 ${
                                item?.isRead ? 'font-normal' : 'font-bold'
                              }`}
                            >
                              {/* {moment(item?.emailBody?.receivedDateTime).fromNow(
                              true
                            )} */}
                              {customFromNow(item?.emailBody?.receivedDateTime)}
                            </div>
                            <ButtonCmp
                              className="btn_secondary_black lg:px-[9px] px-2"
                              icon={<XClose className="w-4 h-4" />}
                              onClick={() => handleDeleteQuote(item)}
                            >
                              <></>
                            </ButtonCmp>
                          </div>
                        </>
                      ) : (
                        <div className="p-4 cursor-pointer">
                          <div
                            key={index}
                            className={`w-9 h-9 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative ${
                              item?.emailBody?.id ===
                              selectedEmail?.emailBody?.id
                                ? 'bg-utilityGray400'
                                : ''
                            }`}
                            onClick={() => handleSelectedEmail(item)}
                          >
                            {getShortName(
                              `${item?.emailBody?.from?.emailAddress?.name}`
                            )}
                            <p
                              className={` ${
                                item?.isRead
                                  ? ''
                                  : 'w-2 h-2 bg-primary500 rounded-full mt-[5px] self-start -right-[5px] absolute'
                              }`}
                            ></p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                {(loading || loadMore) && isOpen && (
                  <MessageListLoading isLoadMore={loadMore} />
                )}
                {(loading || loadMore) && !isOpen && (
                  <MessageListCloseLoading isLoadMore={loadMore} />
                )}
              </div>
            </div>
            <div className="md:w-[75%] flex-1 w-full flex flex-col">
              {/* <NotFoundUI title="No Quote Requests Found" desc="Please check your connection or connect to your inbox." /> */}
              {emailData.length === 0 ? (
                <NoEmailUI />
              ) : (
                <MailDetailView emailDetails={selectedEmail} />
              )}
            </div>
          </div>
          {isOpenQuoteRequest && (
            <ConnectionModal handleClose={() => setIsOpenQuoteRequest(false)} />
          )}

          {/* {emailToDelete && (
            <DeleteModal
              moduleName="Email"
              handleClose={() => null}
              isDeleteLoading={isDeleteLoading}
            />
          )} */}
        </>
      ) : (
        <NotFoundUI />
      )}
    </PageSectionLayout>
  );
};

export default QuotingRequest;
