import {
  FilterLines,
  Plus,
  SearchLg,
  XClose,
  // XClose,
} from '@untitled-ui/icons-react/build/cjs';
import _, { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TeamIcon1 from 'src/assets/img/Avatar7.png';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import { CURRENCY, PERMISSION } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { getPricingRate } from 'src/services/CommonService';
import { deleteQuote, listQuote } from 'src/services/QuoteService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetterAndDash,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import CompareQuote from './CompareQuote';
import FilterModal from './FilterModal';
import QuotingDashboardRow from './QuotingDashboardRow';
import QuotingDashboardToQuoteRow from './QuotingDashboardToQuoteRow';
import SmartQuoteListLoading from './SmartQuoteListLoading';
import ToQuoteListLoading from './ToQuoteListLoading';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: window?.MODE !== 'production' ? 'customerName' : 'createdAt',
  page: 1,
  limit: 50,
  team: 'all',
  member: 'all',
  startDate: null,
  endDate: null,
  filter: {
    status: window?.MODE !== 'production' ? 'QuoteRequest' : 'All',
    classification: null,
  },
};

const initCounter = {
  All: 0,
  Opened: 0,
  Active: 0,
  Won: 0,
  Lost: 0,
  Expired: 0,
  Archived: 0,
};

const initAction = {
  mode: null,
  quote: false,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const quoteTypeArray: any = [
  {
    value: 'QuoteRequest',
    name: 'To-Quote',
  },
  {
    value: 'All',
    name: 'Quoted',
  },
];

const QuotingDashboard = () => {
  const navigate = useNavigate();
  const uiState = useSelector((state: any) => state.MobileView);
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const [quotes, setQuotes] = useState([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const [counter, setCounter] = useState(initCounter);
  const [search, setSearch] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.search || ''
      : '';
  });
  const [action, setAction] = useState(initAction);
  const [singleQuote, setSingleQuote] = useState<any>({});
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [isDisplayCompareQuote, setIsDisplayCompareQuote] = useState(false);
  const { currency, setCurrency } = useContext(BasicContext);
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);

  const [rates, setRates] = useState<any>({});
  const [filterCount, setFilterCount] = useState<number>(0);
  // const [currentFilterType, setCurrentFilterType] = useState('to-quote');
  const [currentFilterType, setCurrentFilterType] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    // return storedParams && isValidJSON(storedParams)
    //   ? JSON.parse(storedParams)?.filter?.status || ''
    //   : (window.MODE !== 'production' && 'QuoteRequest') || 'All';
    if (window.MODE !== 'production') {
      return storedParams && isValidJSON(storedParams)
        ? JSON.parse(storedParams)?.filter?.status !== 'QuoteRequest'
          ? 'All'
          : 'QuoteRequest'
        : 'QuoteRequest';
    } else {
      return storedParams && isValidJSON(storedParams)
        ? JSON.parse(storedParams)?.filter?.status || ''
        : 'All';
    }
  });
  const statuses = useMemo(
    () => [
      // {
      //   id: 1,
      //   value: 'All',
      //   name: 'All',
      //   counter: counter.All,
      // },
      {
        id: 2,
        value: 'Opened',
        name: 'Open',
        counter: counter.Opened,
      },
      {
        id: 3,
        value: 'Active',
        name: 'Quoted',
        counter: counter.Active,
      },
      {
        id: 4,
        value: 'Won',
        name: 'Won',
        counter: counter.Won,
      },
      {
        id: 5,
        value: 'Lost',
        name: 'Lost',
        counter: counter.Lost,
      },
      {
        id: 6,
        value: 'Expired',
        name: 'Expired',
        counter: counter.Expired,
      },
      {
        id: 6,
        value: 'Archived',
        name: 'Archived',
        counter: counter.Archived,
      },
    ],
    [counter]
  );
  const statusArr = getSelectBoxOptions(statuses, 'value', 'name');

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => console.error('Pricing rates error'));
  };

  useEffect(() => {
    console.log('param changed : ', params);
  }, [params]);

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    console.log('currentFilterType', currentFilterType);
  }, [currentFilterType]);

  useEffect(() => {
    localStorage.removeItem('isModifyQuoteFromQuote');
    localStorage.removeItem('isPickupDateChanged');
    localStorage.removeItem('modifyQuoteCode');

    localStorage.setItem(TABLE_IDS.QUOTE_LIST, JSON.stringify(params));
    setIsLoading(true);
    // listQuote(params)
    //     .then((response: any) => {
    //         if (response.data) {
    //             setQuotes(response.data?.quotes);
    //             setCounter(response.data?.count[0]);
    //         }
    //         setTotals(response.total);
    //     })
    //     .finally(() => {
    //         setIsLoading(false);
    //         setIsRefresh(false);
    //     })
    //     .catch(console.log);
    const quoteApiController = new AbortController();
    const quoteApiSignal = quoteApiController.signal;

    const fetchQuotes = async () => {
      setIsLoading(false);
      setIsRefresh(false);

      try {
        setIsLoading(true);
        setQuotes([]);
        const response: any = await listQuote(params, quoteApiSignal);

        if (response?.data) {
          setQuotes(response.data?.quotes);

          if (response.data?.count?.length) {
            setCounter(response.data?.count[0]);
          }
          setIsLoading(false);
          setIsRefresh(false);
        }
        setTotals(response.total);
      } catch (e: any) {
        //   setError(error.message);
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        setIsRefresh(false);
      }
    };

    fetchQuotes();

    return () => {
      quoteApiController.abort();
    };
  }, [isRefresh, params, currentFilterType]);

  useEffect(() => {
    getTeamList({ onlyUsers: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
        // setParams((old) => ({ ...old, ...{ team: "all", member: "all" } }));
        // setSelectedTeam("all");
        // setSelectedUser("all");
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setUserList(updatedListAllMembers);
      // setSelectedUser("all");
      // setParams((old) => ({ ...old, page: 1, member: "all" }));
    }
  }, [params.team, teamListOptions]);

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setSingleQuote({});

      if (status) {
        deleteQuote(singleQuote.id)
          .then(() => {
            setIsRefresh(true);
            WalToast.success('Quote deleted successfully');
          })
          .catch(() => WalToast.error('Quote not deleted', ''));
      }
    },
    [singleQuote]
  );

  const headCellsQuoted = useMemo(
    () => [
      {
        id: 'fullName',
        name: 'User',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'customerName',
        name: 'Customers',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'shipperAddress1',
        name: 'Addresses',
        sortable: true,
        visible: true,
        rowClassName: 'text-xs ',
      },
      {
        id: 'createdAt',
        name: 'Creation Date',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'pickupDate',
        name: 'Pickup Date',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalUnits',
        name: 'Total Units',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      // {
      //     id: "quotePrice",
      //     name: "Quote price",
      //     sortable: true,
      //     visible: true,
      // },
      {
        id: 'status',
        name: 'Status',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'action',
        visible: hasRoleV2('admin') ? true : false,
        rowClassName: '',
      },
    ],
    []
  );

  const headCellsToQuote = useMemo(
    () => [
      {
        id: 'fullName',
        name: 'Staff member',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'urgency',
        name: 'Urgency',
        sortable: true,
        visible: true,
        rowClassName: '!pl-3 !pr-1',
      },
      {
        id: 'classification',
        name: 'Type',
        sortable: true,
        visible: true,
      },
      {
        id: 'customerName',
        name: 'Customers',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'shipperAddress1',
        name: 'Address',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
        sortable: true,
        visible: true,
        rowClassName: '!pl-3 !pr-1',
      },
      {
        id: 'totalUnits',
        name: 'Total Units',
        sortable: true,
        visible: true,
        rowClassName: '!pl-3 !pr-1',
      },
      {
        id: 'totalWeight',
        name: 'Total weight',
        sortable: true,
        visible: true,
        rowClassName: '!px-3',
      },
      {
        id: 'pickupDate',
        name: 'Pickup Date',
        sortable: true,
        visible: true,
        rowClassName: '!px-3',
      },
      {
        id: 'receivedDateTime',
        name: 'Timer',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'action',
        visible: hasRoleV2('admin') ? true : false,
        rowClassName: '',
      },
    ],
    []
  );

  const onRowClick = (id: number) => () => {
    navigate(`${PATH.MY_QUOTE}/${id}`);
  };

  const onRowClickToQuoted = (id: number) => () => {
    navigate(`${PATH.ADDRESS_DETAILS}/${id}`);
  };

  const onNewQuote = () => {
    navigate(`${PATH.ADDRESS_DETAILS}`);
  };

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const onClearFilter = () => {
    if (uiState.isMobile || uiState.currentBreakpoint === 'SM') {
      setParams(initParams);
    } else {
      setParams((old: any) => ({
        ...old,
        page: 1,
        startDate: null,
        endDate: null,
        team: 'all',
        member: 'all',
        classification: null,
      }));
    }

    setFilterCount(0);
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Quoting Dashboard"
            desc="Create, view and manage your customer quotes."
            mainContainerClassName="flex-nowrap !flex-row"
            leftClassName="!w-auto"
            rightClassName="!w-auto"
            rightSideContent={
              <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
                <TabButton
                  tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                  parentClassName="w-full currency-tab quoting-currency"
                  childrenClassName="test"
                  activeClassName="text-grayLight900 bg-utilityGray100"
                  className=""
                  tabArray={filterArr}
                  isTooltip={true}
                  handleOnClick={(e: any) => {
                    setCurrency(e.target.dataset.value);
                  }}
                  isActive={currency}
                />
              </div>
            }
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">
                  {params?.filter?.status !== '' &&
                  params?.filter?.status !== 'All' &&
                  params?.filter?.status !== 'QuoteRequest'
                    ? capitalizeFirstLetterAndDash(params?.filter?.status ?? '')
                    : hasRoleV2('admin') || hasRoleV2('manager')
                    ? 'All'
                    : 'My'}{' '}
                  Quotes
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals} {totals > 1 ? 'Quotes' : 'Quote'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Create, view and manage your customer quotes.
              </p>
            </div>
            {hasPermissionV2(PERMISSION.CREATE_SMART_QUOTE) && (
              <Link
                to={PATH.ADDRESS_DETAILS}
                className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_primary flex sm:flex-none flex-1 "
              >
                <div className="flex items-center gap-2">
                  <Plus className="text-white w-4 h-4" />
                  Add New Quote
                </div>
              </Link>
            )}
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left flex">
              {window.MODE !== 'production' && (
                <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                  <TabButton
                    className="min-w-[130px]"
                    tabArray={quoteTypeArray}
                    isActive={currentFilterType}
                    activeClassName="bg-primary text-white"
                    // parentClassName="w-[2.5vh] "
                    // tabParentClassName="w-[6.5vh] justify-end"
                    handleOnClick={(e: any) => {
                      setParams((old: any) => ({
                        ...old,
                        ...{
                          filter: { status: e.target.dataset.value },
                          page: 1,
                          search: '',
                          sortType: 'desc',
                          sortField: 'customerName',
                        },
                      }));
                      setCurrentFilterType(e?.target?.dataset?.value);
                    }}
                  />
                </div>
              )}

              <InputText
                inputName="searchQuote"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
            </div>
            {/* {!(currentFilterType == 'QuoteRequest') && ( */}
            <div className="table-statustbox mdm:block hidden">
              <SelectBox
                name="status"
                placeholder="All"
                parentClassName=" block w-full"
                labelClassName="form_label"
                isDisabled={currentFilterType === 'QuoteRequest'}
                isClearable={params?.filter?.status !== 'All' ? true : false}
                value={
                  params?.filter?.status
                    ? statusArr?.filter(
                        (val: any) => params?.filter?.status === val?.value
                      )
                    : 'All'
                }
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    page: 1,
                    filter: { status: event?.value ?? 'All' },
                  }));
                }}
                options={statusArr}
              />
            </div>
            {/* )} */}
            {/* Moved below filter to modal */}
            {/* <div className="table-selectbox xl:block hidden">
              <SelectBox
                name="teamListGroup"
                id="teamListGroup"
                className="form_control"
                size="sm"
                placeholder="All Teams"
                noOptionMessage="No Teams Found"
                isClearable={true}
                isSearchable={true}
                options={teamListOptions}
                onChangeFunc={(event: any) =>
                  setParams((old: any) => ({
                    ...old,
                    ...{ team: event?.value ?? 'all', page: 1 },
                  }))
                }
                value={
                  params.team
                    ? teamListOptions.filter(
                        (val: any) => params.team === val.value
                      )
                    : null
                }
              />
            </div>
            <div className="table-selectbox xl:block hidden xls:order-none order-5">
              <SelectBox
                name="userListGroup"
                id="userListGroup"
                size="sm"
                placeholder="All Users"
                className="form_control"
                isClearable={true}
                isSearchable={true}
                options={userList}
                noOptionMessage="No Users Found"
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    ...{ member: event?.value ?? 'all', page: 1 },
                  }));
                }}
                value={userList.filter(
                  (user: any) => params.member === user.value
                )}
              />
            </div> */}
            <ButtonCmp
              type="submit"
              className={`btn_secondary_black table-filter-btn ${
                filterCount > 0 ? 'border-borderSecondary' : ''
              }`}
              onClick={(e) => {
                if (
                  e?.target?.id !== 'closeBtn' &&
                  e?.target?.parentElement?.id !== 'closeBtn'
                )
                  setIsDisplayFilterModal(true);
              }}
              icon={<FilterLines className=" w-4 h-4 " />}
            >
              Filter
              {filterCount > 0 ? (
                <>
                  <p className="rounded-full border bg-primary border-primary text-white w-4 h-4 text-[11px] flex items-center justify-center">
                    {filterCount}
                  </p>
                  <XClose
                    className="w-4 h-4 text-primary700"
                    id="closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      onClearFilter();
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </ButtonCmp>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>

          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              {currentFilterType !== 'QuoteRequest' ? (
                <TableCmp
                  headCells={headCellsQuoted}
                  tableDataArr={quotes}
                  TableLoaderRowCmp={ToQuoteListLoading}
                  TableRowCmp={QuotingDashboardRow}
                  tableRowCmpProps={{
                    setAction: setAction,
                    setSingleQuote: setSingleQuote,
                    onRowClick: onRowClick,
                  }}
                  params={params}
                  setParams={setParams}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={10}
                  isTableRowClickable={true}
                />
              ) : (
                <TableCmp
                  headCells={headCellsToQuote}
                  tableDataArr={quotes}
                  TableLoaderRowCmp={SmartQuoteListLoading}
                  TableRowCmp={QuotingDashboardToQuoteRow}
                  tableRowCmpProps={{
                    setAction: setAction,
                    setSingleQuote: setSingleQuote,
                    onRowClick: onRowClickToQuoted,
                  }}
                  params={params}
                  setParams={setParams}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={10}
                  isTableRowClickable={true}
                />
              )}
            </div>
            {!isLoading &&
              params?.filter?.status === 'Archived' &&
              quotes.length <= 0 &&
              search && (
                <NotFoundUI
                  handleActionType={onNewQuote}
                  // buttonText="Add New Quote"
                  title={'No Archived Quotes found'}
                  desc={
                    'No archived quotes were found in the system based on the given search.'
                  }
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  testId="wal-QuotingDashboard-dataNotFoundText"
                />
              )}
            {!isLoading && params?.filter?.status === 'Archived' && !search && (
              <NotFoundUI
                handleActionType={onNewQuote}
                // buttonText="Add New Quote"
                title={'Search Archived Quotes'}
                desc={
                  'Archived quotes will appear here after performing a search using the above search bar.'
                }
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                testId="wal-QuotingDashboard-dataNotFoundText"
              />
            )}
            {quotes.length <= 0 &&
              !isLoading &&
              params?.filter?.status !== 'Archived' && (
                <NotFoundUI
                  handleActionType={onNewQuote}
                  buttonText="Add New Quote"
                  title="No Quotes found"
                  desc="There are no quotes found. You can create a new quote to get started."
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  testId="wal-QuotingDashboard-dataNotFoundText"
                />
              )}
          </div>
          {!!quotes.length && (
            <div className="w-full bg-white rounded-b-xl">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={totals}
                currentPage={params.page}
                handlePagination={handlePagination}
              />
            </div>
          )}
        </div>
      </PageSectionLayout>

      {isDisplayCompareQuote && (
        <CompareQuote handleClose={() => setIsDisplayCompareQuote(false)} />
      )}
      {isDisplayFilterModal && (
        <FilterModal
          handleClose={() => setIsDisplayFilterModal(false)}
          params={params}
          teamListOptions={teamListOptions}
          setFilterCount={setFilterCount}
          setParams={setParams}
          userList={userList}
          statusArr={statusArr}
        />
      )}

      {action.mode === 'delete' && (
        <DeleteModal moduleName="Quote" handleClose={handleModalClose} />
      )}
    </>
  );
};

export default QuotingDashboard;
