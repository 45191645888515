import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const OrderLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-52 w-52 max-w-52">
      <div className="flex items-center">
        <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white custom-loading">
          J D
        </div>
        <div className="pl-2 text-xs font-medium max-w-32">
          <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1 custom-loading">
            <span className="inline-block max-w-32 truncate">
              XXXXX XXXXXXXX
            </span>
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
            # WAL-TEST-123
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 max-w-52 min-w-52 w-52 ">
      <BadgeCmp style="modern" type="error" mainClassName="custom-loading">
        New
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-52 min-w-52 w-52">
      <TooltipCmp childrenClassName="!block truncate max-w-48 custom-loading">
        Unicon Concrete Specialties Ltd
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 text-xs max-w-64 w-64 min-w-64 ">
      <TooltipCmp type="dark" parentClassName="">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate max-w-[180px]">
            Etobicoke, ON, Canada
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp type="dark" parentClassName="max-w-90">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate max-w-[180px]">
            {' '}
            Still water, OK 74078, USA
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-44 max-w-44">
      <span className="custom-loading">
        Jul 8<sup>th</sup> - 2024, 09:52 AM
      </span>
    </td>
    <td className="px-5 py-4 font-normal min-w-36 ">
      <span className="custom-loading">$ 1234.22</span>
    </td>
    <td className="px-5 py-4 font-normal min-w-36 ">
      <span className="custom-loading">$ 134542.22</span>
    </td>
    <td className="px-5 py-4 font-normal min-w-[120px] max-w-[120px] w-[120px]">
      <div className="flex gap-1 custom-loading">
        10.10%
        <TooltipCmp>
          <InfoCircle className="h-3.5 w-3.5 text-primary" />
        </TooltipCmp>
      </div>
    </td>
    <td className="px-5 py-4 font-semibold min-w-40 max-w-40 w-40">
      <span className="custom-loading">$ 95.26 CAD</span>
    </td>
  </>
);

export default OrderLoaderRow;
