import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useMemo, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TableCmp from 'src/components/TableCmp';

import { LoadInsuranceData } from './LoadInsurance.interface';
import LoadInsuranceLoading from './LoadInsuranceLoading';
import LoadInsuranceRow from './LoadInsuranceRow';
import RequestLoadInsurance from './RequestLoadInsurance';

const initAction = {
  mode: null,
  carrier: false,
};

const LoadInsurance = () => {
  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);

  const headCells = useMemo(
    () => [
      {
        id: 'orderId',
        name: 'Wal#',
        sortable: true,
        visible: true,
      },
      {
        id: 'commodity',
        name: 'Commodity',
        sortable: true,
        visible: true,
      },
      {
        id: 'commercial',
        name: 'Commercial Value',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        name: '#',
        visible: true,
      },
    ],
    []
  );

  const handleActionType =
    (actionKey: any, mode: any = null, data: any = {}) =>
    () => {
      console.log(actionKey, ' ', mode, ' data :>> ', data);
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);
  console.log('handleDraweClose :>> ', handleDrawerClose, '', action);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Load Insurance"
          desc="All Load Insurance."
          mainContainerClassName="md:flex-nowrap"
        />
      }
    >
      <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Load Insurance</h5>
            </div>
          </div>
          <ButtonCmp
            type="button"
            className="btn_primary flex smd:w-fit w-full"
            onClick={handleActionType('Load Insurance', 'request')}
            iconSide="left"
            icon={<Plus className="text-white w-4 h-4" />}
          >
            Request Load Insurance
          </ButtonCmp>
        </div>
        <div
          data-test-id="userTest"
          className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
        >
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={headCells}
              tableDataArr={LoadInsuranceData}
              TableLoaderRowCmp={LoadInsuranceLoading}
              TableRowCmp={LoadInsuranceRow}
              tableRowCmpProps={{
                handleActionType: handleActionType,
              }}
              numberOfSkeletonRows={10}
              isTableRowClickable={true}
            />
          </div>
        </div>
        {action.mode === 'request' ? (
          <RequestLoadInsurance handleClose={handleDrawerClose} />
        ) : (
          ''
        )}
      </div>
    </PageSectionLayout>
  );
};
export default LoadInsurance;
