/* eslint-disable max-lines-per-function */
import {
  SearchLg,
  XClose,
  Copy06,
  ArrowDown,
  ArrowUp,
  Copy05,
  Edit05,
  Share03,
  CheckSquareBroken,
  DownloadCloud02,
  InfoCircle,
  Plus,
  Trash01,
  Check,
  ChevronUp,
  DotsVertical,
  RefreshCcw05,
  HelpCircle,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy, debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPricingRate } from 'src/services/CommonService';

import profile1 from '../../../../assets/img/Avatar1.png';
import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';
import ButtonCmp from '../../../../components/ButtonCmp';
import CheckBox from '../../../../components/CheckBox';
import InputText from '../../../../components/InputText/InputText';
import NotFoundUI from '../../../../components/NotFoundUI';
import PageSectionLayout from '../../../../components/PageSectionLayout';
import Header from '../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import { CURRENCY, STATUS } from '../../../../constants/common';
import { PATH } from '../../../../constants/path';
import { ROUTES } from '../../../../constants/routes';
import { AuthContext } from '../../../../context/AuthContext';
import { BasicContext } from '../../../../context/BasicContext';
import { listCarrier } from '../../../../services/CarrierService';
import {
  createRate,
  getQuote,
  modifyQuote,
  spotQuoteRequest,
} from '../../../../services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getLabelByValue,
  onError,
  downloadPdf,
  getShortName,
  fetchJsFromCDN,
} from '../../../../utils/CommonFunctions';
import WalToast from '../../../../utils/WalToast';
import PDF from '../Quote/PDF';

import AddQuoteDetail from './AddQuoteDetail';
import Pricing from './Pricing';
import PricingDetails from './PricingDetails';
import ProceedWithOrderFlow from './ProceedWithOrderFlow';
import QuoteDetail from './QuoteDetail';
import QuotedLoading from './QuotedLoading';
import QuotedRow from './QuotedRow';
import ReqQuotedRow from './ReqQuotedRow';
import ReqQuoteLoading from './ReqQuoteLoading';
import ServiceDetail from './ServiceDetail';
import SpotQuoteConfirmation from './SpotQuoteConfirmation';
import WithQuoteLoading from './WithQuoteLoading';
import WithRateQuote from './WithRateQuote';

const initAction = {
  mode: '',
  pricing: false,
  pdf: false,
  closedReason: true,
  informationModel: false,
  quoteDetail: false,
  addQuoteDetail: false,
  orderSuccessModel: false,
  pricingDetails: false,
  spotQuoteConfirmation: false,
};

enum QuoteType {
  QUOTED = 'quoted',
  NON_QUOTED = 'non_quoted',
  WITH_RATE = 'with _rate',
}

// const [isShowMemberList, setIsShowMemberList] = useState(false);
const MyQuote = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [services, setServices] = useState<any[]>([]);
  const [rates, setRates] = useState<any>({});
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });
  // const [sortBy] = useState("pricing");
  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [quoteType, setQuoteType] = useState<QuoteType>(QuoteType.WITH_RATE);
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [quotedList, setQuotedList] = useState<any[]>([]);
  const [nonQuotedList, setNonQuotedList] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(false);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any>([]);
  const { currency, setCurrency } = useContext(BasicContext);
  const [search, setSearch] = useState('');
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const sortDataByIsQuoted = (data: any) =>
    data.sort((a: any, b: any) => {
      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    setCarrierRates(allCarrierRates);
    setNonQuotedList(invalidCarrierRates);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(quotedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
        setQuotedList(
          sortDataByIsQuoted(
            orderBy(quotedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
        // setIsFinish(true);
        // setIsLoading(false);
      }, 400);
    }
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const handleAllColors = (addressDetailsObj: any) => {
    const { status } = addressDetailsObj;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => console.error('Pricing rates error'));
  };

  useEffect(() => {
    getRate();
  }, []);

  const handleSearchFilter = () => {
    const searchTermLower = search.toLowerCase();
    const matchedVal = carrierRates.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTermLower) ||
        (item.provider &&
          item.provider.toLowerCase().includes(searchTermLower)) ||
        (item.tags &&
          item.tags.some((tag: any) =>
            tag.toLowerCase().includes(searchTermLower)
          )) ||
        (item.carrierQuoteId &&
          item.carrierQuoteId.toLowerCase().includes(searchTermLower))
    );

    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (matchedVal?.length) {
      matchedVal.forEach((carrierRate: any) => {
        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }

    setNonQuotedList(invalidCarrierRates);
    setWithRateList(
      orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
    );
    setQuotedList(
      orderBy(quotedCarrierRates, sortData.sortField, sortData.sortType)
    );
  };

  useEffect(() => {
    handleSearchFilter();
  }, [search]);

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;
              const { additionalService } = responseData;
              setIsCustomerDeleted(
                responseData?.addressDetails.isCustomerDeleted
              );
              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);
              }

              if (responseData?.dimensions) {
                setDimensions(responseData.dimensions);
                let unit = 0;
                let weight = 0;
                responseData.dimensions.forEach((val: any) => {
                  unit += val.handlingUnitNo || 0;
                  weight += val.totalWeight;
                });
                setTotalWeight(weight);
                setTotalUnit(unit);
              }

              if (additionalService) {
                setServices(additionalService);
              }
              setIsLoading(false);

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                sortCarrierRates(responseData?.carrierRates);
                setIsLoading(false);
              } else {
                setIsGetRate(true);
              }
              handleAllColors(responseData?.addressDetails);

              if (
                responseData?.addressDetails?.status === STATUS.ACTIVE ||
                responseData?.addressDetails?.status === STATUS.WON
              ) {
                setQuoteType(QuoteType.QUOTED);
              }
            } else {
              navigate(ROUTES.HOME);
            }
          })
          .finally(() => setIsRefresh(false))
          .catch(console.error);
      }
    } else {
      navigate(ROUTES.HOME);
    }
  }, [isRefresh]);

  const connectSocket = () => {
    setIsFinish(false);

    fetchJsFromCDN(
      'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
      ['io']
    ).then(([io]: any) => {
      io.sails.url = window.SERVER_URL;
      io.socket.on('connect', function socketConnected() {
        io.socket.get(
          `/subscribe/${currentUser.id}`,
          function (data: any, jwr: any) {
            if (jwr.error) {
              return;
            }
            setIsSocketConnected(true);
          }
        );
      });

      io.socket.on('user', function (data: any) {
        if (data.type === 'NEW_RATE') {
          if (data?.data && data?.data?.quoteId === parseInt(id)) {
            carrierRates = [...carrierRates, data.data];
            sortCarrierRates(carrierRates);
            setIsLoading(false);
          }
        } else if (data?.type === 'FINISH_LOADING') {
          carrierRateFetchStatus[data.carrier] = true;
          setCarrierRateFetchStatus((old: any) => ({
            ...old,
            [data.carrier]: true,
          }));
          // setIsFinish(true);
        }
      });
    });
  };

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      if (carrierRate) {
        let newCarrierRate: any = {};

        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate.services.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
        setSelectedCarrierRates([newCarrierRate]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
  }, []);

  const handleSubmit = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            connectSocket();
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
          }

          setCarrierList(carriers || []);
        })
        .finally(() => {
          // setIsLoading(false);
          // setIsRefresh(false);
        })
        .catch(console.log);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (carrierRates?.length) {
      // if (quoteType === QuoteType.WITH_RATE || quoteType === QuoteType.QUOTED) {
      //   setWithRateList([]);
      //   setQuotedList([]);
      //   setIsFinish(false);
      //   // setIsLoading(true);
      // }
      sortCarrierRates(carrierRates, true);
    }
  }, [sortData, addressDetails]);

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    modifyQuote({ quoteId: quoteStatusId })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatusId;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', addressDetails?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          if (isCustomerDeleted) {
            navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          } else {
            navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  const updateSelectedCarrierRates = (allNonQuotedList: any) => {
    const filteredList = allNonQuotedList.filter(
      (carrierRate: any) =>
        !(carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail)
    );

    return filteredList;
  };

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: false,
        rowClassName: '',
      },
      // {
      //     id: "TransitTime",
      //     name: "Transit time",
      //     sortable: true,
      //     visible: true,
      // },

      {
        id: 'ProjectedDelivery',
        name: 'Projected Delivery',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'AdditionalServices',
        name: 'Additional Services',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'action',
        name: (
          <div className="">
            <CheckBox
              checked={
                (allChecked ||
                  updateSelectedCarrierRates(nonQuotedList).length ===
                    selectedCarrierRates.length) &&
                selectedCarrierRates.length != 0
              }
              onChangeFunc={(e: any) => {
                setAllChecked(e.target.checked);

                if (e.target.checked) {
                  const filteredList =
                    updateSelectedCarrierRates(nonQuotedList);
                  setSelectedCarrierRates(filteredList);
                } else {
                  setSelectedCarrierRates([]);
                }
              }}
              parentClassName=""
            />
          </div>
        ),
        sortable: false,
        rowClassName: 'w-[5%]',
      },
    ],
    [allChecked, selectedCarrierRates]
  );

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [quoteType]);

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const WithRatesHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: 'min-w-64 w-[350px]',
      },
      {
        id: 'tags',
        name: 'Delivery',
        sortable: true,
        rowClassName: 'max-w-48',
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: 'w-48',
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
        sortable: true,
        rowClassName: 'w-44',
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        rowClassName: 'w-44',
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
        rowClassName: 'w-36',
      },

      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
        rowClassName: 'w-36',
      },
      {
        id: 'finalCharge',
        name: 'Total Price',
        sortable: true,
        rowClassName: 'w-48',
      },
      {
        id: 'action',
        rowClassName: 'text-sm w-3.5',
      },
    ],
    []
  );

  const QuotedHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
        sortable: true,
        visible: true,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
        visible: true,
      },

      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
        visible: true,
      },
      {
        id: `${currency === CURRENCY.CAD ? 'totalCostCAD' : 'totalCostUSD'}`,
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Margin',
        sortable: true,
        visible: true,
      },

      {
        id: 'finalCharge',
        name: 'Total Price',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const tabOptionsArr = [
    {
      value: QuoteType.QUOTED,
      label: 'Quoted',
    },
    {
      value: QuoteType.WITH_RATE,
      label: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      label: 'Request Spot Quote',
    },
  ];

  const requestSpotQuote = (
    sentEmailFrom?: any,
    isDoNotAskChecked: boolean = false
  ) => {
    const quoteIds = selectedCarrierRates.map((item: any) => item.id);
    const data: any = {
      doNotAskSpotQuote: isDoNotAskChecked,
      sentEmailFrom: sentEmailFrom,
      ids: quoteIds,
    };
    setSubmitLoading(true);
    spotQuoteRequest(data)
      .then(() => {
        setSubmitLoading(false);
        setSelectedCarrierRates([]);
        setAllChecked(false);
        setAction(initAction);

        if (isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
        WalToast.success('Spot quote request sent successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(() => {
        setSelectedCarrierRates([]);
        setSubmitLoading(false);
        setAction(initAction);
      });
  };

  const handleModalClose = useCallback(
    (
        status: boolean,
        sentEmailFrom?: any,
        isDoNotAskChecked: boolean = false
      ) =>
      () => {
        if (!status) {
          setAction(initAction);
        }

        if (status) {
          if (action.informationModel) {
            setAction(initAction);
            setSelectedCarrierRates([]);
            setIsRefresh(true);
          } else if (action.spotQuoteConfirmation) {
            requestSpotQuote(isDoNotAskChecked, sentEmailFrom);
          }
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
        } else if (action.informationModel) {
          setSelectedCarrierRates([]);
        } else if (action.addQuoteDetail || action.informationModel) {
          setSelectedCarrierRates([]);
        }
      },
    [action]
  );

  const handleQuoteClick = (carrierRate: any) => {
    if (
      (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
      quoteType === QuoteType.NON_QUOTED
    ) {
      return;
    }

    let newCarrierRate: any = {};

    if (currency === CURRENCY.USD) {
      newCarrierRate = { ...carrierRate };
      newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
      newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
      newCarrierRate.margin = carrierRate.marginUSD;
      newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

      if (newCarrierRate.services.length) {
        newCarrierRate.services = newCarrierRate.services.map((item: any) => ({
          ...item,
          value: item.valueUSD,
        }));
      }
    } else {
      newCarrierRate = { ...carrierRate };
    }

    if (
      selectedCarrierRates.filter((rate: any) => rate.id === carrierRate.id)
        .length
    ) {
      newCarrierRate = {};
      setSelectedCarrierRates((old: any) =>
        old.filter((item: any) => item.id !== carrierRate.id)
      );
    } else {
      setSelectedCarrierRates((old: any) => [...old, newCarrierRate]);
    }
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  // const getServicesDisplay = (val: number, serve: any) => {
  //     let isServiceDis = val === 1 ? true : false;
  //     let additionalCharges = currency === CURRENCY.CAD ? serve.additionalCharges.toFixed(2) : serve.additionalChargesUSD.toFixed(2);
  //     if (serve.services.length && additionalCharges > 0) {
  //         // setIsServiceDisplay(isServiceDis);
  //         // setServiceCarrierId(serve.id);
  //         setSelectedCarrierServices(serve);
  //     }
  // };

  useEffect(() => {
    if (carrierRates?.length) {
      setIsGetRate(false);
    }
  }, [carrierRates]);

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      carrierList.forEach((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));

        let formData = quote;
        formData.carrierId = element.id;
        createRate(formData)
          .then()
          .finally()
          .catch((error: any) => {
            console.error(error);
            WalToast.error(
              error?.response?.data?.message ||
                'Carrier rates have not been created',
              ''
            );
          });
      });
    }
  }, [isSocketConnected]);

  useEffect(() => {
    let allFetched: boolean = Object.keys(carrierRateFetchStatus).length !== 0;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setIsFinish(true);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  // const handleSubmit = () => {
  //     const cancelParams = {
  //         cancelReason: closedReasonText === "Other" ? otherReasonText : closedReasonText,
  //     };
  //     cancelQuote(cancelParams, id)
  //         .then((response: any) => {
  //             if (response && response.data) {
  //                 handleClose(false)();
  //                 WalToast.success("Quote has been closed successfully", "");
  //             }
  //         })
  //         .finally(() => setIsRefresh(true))
  //         .catch(() => WalToast.error("Quote has not been close", ""));
  // };

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      <TabButton
        tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
        parentClassName="w-full currency-tab quoting-currency"
        childrenClassName="test"
        activeClassName="text-grayLight900 bg-utilityGray100"
        className=""
        isTooltip={true}
        tabArray={filterArr}
        handleOnClick={(e: any) => {
          setCurrency(e.target.dataset.value);
        }}
        isActive={currency}
      />
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      <div className="flex flex-wrap  justify-end lg:gap-4 gap-3 mdm:flex-none md:flex-1 sms:flex-none flex-1">
        {!isLoading && (
          <ButtonCmp
            type="submit"
            className="btn_primary w-full xs:!text-xs !text-[11px] sm:!px-3 !px-2"
            onClick={modifyQuoteStatus(addressDetails.id)}
          >
            {addressDetails.status === STATUS.OPENED
              ? 'Modify Quote'
              : 'Duplicate Quote'}
          </ButtonCmp>
        )}
      </div>
      {addressDetails.status === STATUS.ACTIVE ||
      addressDetails.status === STATUS.WON ||
      addressDetails.status === STATUS.EXPIRED ||
      addressDetails.status === STATUS.LOST ? (
        <>
          <ButtonCmp
            type="button"
            className="btn_secondary_black mdm:flex-none md:flex-1 sms:flex-none flex-1 xs:!text-xs !text-[11px] sm:!px-3 !px-2"
            onClick={handleActionType('pdf')}
          >
            View Generated PDFs
          </ButtonCmp>
        </>
      ) : (
        !isLoading && <></>
        // addressDetails.status === STATUS.OPENED && (
        //     <>
        //         <div className="font-semibold inline-block ml-3 px-3 text-center align-middle cursor-pointer" onClick={handleSubmit}>
        //             <HiOutlineX size={40} />
        //         </div>
        //     </>
        // )
      )}

      <ButtonCmp
        type="button"
        className="btn_secondary_black !p-2 flex-none"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp>
    </div>
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: #${addressDetails.code ?? ''}`}
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              isLoading ? (
                <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                  <span className="rounded-full p-1 bg-warning500"></span>
                  <span className="text-xs">Opened </span>
                </div>
              ) : (
                <BadgeCmp
                  style="modern"
                  type={
                    addressDetails.status === 'Won'
                      ? 'success'
                      : addressDetails.status === 'Active'
                      ? 'lightblue'
                      : addressDetails.status === 'Opened'
                      ? 'primary'
                      : addressDetails.status === 'Expired' ||
                        addressDetails.status === 'Lost'
                      ? 'error'
                      : 'success'
                  }
                >
                  {addressDetails.status === 'Opened'
                    ? 'Open'
                    : addressDetails.status === 'Active'
                    ? 'Quoted'
                    : addressDetails.status}
                </BadgeCmp>
              )
            }
            desc="View carrier rates and create a quote for your shipment."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
              <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
                <tr className="border-b border-utilityGray200 [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50">
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>User</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Customer</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium">
                    <div className="flex items-center">
                      <span>Addresses</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Pickup Date</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Total Weight</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>№ of HU</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Freight Class</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>
                        Dimensions (
                        {dimensions?.length > 0
                          ? getLabelByValue(
                              dimensions[0]?.lengthMeasurement
                            )?.toLowerCase()
                          : 'lbs'}
                        )
                      </span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium min-w-32 w-32 max-w-32">
                    <div className="flex items-center">
                      <span></span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {!isLoading ? (
                  <tr
                    className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]"
                    onClick={handleActionType('quoteDetail', '')}
                  >
                    <td className="px-5 py-4 min-w-52">
                      <div className="flex items-center">
                        <img
                          src={
                            addressDetails.image
                              ? addressDetails.imageUrl + addressDetails.image
                              : profile1
                          }
                          className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                        />
                        <div className="pl-3 max-w-32">
                          <h6 className="text-grayLight900 font-medium leading-tight truncate">
                            {addressDetails.firstName} {addressDetails.lastName}
                          </h6>
                          <p className="text-grayLight600 font-normal leading-tight truncate">
                            #{addressDetails.code ?? ''}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-4 min-w-40 w-40 max-w-40">
                      <TooltipCmp
                        message={
                          addressDetails?.customerName?.length > 20
                            ? addressDetails?.customerName
                            : null
                        }
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="max-w-40 truncate">
                          {addressDetails?.customerName}
                        </p>
                      </TooltipCmp>
                    </td>
                    <td className="px-5 py-4 text-xs ">
                      <TooltipCmp
                        message={addressDetails.shipperFullAddress}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="flex ">
                          <span className="text-gray500 font-normal">
                            From:&nbsp;
                          </span>
                          <span className="font-medium truncate max-w-52">
                            {addressDetails.shipperFullAddress}
                          </span>
                        </p>
                      </TooltipCmp>
                      <TooltipCmp
                        message={addressDetails.consigneeFullAddress}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="flex">
                          <span className="text-gray500 font-normal">
                            To:&nbsp;
                          </span>
                          <span className="font-medium truncate max-w-52">
                            {addressDetails.consigneeFullAddress}
                          </span>
                        </p>
                      </TooltipCmp>
                    </td>

                    <td className="px-5 py-4 ">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: addressDetails.pickupDate
                            ? getDateWithSuffixFormat(
                                moment
                                  .utc(addressDetails.pickupDate)
                                  .format(`MMM Do - YYYY`)
                              )
                            : '-',
                        }}
                      />
                      {/* {moment(addressDetails.pickupDate).format("DD MMMM, YYYY")} */}
                    </td>
                    <td className="px-5 py-4 ">
                      {totalWeight}{' '}
                      {dimensions.length > 0 &&
                        getLabelByValue(
                          dimensions[0].weightMeasurement
                        )?.toLowerCase()}
                    </td>
                    <td className="px-5 py-4 ">{totalUnit}</td>
                    <td className="px-5 py-4 ">
                      {dimensions.length > 0
                        ? dimensions?.[0]?.freightClass
                        : 0}
                    </td>
                    <td className="px-5 py-4 ">
                      {dimensions?.length || 0}{' '}
                      {dimensions?.length &&
                        ((dimensions?.length as number) > 1
                          ? 'Pieces'
                          : 'Piece')}
                    </td>
                    <td className="px-5 py-4 min-w-32 w-32 max-w-32">
                      <a
                        className="text-primary underline font-semibold cursor-pointer"
                        onClick={handleActionType('quoteDetail', '')}
                      >
                        View Details
                      </a>
                    </td>
                  </tr>
                ) : (
                  <tr className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]">
                    <td className="px-5 py-4 min-w-52">
                      <div className="flex items-center">
                        <img className="w-8 h-8 custom-loading rounded-full image-loading" />
                        <div className="pl-3 max-w-52">
                          <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                            Use Name Use Name{' '}
                          </h6>
                          <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                            #SQ-L-100145
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-4 min-w-52">
                      <p className="max-w-80 truncate custom-loading">
                        Evolution Wheel{' '}
                      </p>
                    </td>
                    <td className="px-5 py-4 min-w-40 w-40 max-w-40">
                      <p className="flex mb-1 custom-loading">
                        <span className="text-gray500 font-normal">
                          From:&nbsp;
                        </span>
                        <span className="font-medium truncate max-w-52">
                          {addressDetails.shipperFullAddress}
                        </span>
                      </p>
                      <p className="flex custom-loading">
                        <span className="text-gray500 font-normal">
                          To:&nbsp;
                        </span>
                        <span className="font-medium truncate max-w-52">
                          {addressDetails.consigneeFullAddress}
                        </span>
                      </p>
                    </td>

                    <td className="px-5 py-4 ">
                      <span className="custom-loading">May 14th, 2024 </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading">11</span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading"> 500 lbs </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading"> 11 </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading">1 Dimensions</span>
                    </td>
                    <td className="px-5 py-4 min-w-32 w-32 max-w-32">
                      <span className="custom-loading">View Details</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden !hidden">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
              <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
                <tr className="border-b border-utilityGray200 [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50">
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>User</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Customer</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium">
                    <div className="flex items-center">
                      <span>Addresses</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Pickup Date</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Total Weight</span>
                    </div>
                  </th>
                  <th className="px-5 py-3 font-medium ">
                    <div className="flex items-center">
                      <span>Total № of HU</span>
                    </div>
                  </th>

                  <th className="px-5 py-3 font-medium min-w-14 w-14 max-w-14">
                    <div className="flex items-center">
                      <span></span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {!isLoading ? (
                  <tr className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]">
                    <td className="px-5 py-4 flex items-center min-w-64 w-64 max-w-64">
                      <img
                        src={
                          addressDetails.image
                            ? addressDetails.imageUrl + addressDetails.image
                            : profile1
                        }
                        className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                      />
                      <div className="pl-3 max-w-32">
                        <h6 className="text-grayLight900 font-medium leading-tight truncate">
                          {addressDetails.firstName} {addressDetails.lastName}
                        </h6>
                        <p className="text-grayLight600 font-normal leading-tight truncate">
                          #{addressDetails.code ?? ''}
                        </p>
                      </div>
                    </td>
                    <td className="px-5 py-4 min-w-60 w-60 max-w-60">
                      <TooltipCmp
                        message={
                          addressDetails?.customerName?.length > 30
                            ? addressDetails?.customerName
                            : null
                        }
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="max-w-44 truncate">
                          {addressDetails?.customerName}
                        </p>
                      </TooltipCmp>
                    </td>
                    <td className="px-5 py-4 text-xs min-w-80 w-80 max-w-80">
                      <TooltipCmp
                        message={addressDetails.shipperFullAddress}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="flex mb-1">
                          <span className="text-gray500 font-normal">
                            From:&nbsp;
                          </span>
                          <span className="font-medium truncate max-w-52">
                            {addressDetails.shipperFullAddress}
                          </span>
                        </p>
                      </TooltipCmp>
                      <TooltipCmp
                        message={addressDetails.consigneeFullAddress}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p className="flex">
                          <span className="text-gray500 font-normal">
                            To:&nbsp;
                          </span>
                          <span className="font-medium truncate max-w-52">
                            {addressDetails.consigneeFullAddress}
                          </span>
                        </p>
                      </TooltipCmp>
                    </td>

                    <td className="px-5 py-4 ">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: addressDetails.pickupDate
                            ? getDateWithSuffixFormat(
                                moment
                                  .utc(addressDetails.pickupDate)
                                  .format(`MMM Do - YYYY`)
                              )
                            : '-',
                        }}
                      />
                    </td>
                    <td className="px-5 py-4 ">
                      {totalWeight}{' '}
                      {dimensions.length > 0 &&
                        getLabelByValue(
                          dimensions[0].weightMeasurement
                        )?.toLowerCase()}
                    </td>
                    <td className="px-5 py-4 ">{totalUnit}</td>

                    <td className="px-5 py-4 min-w-14 w-14 max-w-14">
                      <ChevronUp className="w-4 h-4" />
                    </td>
                  </tr>
                ) : (
                  <tr className="text-grayLight600 font-normal text-xs [&>:last-child]:w-[73px] [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all  duration-[0.5s]">
                    <td className="px-5 py-4 flex items-center min-w-64 w-64 max-w-64">
                      <img className="w-8 h-8 custom-loading rounded-full image-loading" />
                      <div className="pl-3 max-w-52">
                        <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                          Use Name Use Name{' '}
                        </h6>
                        <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                          #SQ-L-100145
                        </p>
                      </div>
                    </td>
                    <td className="px-5 py-4 min-w-60 w-60 max-w-60">
                      <p className="max-w-44 truncate custom-loading">
                        Evolution Wheel{' '}
                      </p>
                    </td>
                    <td className="px-5 py-4 text-xs  min-w-80 w-80 max-w-80">
                      <p className="flex mb-1 custom-loading">
                        <span className="text-gray500 font-normal">
                          From:&nbsp;
                        </span>
                        <span className="font-medium truncate max-w-52">
                          {addressDetails.shipperFullAddress}
                        </span>
                      </p>
                      <p className="flex custom-loading">
                        <span className="text-gray500 font-normal">
                          To:&nbsp;
                        </span>
                        <span className="font-medium truncate max-w-52">
                          {addressDetails.consigneeFullAddress}
                        </span>
                      </p>
                    </td>

                    <td className="px-5 py-4 ">
                      <span className="custom-loading">May 14th, 2024 </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading">11</span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading"> 500 lbs </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading"> 11 </span>
                    </td>
                    <td className="px-5 py-4 ">
                      <span className="custom-loading">1 Dimensions</span>
                    </td>
                    <td className="px-5 py-4 min-w-14 w-14 max-w-14">
                      <ChevronUp className="w-4 h-4" />
                    </td>
                  </tr>
                )}
              </tbody>

              <tbody className="text-xs">
                <tr
                  className={`border-t border-utilityGray200 text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]`}
                >
                  <td className="px-5 py-4 min-w-64 w-64 max-w-64">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Handling unit
                    </h6>
                    <p className="text-utilityGray800 font-semibold">Pallet</p>
                  </td>

                  <td className="px-5 py-4 text-xs min-w-60 w-60 max-w-60 ">
                    <h6 className="text-gray500 font-normal mb-0.5">№ of HU</h6>
                    <p className="text-utilityGray800 font-semibold">01</p>
                  </td>

                  <td className="px-5 py-4  min-w-80 w-80 max-w-80">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Weight per unit
                    </h6>
                    <p className="text-utilityGray800 font-semibold">11 lbs</p>
                  </td>

                  <td className="px-5 py-4 ">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Freight Class
                    </h6>
                    <p className="text-utilityGray800 font-semibold">50</p>
                  </td>
                  <td className="px-5 py-4 " colSpan={3}>
                    <ul className="flex gap-6">
                      <li className="w-1/3 ">
                        <h6 className="text-gray500 font-normal mb-0.5">
                          Length (in)
                        </h6>
                        <div className="text-utilityGray800 font-semibold flex justify-between">
                          <p>48</p> <span>x</span>
                        </div>
                      </li>
                      <li className="w-1/3 ">
                        <h6 className="text-gray500 font-normal mb-0.5">
                          Width (in)
                        </h6>
                        <div className="text-utilityGray800 font-semibold flex justify-between">
                          <p>48</p> <span>x</span>
                        </div>
                      </li>
                      <li className="w-1/3 ">
                        <h6 className="text-gray500 font-normal mb-0.5">
                          Height (in)
                        </h6>
                        <div className="text-utilityGray800 font-semibold flex justify-between">
                          <p>48</p>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr
                  className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]"
                  onClick={handleActionType('quoteDetail', '')}
                >
                  <td className="px-5 pb-4 " colSpan={100}>
                    <div className="border-t border-utilityGray200 pt-4">
                      <h6 className="text-gray500 font-normal mb-1.5">
                        Additional Services
                      </h6>
                      <ul className="flex flex-wrap gap-y-1 gap-x-10">
                        <li className="flex gap-1 w-[245px]">
                          <Check className="w-3.5 h-3.5 text-primary mt-[1px]" />
                          <p className="text-utilityGray800 text-xs font-medium text-wrap whitespace-normal">
                            Tailgate Pickup
                          </p>
                        </li>
                        <li className="flex gap-1 w-[245px]">
                          <Check className="w-3.5 h-3.5 text-primary mt-[1px]" />
                          <p className="text-utilityGray800 text-xs font-medium text-wrap whitespace-normal">
                            After hours / weekend / holiday pickup
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col !hidden">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3 border-b border-utilityGray200">
            <div
              className={`w-auto bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {addressDetails.status !== STATUS.OPENED && (
                <div
                  className={`py-2 sm:px-4 px-3 cursor-pointer rounded-md text-xs mdm:flex-none flex-1 ${
                    quoteType === QuoteType.QUOTED ? 'bg-gray50' : ''
                  }`}
                  onClick={() => setQuoteType(QuoteType.QUOTED)}
                >
                  Active Quotes
                </div>
              )}
            </div>

            <div className="gap-3 flex flex-wrap whitespace-nowrap">
              <ButtonCmp
                type="submit"
                disabled={selectedCarrierRates.length <= -1}
                className="btn_secondary_black mdm:flex-none flex-1 xls:min-w-[180px] mdm:min-w-[auto] md:min-w-[calc(50%_-_8px)] sm:min-w-[auto] min-w-[calc(50%_-_8px)]"
                onClick={handleActionType('pdf')}
              >
                View PDF History
              </ButtonCmp>
              <div className="relative mdm:flex-none flex-1 mdm:min-w-[180px]  md:min-w-[calc(50%_-_8px)] sm:min-w-[180px] min-w-[180px]">
                <ButtonCmp type="submit" className="btn_primary w-full ">
                  Generate PDF
                </ButtonCmp>

                <div
                  className={` rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-normal w-full my-1.5 z-[1] absolute`}
                >
                  <h5 className="px-1.5 pb-[5px] font-semibold ">Language</h5>
                  <div className="flex bg-gray50 px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      English
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="flex  px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      French
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <h5 className="px-1.5 pb-[5px] pt-2 font-semibold ">
                    Display
                  </h5>
                  <div className="flex px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      Display Carrier Names
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="flex px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      Display Est. Delivery & Transit Time
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="mx-1.5">
                    <ButtonCmp className="btn_primary w-full mt-1 ">
                      Generate
                    </ButtonCmp>
                  </div>
                </div>
              </div>
              <ButtonCmp
                type="button"
                className="btn_secondary_black mdm:flex-none flex-1 xls:min-w-[180px] mdm:min-w-[auto] sm:w-auto md:min-w-[calc(50%_-_8px)] sm:min-w-[auto] min-w-[calc(50%_-_8px)] "
                icon={<Plus className="w-4 h-4" />}
              >
                Add Quick Quote
              </ButtonCmp>
              <ButtonCmp
                type="button"
                className="btn_secondary_black lg:!px-[9px] !px-2 "
              >
                <Trash01 className="w-4 h-4" />
              </ButtonCmp>
            </div>
          </div>

          {quoteType === QuoteType.QUOTED && (
            <div className="w-full">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
                  <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200 ">
                    <tr className="border-b border-utilityGray200 [&>:last-child]:w-3.5 [&>:nth-child(1)]:w-60">
                      {QuotedHeadCells.map(
                        (head, index) =>
                          head.id !== 'tags' &&
                          head.visible && (
                            <th
                              scope="col"
                              className="px-5 py-3 font-medium cursor-pointer"
                              key={index}
                              onClick={() => {
                                if (head.sortable) {
                                  if (head.sortable) {
                                    setSortData({
                                      ...{
                                        sortField: head.id,
                                        sortType:
                                          sortData.sortType === 'asc' &&
                                          sortData.sortField === head.id
                                            ? 'desc'
                                            : 'asc',
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              <div className="flex items-center">
                                <span> {head.name}</span>
                                {head.sortable && (
                                  <>
                                    {sortData.sortType === 'asc' &&
                                    sortData.sortField === head.id ? (
                                      <ArrowDown className="w-4 h-4 cursor-pointer flex-none ms-1" />
                                    ) : (
                                      <ArrowUp className="w-4 h-4 cursor-pointer flex-none ms-1" />
                                    )}
                                  </>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    {quotedList.length > 0 && (
                      <>
                        {quotedList.map(
                          (carrierRate: any, index: number) =>
                            carrierRate.carrierQuoteId && (
                              <tr
                                key={index}
                                className="text-grayLight600 border-b border-utilityGray200 hover:bg-gray-100 bg-transparent transition-all  duration-[0.5s]  font-normal text-xs  [&>:nth-child(1)]:w-60 cursor-pointer"
                              >
                                <td className="px-5 py-4 min-w-64 w-64">
                                  <div className=" flex items-center">
                                    {carrierRate.image ? (
                                      <img
                                        src={`${carrierRate.imageUrl}${carrierRate.image}`}
                                        className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
                                        alt={carrierRate.name}
                                        title={carrierRate.name}
                                        onError={onError}
                                      />
                                    ) : (
                                      <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                                        {getShortName(`${carrierRate.name}`)}
                                      </div>
                                    )}
                                    <div className="pl-3 max-w-48">
                                      {(
                                        carrierRate.name +
                                        (carrierRate.banyanPostFix || '')
                                      ).length > 30 ? (
                                        <TooltipCmp
                                          message={`${carrierRate.name} ${
                                            carrierRate.banyanPostFix || ''
                                          }`}
                                        >
                                          <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">
                                            {`${carrierRate.name} ${
                                              carrierRate.banyanPostFix || ''
                                            }`}
                                          </h6>
                                        </TooltipCmp>
                                      ) : (
                                        <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">
                                          {`${carrierRate.name} ${
                                            carrierRate.banyanPostFix || ''
                                          }`}
                                        </h6>
                                      )}

                                      <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
                                        {carrierRate.carrierQuoteId.length >
                                        30 ? (
                                          <TooltipCmp
                                            message={`#${carrierRate.carrierQuoteId}`}
                                            childrenClassName="max-w-36 truncate"
                                          >
                                            <span className="max-w-36 truncate inline-block align-middle  ">
                                              #{carrierRate.carrierQuoteId}
                                            </span>
                                          </TooltipCmp>
                                        ) : (
                                          <span className="inline align-middle truncate max-w-36">
                                            #{carrierRate.carrierQuoteId}
                                          </span>
                                        )}
                                        &nbsp;
                                        <div className="flex gap-1">
                                          <TooltipCmp message="Copy">
                                            <Copy06
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                copyDebounce(
                                                  carrierRate.carrierQuoteId
                                                );
                                              }}
                                              className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                                            />
                                          </TooltipCmp>

                                          {carrierRate.quoteUrl && (
                                            <TooltipCmp message="Redirect">
                                              <a
                                                href={carrierRate.quoteUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(event) =>
                                                  event.stopPropagation()
                                                }
                                              >
                                                <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                                              </a>
                                            </TooltipCmp>
                                          )}
                                        </div>
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td className="px-5 py-4">
                                  {(carrierRate.isFreightcom ||
                                    carrierRate.isFreightcom === 1) && (
                                    <div className="flex items-center">
                                      <img
                                        className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                                        src={FreightcomIcon}
                                        alt="FC"
                                      />
                                      <div className="pl-3 font-medium">
                                        Freightcom
                                      </div>
                                    </div>
                                  )}
                                  {(carrierRate.isBanyan ||
                                    carrierRate.isBanyan === 1) && (
                                    <div className="flex items-center">
                                      <img
                                        className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                                        src={BanyanIcon}
                                        alt="BANYAN"
                                      />
                                      <div className="pl-3 font-medium">
                                        Banyan
                                      </div>
                                    </div>
                                  )}
                                  {!carrierRate.isBanyan &&
                                    !carrierRate.isFreightcom && (
                                      <div className="flex items-center">
                                        <img
                                          className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
                                          src={
                                            carrierRate.image
                                              ? `${carrierRate.imageUrl}${carrierRate.image}`
                                              : defaultImage
                                          }
                                          alt="CR"
                                          onError={onError}
                                        />
                                        <div className="pl-3 font-medium">
                                          Direct
                                        </div>
                                      </div>
                                    )}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.transitTime > 0
                                    ? `Est. ${carrierRate.transitTime} days`
                                    : carrierRate.transitTime === 0
                                    ? ' Est. Today'
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.transitTime > 0 ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          carrierRate.projectedDeliveryDate
                                            ? getDateWithSuffixFormat(
                                                moment
                                                  .utc(
                                                    carrierRate.projectedDeliveryDate
                                                  )
                                                  .format(`MMM Do - YYYY`)
                                              )
                                            : '-',
                                      }}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td className="px-5 py-4">
                                  <span>
                                    {carrierRate.totalCharge === 0
                                      ? '-'
                                      : currency === CURRENCY.CAD
                                      ? `$${getFormattedNumber(
                                          carrierRate.totalCharge
                                        )}`
                                      : `$${getFormattedNumber(
                                          carrierRate.totalChargeUSD
                                        )}`}
                                  </span>
                                </td>
                                <td className="px-5 py-4">
                                  <div className="flex items-center gap-1">
                                    {carrierRate.additionalCharges > 0 ? (
                                      <ActionTooltip
                                        openOnHover={true}
                                        tooltipPosition="center"
                                        parentClassName="w-max"
                                        isOpenChildTopPosOnTop={true}
                                        type="dark"
                                        message={
                                          <ServiceDetail
                                            carrierServices={carrierRate}
                                          />
                                        }
                                      >
                                        {carrierRate.additionalCharges === 0
                                          ? '$0.00'
                                          : currency === CURRENCY.CAD
                                          ? `$${getFormattedNumber(
                                              carrierRate.additionalCharges
                                            )}`
                                          : `$${getFormattedNumber(
                                              carrierRate.additionalChargesUSD
                                            )}`}
                                      </ActionTooltip>
                                    ) : carrierRate.additionalCharges === 0 ? (
                                      '$0.00'
                                    ) : currency === CURRENCY.CAD ? (
                                      `$${getFormattedNumber(
                                        carrierRate.additionalCharges
                                      )}`
                                    ) : (
                                      `$${getFormattedNumber(
                                        carrierRate.additionalChargesUSD
                                      )}`
                                    )}
                                    {carrierRate.services.some(
                                      (form: any) => !form.isQuoted
                                    ) ? (
                                      <TooltipCmp message="Carrier did not provide a rate for this additional service, for this additional service. Please validate feasibility and custom pricing.">
                                        <InfoCircle className="text-primary w-3.5 h-3.5" />
                                      </TooltipCmp>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.carrierQuoteId
                                    ? currency === CURRENCY.CAD
                                      ? `$${getFormattedNumber(
                                          carrierRate.totalCostCAD
                                        )}`
                                      : `$${getFormattedNumber(
                                          carrierRate.totalCostUSD
                                        )}`
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.margin === 0
                                    ? '$0.00'
                                    : currency === CURRENCY.CAD
                                    ? `$${getFormattedNumber(
                                        carrierRate.margin
                                      )}`
                                    : `$${getFormattedNumber(
                                        carrierRate.marginUSD
                                      )}`}
                                </td>

                                <td className="px-5 py-4 text-sm">
                                  <span className="text-textSecondary font-bold">
                                    {carrierRate.carrierQuoteId
                                      ? currency === CURRENCY.CAD
                                        ? `$${getFormattedNumber(
                                            carrierRate.finalCharge
                                          )} CAD`
                                        : `$${getFormattedNumber(
                                            carrierRate.finalChargeUSD
                                          )} USD`
                                      : '-'}
                                  </span>
                                </td>

                                <td className="px-5 py-4">
                                  {addressDetails.status === STATUS.ACTIVE ? (
                                    carrierRate.carrierQuoteId && (
                                      <div className="flex gap-3">
                                        {carrierRate.poq &&
                                          carrierRate.poqUrl && (
                                            <TooltipCmp message="Download POQ">
                                              <DownloadCloud02
                                                className="w-4 h-4"
                                                onClick={() =>
                                                  downloadPdf(
                                                    `${carrierRate.poqUrl}${carrierRate.poq}`,
                                                    `POQ-${carrierRate.poq}`
                                                  )
                                                }
                                              ></DownloadCloud02>
                                            </TooltipCmp>
                                          )}
                                        <TooltipCmp message="Modify Pricing">
                                          <Edit05
                                            className="w-4 h-4"
                                            onClick={handleActionType(
                                              'pricing',
                                              'update',
                                              carrierRate
                                            )}
                                          ></Edit05>
                                        </TooltipCmp>
                                        <TooltipCmp message="Proceed to Book Order">
                                          <CheckSquareBroken
                                            className="w-4 h-4 text-fgSuccessPrimary"
                                            onClick={handleActionType(
                                              'informationModel',
                                              '',
                                              carrierRate
                                            )}
                                          />
                                        </TooltipCmp>
                                      </div>
                                    )
                                  ) : (
                                    <span className="px-3">
                                      <a
                                        className="text-primary underline font-semibold cursor-pointer"
                                        onClick={handleActionType(
                                          'pricingDetails',
                                          '',
                                          carrierRate
                                        )}
                                      >
                                        View Details
                                      </a>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      </>
                    )}
                    {(!isFinish || isLoading) && (
                      <>
                        {[...Array(8)].map((_, index) => (
                          <tr
                            key={index}
                            className="text-grayLight600 border-b border-utilityGray200 hover:bg-gray-100 bg-transparent transition-all  duration-[0.5s]  font-normal text-xs  [&>:nth-child(1)]:w-60"
                          >
                            <td className="px-5 py-4 min-w-64">
                              <div className=" flex items-center ">
                                <img
                                  className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] image-loading"
                                  src={defaultImage}
                                  alt="IMAGE"
                                  title="IMAGE"
                                />

                                <div className="pl-3 max-w-52">
                                  <h6 className="text-grayLight900 font-medium leading-tight custom-loading truncate">
                                    *****************
                                  </h6>
                                  <p className="text-grayLight600 font-normal leading-tight custom-loading">
                                    #11111
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-4">
                              <div className="custom-loading px-2 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center gap-1 inline-flex">
                                <div className="p-1 bg-green500 rounded-full" />
                                <div className="text-center text-textSecondary text-[11px] font-medium leading-[18px]">
                                  #######
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-4">
                              <div className="flex items-center custom-loading">
                                <img
                                  className="w-4 h-4 relative rounded-full  border-[0.75px] border-black/[0.08]"
                                  src={FreightcomIcon}
                                  alt="FC"
                                />

                                <div className="pl-3 font-medium">NAME</div>
                              </div>
                            </td>
                            <td className="px-5 py-4 ">
                              <span className="custom-loading">
                                {' '}
                                {`Est. 5 days`}{' '}
                              </span>
                            </td>
                            <td className="px-5 py-4">
                              <span className="custom-loading">
                                {moment().format('MMM. Do - YYYY')}{' '}
                              </span>
                            </td>
                            <td className="px-5 py-4">
                              <span className="custom-loading">100000</span>
                            </td>
                            <td className="px-5 py-4">
                              <span className="custom-loading">100000</span>
                            </td>
                            <td className="px-5 py-4">
                              {' '}
                              <span className="text-textSecondary font-bold custom-loading">
                                {' '}
                                {'2000000000'}
                              </span>
                            </td>
                            <td className="px-5 py-4">
                              <span className="px-3">
                                <CheckBox
                                  className="custom-loading"
                                  onChangeFunc={() => setIsChecked(!isChecked)}
                                  checked={isChecked}
                                />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {quotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Active Quotes"
                  desc=""
                  containerClassName=" !my-0"
                  contentClassName="min-h-[130px] !h-auto before:bg-[length:400px_400px]  lg:px-12 px-10"
                  iconClassName="hidden"
                />
              )}
            </div>
          )}
        </div>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1 overflow-hidden">
          <div className="table-bottom-header table-tab-wrap">
            <div className="table-header-bottom-left">
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {addressDetails.status !== STATUS.OPENED && (
                  <div
                    className={`py-2 sm:px-4 px-3 cursor-pointer rounded-l-md text-xs mdm:flex-none flex-1 ${
                      quoteType === QuoteType.QUOTED ? 'bg-gray50' : ''
                    }`}
                    onClick={() => setQuoteType(QuoteType.QUOTED)}
                  >
                    Quoted
                  </div>
                )}
                <div
                  className={`py-2 sm:px-4 px-3 cursor-pointer text-xs mdm:flex-none flex-1 ${
                    quoteType === QuoteType.WITH_RATE ? 'bg-gray50' : ''
                  } ${
                    addressDetails.status !== STATUS.OPENED
                      ? 'border-l border-borderPrimary'
                      : 'rounded-l-md'
                  } ${window.MODE === 'production' ? 'rounded-r-md' : ''}`}
                  onClick={() => setQuoteType(QuoteType.WITH_RATE)}
                >
                  Direct Carrier Rates
                </div>

                {window.MODE !== 'production' && (
                  <div
                    className={`py-2 sm:px-4 px-3 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                      quoteType === QuoteType.NON_QUOTED ? 'bg-gray50' : ''
                    }`}
                    onClick={() => setQuoteType(QuoteType.NON_QUOTED)}
                  >
                    Request Spot Quote
                  </div>
                )}
              </div>
            </div>
            <InputText
              inputName="searchQuote"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              isClearable
              onChangeFunc={(e: any) => {
                setSearch(e.target.value);
              }}
              parentClassName="table-searchInput"
            />

            <SelectBox
              name="role"
              placeholder="Carrier Rates"
              parentClassName="sm:hidden block w-full"
              labelClassName="form_label"
              value={tabOptionsArr.find((val: any) => val.value === quoteType)}
              onChangeFunc={(event: any) => setQuoteType(event.value)}
              options={tabOptionsArr}
            />
            {quoteType === QuoteType.WITH_RATE && (
              <ButtonCmp
                type="submit"
                disabled={selectedCarrierRates.length <= 0}
                className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                onClick={handleActionType('pricing', 'create')}
              >
                Select Rates
              </ButtonCmp>
            )}
            {quoteType === QuoteType.NON_QUOTED && (
              <ButtonCmp
                type="submit"
                disabled={selectedCarrierRates.length <= 0}
                className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                onClick={() =>
                  handleActionType('spotQuoteConfirmation', 'view')()
                }
              >
                Request Spot
              </ButtonCmp>
            )}
          </div>

          {quoteType === QuoteType.NON_QUOTED && (
            <div className="h-full lg:min-h-[122px] w-full  border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={ReqQuoteHeadCells}
                  tableDataArr={nonQuotedList}
                  TableLoaderRowCmp={ReqQuoteLoading}
                  TableRowCmp={ReqQuotedRow}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    currentUser: currentUser,
                    requestSpotQuote: requestSpotQuote,
                    selectedCarrierRates: selectedCarrierRates,
                    allChecked: allChecked,
                    setAllChecked: setAllChecked,
                  }}
                  isTableDataLoading={isLoading || !isFinish}
                  numberOfSkeletonRows={8}
                  isTableRowClickable={true}
                  onRowClick={handleQuoteClick}
                />
              </div>
              {nonQuotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          )}

          {quoteType === QuoteType.WITH_RATE && (
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={withRateList}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={WithRateQuote}
                  params={sortData}
                  setParams={setSortData}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    selectedCarrierRates: selectedCarrierRates,
                  }}
                  onRowClick={handleQuoteClick}
                  isTableDataLoading={!isFinish || isLoading}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                />
              </div>
              {withRateList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          )}

          {quoteType === QuoteType.QUOTED && (
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={QuotedHeadCells}
                  tableDataArr={quotedList}
                  TableLoaderRowCmp={QuotedLoading}
                  TableRowCmp={QuotedRow}
                  params={sortData}
                  setParams={setSortData}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    selectedCarrierRates: selectedCarrierRates,
                  }}
                  // onRowClick={handleQuoteClick}
                  isTableDataLoading={!isFinish || isLoading}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={false}
                />
              </div>
              {quotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          )}
        </div>
      </PageSectionLayout>

      <PDF
        isOpen={action.pdf}
        id={id}
        handleClose={handleDrawerClose}
        addressDetails={addressDetails}
      />

      {action.informationModel && (
        <ProceedWithOrderFlow
          selectedCarrierRates={selectedCarrierRates}
          addressDetails={addressDetails}
          handleModalClose={handleModalClose}
          setIsRefresh={setIsRefresh}
        />
      )}

      <QuoteDetail
        isOpen={action.quoteDetail}
        handleClose={handleModalClose(false)}
        quote={{ addressDetails, dimensions, services, totalUnit, totalWeight }}
      />

      <PricingDetails
        isOpen={action.pricingDetails}
        handleClose={handleDrawerClose}
        quote={{ addressDetails, dimensions, services }}
        carrierRate={selectedCarrierRates[0]}
      />

      <Pricing
        isOpen={action.pricing}
        mode={action.mode}
        selectedCarrierRates={selectedCarrierRates}
        quoteID={id}
        setIsRefresh={setIsRefresh}
        handleClose={handleDrawerClose}
        currency={currency}
        dimensions={dimensions}
        services={services}
        setQuoteType={setQuoteType}
      />

      <AddQuoteDetail
        isOpen={action.addQuoteDetail}
        handleClose={handleDrawerClose}
        carrierRate={selectedCarrierRates[0]}
        currency={currency}
        setIsRefresh={setIsRefresh}
      />
      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRates={selectedCarrierRates}
          dimensions={dimensions}
          addressDetails={addressDetails}
          loading={submitLoading}
        />
      )}
    </>
  );
};

export default MyQuote;
