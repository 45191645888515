/* eslint-disable no-undef */
import { useEffect, useRef } from 'react';

const useKeyboardAdjustments = () => {
  const focusRecentlyOccurred = useRef(false);
  const lastScrollPosition = useRef({
    window: 0,
    mainLayoutSection: 0,
    overflowYAuto: new Map(), // Using a Map to track scroll positions of multiple elements
  });

  useEffect(() => {
    // Function to set up or update event listeners
    const setupEventListeners = () => {
      const handleFocusIn = () => {
        focusRecentlyOccurred.current = true;
        setTimeout(() => (focusRecentlyOccurred.current = false), 300);
      };

      const handleScroll = (event) => {
        const activeElement = document.activeElement;

        if (activeElement && activeElement.classList.contains('ignore-blur')) {
          return;
        }

        const target = event.target;

        if (target && target.closest) {
          if (
            target &&
            (target.closest('.select__menu-list') ||
              target.closest('.team-user-select') ||
              target.closest('.select__input') ||
              target.closest('.select__input-container'))
          ) {
            return; // Ignore scroll events originating from elements within 'select__menu-list'
          }
        }
        // Check if the event target or its parents have 'select__menu-list' class

        const currentScroll =
          target === document ? window.scrollY : target.scrollTop;
        const lastScroll =
          target === document
            ? lastScrollPosition.current.window
            : target.classList.contains('main__layout_section')
            ? lastScrollPosition.current.mainLayoutSection
            : lastScrollPosition.current.overflowYAuto.get(target) || 0;

        if (
          Math.abs(currentScroll - lastScroll) > 10 &&
          !focusRecentlyOccurred.current &&
          !document?.activeElement?.parentElement?.classList.contains(
            'select__input-container'
          )
        ) {
          document.activeElement.blur();
        }

        // Update last scroll position
        if (target === document) {
          lastScrollPosition.current.window = currentScroll;
        } else if (target.classList.contains('main__layout_section')) {
          lastScrollPosition.current.mainLayoutSection = currentScroll;
        } else {
          lastScrollPosition.current.overflowYAuto.set(target, currentScroll);
        }
      };

      // Adding global and main section listeners
      window.addEventListener('focusin', handleFocusIn);
      window.addEventListener('scroll', handleScroll, true);
      document
        .querySelector('.main__layout_section')
        ?.addEventListener('scroll', handleScroll, true);

      // Adding listeners for all elements with class overflow-y-auto
      document.querySelectorAll('.overflow-y-auto').forEach((element) => {
        lastScrollPosition.current.overflowYAuto.set(
          element,
          element.scrollTop
        );
        element.addEventListener('scroll', handleScroll, true);
      });
    };

    setupEventListeners(); // Call on initial mount

    return () => {
      // Cleanup function
      window.removeEventListener('focusin', handleFocusIn);
      window.removeEventListener('scroll', handleScroll, true);
      document
        .querySelector('.main__layout_section')
        ?.removeEventListener('scroll', handleScroll, true);
      document.querySelectorAll('.overflow-y-auto').forEach((element) => {
        element.removeEventListener('scroll', handleScroll, true);
      });
    };
  }, []);

  return null;
};

export default useKeyboardAdjustments;
