import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  capitalizeFirstLetterAndDash,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import CreateCustomerGroupModal from './CreateCustomerGroupModal';
import CustomerGroupLoaderRow from './CustomerGroupLoaderRow';
import CustomerGroupRow from './CustomerGroupRow';

const initParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'total160DaysGrossProfit',
  page: 1,
  limit: 50,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const CustomerGroup = () => {
  const { hasRoleV2 } = useRolePermission();

  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState(initParams);
  const [isShowCreateCustomerGroupModal, setIsShowCreateCustomerGroupModal] =
    useState(false);

  const CustomerGroupList: any = () => {
    // dummy
  };

  const getCustomerList = (signal?: any) => {
    // disabled api call dummy code
    return;

    setCustomers([]);
    setIsLoading(true);
    CustomerGroupList(params, signal)
      .then((response: any) => {
        if (response.data) {
          setCustomers(response.data);
        }
        setTotal(response.total ?? 0);
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getCustomerList(signal);

    return () => {
      controller.abort();
    };
  }, [params]);

  const headCellsGroups = useMemo(
    () => [
      {
        id: 'price',
        name: 'Group Name',
        sortable: true,
      },
      {
        id: 'serviceId',
        name: 'Customers',
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap sm:w-auto w-full">
            <div className="table-title-wrap">
              <h5 className="table-title">
                {params.segment !== ''
                  ? capitalizeFirstLetterAndDash(params?.segment ?? '')
                  : hasRoleV2('admin') || hasRoleV2('manager')
                  ? 'All'
                  : 'My'}{' '}
                Customer Groups
              </h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                50 {total <= 1 ? 'Customer' : 'Customers'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Create, view and manage your customer quotes.
            </p>
          </div>

          <ButtonCmp
            className="btn_primary sm:flex-none flex-1 xs:min-w-[auto] min-w-full"
            // onClick={() => setIsShowCustomerSalesRepReassign(true)}
            onClick={() => setIsShowCreateCustomerGroupModal(true)}
            icon={<Plus className="w-4 h-4" />}
          >
            Create new group
          </ButtonCmp>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              inputType="text"
              isClearable={true}
              onChangeFunc={(e) => {
                setParams((old: any) => ({
                  ...old,
                  ...{ search: e.target.value, page: 1 },
                }));
              }}
              parentClassName="table-searchInput"
            />
          </div>

          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              headCells={headCellsGroups}
              params={params}
              setParams={setParams}
              tableDataArr={customers}
              TableLoaderRowCmp={CustomerGroupLoaderRow}
              TableRowCmp={CustomerGroupRow}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={15}
              tableHeaderClass=""
              isTableRowClickable={hasRoleV2('admin')}
            />
          </div>
          {!isLoading && !customers.length && (
            <NotFoundUI
              title="No Customer Found"
              desc="There are no data for customers."
              containerClassName="min-h-[unset]"
            />
          )}
        </div>

        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>
      {isShowCreateCustomerGroupModal && (
        <CreateCustomerGroupModal
          handleClose={() => setIsShowCreateCustomerGroupModal(false)}
        />
      )}
    </>
  );
};

export default CustomerGroup;
