import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const MessageListLoading = ({ isLoadMore }: any) => (
  <div className=" border-b border-utilityGray200">
    {Array.from({ length: isLoadMore ? 2 : 9 }).map((_, index) => (
      <div
        className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer"
        key={index}
      >
        <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
        <div className="flex-1">
          <h6 className="text-sm textSecondary  font-medium custom-loading">
            Anthropic
          </h6>
          <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
            Lets get you signed in All you have to ...
          </p>
        </div>
        <div className="text-xs text-grayLight600 font-normal custom-loading">
          2d ago
        </div>
        <button
          className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
          type="submit"
        >
          <XClose className="w-4 h-4 " />
        </button>
      </div>
    ))}
    {/*<div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
     <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div>
    <div className="p-4 pl-3 flex gap-1.5 items-center cursor-pointer">
      <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start custom-loading"></p>
      <div className="flex-1">
        <h6 className="text-sm textSecondary  font-medium custom-loading">
          Anthropic
        </h6>
        <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary custom-loading">
          Lets get you signed in All you have to ...
        </p>
      </div>
      <div className="text-xs text-grayLight600 font-normal custom-loading">
        2d ago
      </div>
      <button
        className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 custom-loading"
        type="submit"
      >
        <XClose className="w-4 h-4 " />
      </button>
    </div> */}
  </div>
);

export default MessageListLoading;
