import React from 'react';

const ContactTabOrderLoadingRaw = () => (
  <>
    <td className="px-5 py-4 w-1/4 min-w-1/4 max-w-72">
      <h6 className="truncate custom-loading">Kate Morrison</h6>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">Account Executive</span>
    </td>
    <td className="px-5 py-4 font-normal ">
      <span className="custom-loading">+(415)555-0132</span>
    </td>
    <td className="px-5 py-4 w-1/4 min-w-1/4 max-w-72">
      <p className="truncate custom-loading">kate@walhq.com</p>
    </td>
  </>
);

export default ContactTabOrderLoadingRaw;
