import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { APP_FULL_NAME } from '../constants/common';
import { ROUTES } from '../constants/routes';
import { AuthContext } from '../context/AuthContext';

const PublicRoute = ({ component: Component, title, ...props }: any) => {
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    document.title = `${title} - ${APP_FULL_NAME}`;
  }, [title]);

  return isAuthenticated ? (
    <Navigate to={ROUTES.HOME} />
  ) : (
    <Component {...props} />
  );
};

export default PublicRoute;
