import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import outlook from 'src/assets/img/outlook.svg';
import ButtonCmp from 'src/components/ButtonCmp';
import { getQuoteEmailDetails } from 'src/services/QuoteEmailService';
import WalToast from 'src/utils/WalToast';

import EmailDetailView from './EmailDetailView';

const QuoteEmailDetailView = ({ emailContentId }: any) => {
  const [quoteEmailDetails, setQuoteEmailDetails] = useState<any>(null);
  const [isShowEmailDetailView, setIsShowEmailDetailView] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const messageId = searchParams.get('messageId') || emailContentId;

  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    if (!isClicked && !isShowEmailDetailView) {
      console.log(isShowEmailDetailView, 'enter');
      setIsShowEmailDetailView(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      setIsShowEmailDetailView(false);
    }
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    console.log(isShowEmailDetailView);

    if (!isShowEmailDetailView) {
      setIsShowEmailDetailView(true);
    }
  };

  useEffect(() => {
    if (!isShowEmailDetailView) {
      setIsClicked(false);
    }
  }, [isShowEmailDetailView]);

  const fetchQuoteEmailDetails = async () => {
    try {
      const quoteEmails = (await getQuoteEmailDetails({
        messageId: messageId,
      })) as any;
      setQuoteEmailDetails(quoteEmails?.data);
    } catch (e: any) {
      console.log(e);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  useEffect(() => {
    if (messageId) {
      fetchQuoteEmailDetails();
    }
  }, [searchParams]);

  return (
    <div className="absolute bottom-[6px] xxl:right-5 right-4 xl:mr-4 sm:mr-4">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <ButtonCmp
          className="btn-outline-primary !px-8"
          icon={<img src={outlook} className="w-4 h-4" />}
        >
          View Email
        </ButtonCmp>
      </div>
      {isShowEmailDetailView && (
        <EmailDetailView
          emailDetails={quoteEmailDetails}
          handleClose={() => setIsShowEmailDetailView(false)}
          isShowEmailDetailView={isShowEmailDetailView}
          isClicked={isClicked}
        />
      )}
    </div>
  );
};

export default QuoteEmailDetailView;
