import { Check, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import { IStepBar, ISteps } from './StepBar.interface';

const StepBar = ({ steps, activeStep }: IStepBar) => (
  <ol className="flex  items-center md:justify-end w-full gap-2">
    {steps.map(({ name, step }: ISteps, index) => (
      <li
        key={index}
        className={`flex xs:gap-3 gap-1 justify-end items-center w-auto [&:last-child>span>svg]:hidden `}
      >
        {activeStep === step ? (
          <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 bg-primary shadow-md rounded-full shrink-0">
            <span className="flex items-center justify-center xs:w-1.5 w-1.5 xs:h-1.5 h-1.5 bg-white rounded-full shrink-0"></span>
          </span>
        ) : activeStep > step ? (
          <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 bg-primary rounded-full shrink-0">
            <Check className="text-white xs:w-4 w-3 xs:h-4 h-3" />
          </span>
        ) : (
          <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 border border-utilityGray200 bg-white shadow-md rounded-full shrink-0">
            <span className="flex items-center justify-center xs:w-1.5 w-1.5 xs:h-1.5 h-1.5 bg-borderPrimary rounded-full shrink-0"></span>
          </span>
        )}
        <span className="md:text-xs xs:text-xs text-[10px] text-textSecondary font-semibold flex items-center">
          {name}
          <ChevronRight className="ml-1 h-4 w-4" />
        </span>
      </li>
    ))}
  </ol>
);

export default StepBar;
