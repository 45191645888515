import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  formatAddress,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const OrderRow = ({ data }: any) => {
  const { currency } = useContext(BasicContext);

  const getAddressWholeString = (
    first: string,
    second: string,
    third: string,
    fourth: string
  ) =>
    [first, second, third, fourth].filter((e) => e).length
      ? [first, second, third, fourth].filter((e) => e).toString()
      : false;

  return (
    <>
      <td className="px-5 py-4 min-w-52 w-52 max-w-52">
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center">
            {data.orderCommissioneeImage ? (
              <img
                className="w-7 h-7 rounded-full border border-black/[0.08] flex-none"
                src={
                  data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                }
                alt={data.orderCommissioneeFirstName}
                title={data.orderCommissioneeFirstName}
                onError={onError}
              />
            ) : (
              <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                {getShortName(
                  `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                )}
              </div>
            )}
            <div className="pl-2 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.fullId}
              </p>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-xs font-medium max-w-32">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.orderCommissionees?.split(',').length} Sales Reps.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.fullId}
            </p>
          </div>
        ) : data.orderCommissionees ? (
          <div className="flex items-center">
            <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
              {getShortName(data.orderCommissionees)}
            </div>
            <div className="pl-2 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1">
                <span className="inline-block max-w-32 truncate">
                  {data.orderCommissionees}
                </span>
                <TooltipCmp
                  message={`${data.orderCommissionees} is not an active user on WALHQ`}
                >
                  <InfoCircle className="h-3.5 w-3.5" />
                </TooltipCmp>
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.fullId}
              </p>
            </div>
          </div>
        ) : (
          <div className="pl-2 text-xs font-medium max-w-32">
            <p>-</p>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.fullId}
            </p>
          </div>
        )}
      </td>
      <td className="px-5 py-4 max-w-52 min-w-52 w-52 ">
        {(data.orderStatus === 'cancelled' ||
          data.orderStatus === 'no-quote' ||
          data.orderStatus === 'claim') && (
          <BadgeCmp style="modern" type="error">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'delivered' ||
          data.orderStatus === 'quoted') && (
          <BadgeCmp style="modern" type="success">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'quick-quoted' ||
          data.orderStatus === 'new' ||
          data.orderStatus === 'invoice-sent') && (
          <BadgeCmp style="modern" type="pink">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'archived' ||
          data.orderStatus === 'dispatched' ||
          data.orderStatus === 'invoice-paid' ||
          data.orderStatus === 'saved') && (
          <BadgeCmp style="modern" type="primary">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'in-transit' ||
          data.orderStatus === 'draft-quick-quote' ||
          data.orderStatus === 'no-quick-quote') && (
          <BadgeCmp style="modern" type="lightblue">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'invoice-created' ||
          data.orderStatus === 'spot-qq-requested') && (
          <BadgeCmp style="modern" type="gray">
            {data.orderStatus}
          </BadgeCmp>
        )}
      </td>
      <td className="px-5 py-4 max-w-52 min-w-52 w-52 ">
        <TooltipCmp
          childrenClassName="!block truncate max-w-48"
          message={data.customerName}
        >
          {data.customerName}
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 text-xs max-w-64 w-64 min-w-64 ">
        <TooltipCmp
          message={formatAddress(
            getAddressWholeString(
              data.orderFromOrgName,
              data.orderFromCity,
              data.orderFromState,
              data.orderFromPostal
            )
          )}
          type="dark"
          parentClassName=""
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">
              {`${
                getAddressWholeString(
                  data.orderFromOrgName,
                  data.orderFromCity,
                  data.orderFromState,
                  data.orderFromPostal
                )
                  ? formatAddress(
                      getAddressWholeString(
                        data.orderFromOrgName,
                        data.orderFromCity,
                        data.orderFromState,
                        data.orderFromPostal
                      )
                    )
                  : '-'
              }`}
            </span>
          </p>
        </TooltipCmp>
        <TooltipCmp
          message={formatAddress(
            getAddressWholeString(
              data.orderToOrgName,
              data.orderToCity,
              data.orderToState,
              data.orderToPostal
            )
          )}
          type="dark"
          parentClassName="max-w-90"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[180px]">{`${
              getAddressWholeString(
                data.orderToOrgName,
                data.orderToCity,
                data.orderToState,
                data.orderToPostal
              )
                ? formatAddress(
                    getAddressWholeString(
                      data.orderToOrgName,
                      data.orderToCity,
                      data.orderToState,
                      data.orderToPostal
                    )
                  )
                : '-'
            }`}</span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 min-w-44 max-w-44">
        <span
          dangerouslySetInnerHTML={{
            __html: data.orderCreateTime
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.orderCreateTime,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4 font-normal min-w-36 ">
        <span>
          {data.combinedRevenue
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedRevenue
                  : data.combinedRevenueUSD,
                true,
                true
              )
            : 0}{' '}
          {currency}
        </span>
      </td>
      <td className="px-5 py-4 font-normal min-w-36 ">
        <span>
          {data.combinedCost
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedCost
                  : data.combinedCostUSD,
                true,
                true
              )
            : 0}{' '}
          {currency}
        </span>
      </td>
      <td className="px-5 py-4 font-normal min-w-[120px] max-w-[120px] w-[120px]">
        <div className="flex gap-1">
          {data.combinedMarginPercentage
            ? getFormattedNumber(data.combinedMarginPercentage)
            : 0}
          %
          {!!data.isCostCalculatedFromRevenue && (
            <TooltipCmp message={<div>{`Margin estimated`}</div>}>
              <InfoCircle className="h-3.5 w-3.5 text-primary" />
            </TooltipCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 font-semibold min-w-40 max-w-40 w-40">
        <span>
          {data.combinedMargin
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedMargin
                  : data.combinedMarginUSD,
                true,
                true
              )
            : 0}{' '}
          {currency}
        </span>
      </td>
    </>
  );
};

export default OrderRow;
