import 'moment-timezone';

import moment from 'moment';
// eslint-disable-next-line import/order
import React from 'react';

import './assets/css/app.css';
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';

import Loader from './components/Loader';
import { TIMEZONE } from './constants/common';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { BasicProvider } from './context/BasicContext';
import useKeyboardAdjustments from './hooks/useKeyboardAdjustments';
import { setupStore } from './redux/store';
import Routes from './routes';

moment.tz.setDefault(TIMEZONE);

function App() {
  useKeyboardAdjustments();

  return (
    <Provider store={setupStore()}>
      <AuthProvider>
        <BasicProvider>
          <AuthContext.Consumer>
            {({ loader }) => (loader ? <Loader /> : <Routes />)}
          </AuthContext.Consumer>
        </BasicProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
