import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import Carriers from 'src/app/Carriers';
import CustomerDetail from 'src/app/Customers/CustomerDetail';
import MapIt from 'src/app/Geography/MapIt';
import Integration from 'src/app/Integration';
import LoadInsurance from 'src/app/LoadInsurance';
import LoadInsuranceDetails from 'src/app/LoadInsurance/LoadInsuranceDetails';
import Orders from 'src/app/Orders';
import PredictivePriceChart from 'src/app/QuotingHub/PredictivePricing/PredictivePriceChart';
import TargetSetEdit from 'src/app/SalesDashboard/TargetSet/TargetSetEdit';
import TeamMemberDetail from 'src/app/Teams/TeamManagement/TeamDetail';
import { setCurrentBreakPoint, setIsMobile } from 'src/redux/MobileView.slice';
import { useRolePermission } from 'src/utils/CommonFunctions';

import AccountSetting from '../app/AccountSetting';
import AppLayout from '../app/AppLayout';
import Callback from '../app/Callback';
import Connections from '../app/Connections';
import Customers from '../app/Customers';
import PageNotFound from '../app/Errors/404';
import Feedback from '../app/Feedback';
import Login from '../app/Login';
import TrackAndTrace from '../app/Operations/TrackAndTrace';
import LaneHistory from '../app/QuotingHub/LaneHistory';
import SmartQuotes from '../app/QuotingHub/QuotingDashboard';
import QuotingDashboard from '../app/QuotingHub/QuotingDashboard';
import AddressDetails from '../app/QuotingHub/QuotingDashboard/AddressDetails/AddressDetailsForm';
import Dimensions from '../app/QuotingHub/QuotingDashboard/Dimensions';
import MyQuote from '../app/QuotingHub/QuotingDashboard/Quote';
import QuotingRequest from '../app/QuotingHub/QuotingRequest';
import ReqSmartQuote from '../app/QuotingHub/QuotingRequest/SmartQuote/AddressDetails/AddressDetailsForm';
import ReqDimensions from '../app/QuotingHub/QuotingRequest/SmartQuote/Dimensions';
import ReqMyQuote from '../app/QuotingHub/QuotingRequest/SmartQuote/Quote/index';
import RateShopping from '../app/QuotingHub/RateShopping';
import OverView from '../app/SalesDashboard/OverView';
import SalesBackup from '../app/SalesDashboard/SalesBackup';
// eslint-disable-next-line import/order
import TargetSet from '../app/SalesDashboard/TargetSet';

// TEAM
import TeamManagement from '../app/Teams/TeamManagement';
import TeamMembers from '../app/Teams/TeamMembers';
import { PERMISSION } from '../constants/common';
import { ROUTES } from '../constants/routes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { hasRoleV2 } = useRolePermission();
  const dispatch = useDispatch();
  const uiState = useSelector((state: any) => state.MobileView);

  const updateMobileState = () => {
    const isMobileState = window.innerWidth <= 1023;

    if (
      window.innerWidth > 700 &&
      window.innerWidth <= 1023 &&
      uiState.currentBreakpoint !== 'SM'
    ) {
      dispatch(setCurrentBreakPoint('SM'));
    }

    if (
      window.innerWidth > 1023 &&
      window.innerWidth <= 1360 &&
      uiState.currentBreakpoint !== 'LG'
    ) {
      dispatch(setCurrentBreakPoint('LG'));
    }

    if (
      window.innerWidth > 1360 &&
      window.innerWidth <= 1549 &&
      uiState.currentBreakpoint !== 'XL'
    ) {
      dispatch(setCurrentBreakPoint('XL'));
    }

    if (
      window.innerWidth > 1549 &&
      window.innerWidth <= 1760 &&
      uiState.currentBreakpoint !== 'XLM'
    ) {
      dispatch(setCurrentBreakPoint('XLM'));
    }

    if (window.innerWidth > 1760 && uiState.currentBreakpoint !== '2XL') {
      dispatch(setCurrentBreakPoint('2XL'));
    }

    if (isMobileState === uiState.isMobile) {
      return;
    }

    if (
      isMobileState === uiState.isMobile &&
      uiState.currentBreakpoint !== 'SM'
    ) {
      dispatch(setCurrentBreakPoint(''));
    }

    dispatch(setIsMobile(isMobileState));
    // isMobile global state logic
    // if (!uiState.isMobilePageVisitedOnce) {
    //     navigate(ROUTES.MOBILE_LANDING);
    // }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateMobileState);
    });

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [uiState.currentBreakpoint]);

  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path={ROUTES.HOME} element={<AppLayout />}>
          <Route
            path={ROUTES.LOGIN}
            element={<PublicRoute component={Login} title="Login" />}
          />
          <Route
            path={ROUTES.CALLBACK}
            element={<PublicRoute component={Callback} title="Callback" />}
          />

          <Route
            index
            path={ROUTES.HOME}
            element={
              <PrivateRoute
                component={SmartQuotes}
                title="Smart Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />

          <Route
            index
            path={ROUTES.ADDRESS_DETAILS}
            element={
              <PrivateRoute
                component={AddressDetails}
                title="Address Details"
                permission={PERMISSION.CREATE_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.DIMENSIONS}
            element={
              <PrivateRoute
                component={Dimensions}
                title="Dimensions"
                permission={PERMISSION.CREATE_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.MY_QUOTE}
            element={
              <PrivateRoute
                component={MyQuote}
                title="My Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.FEEDBACK_LIST}
            element={
              <PrivateRoute
                component={Feedback}
                title="Feedback"
                permission={true}
              />
            }
          />
          <Route
            path={ROUTES.QUOTING_DASHBOARD}
            element={
              <PrivateRoute
                component={QuotingDashboard}
                title="Quoting Dashboard"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.PREDICTIVE_PRICING}
            element={
              <PrivateRoute
                component={PredictivePriceChart}
                title="Predictive Pricing"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />

          <Route
            path={ROUTES.QUOTING_REQUEST}
            element={
              <PrivateRoute
                component={QuotingRequest}
                title="Quoting Request"
                permission={PERMISSION.QUOTING_REQUEST}
              />
            }
          />
          <Route
            path={ROUTES.REQ_SMART_QUOTE}
            element={
              <PrivateRoute
                component={ReqSmartQuote}
                title="Smart Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.REQ_DIMENSION}
            element={
              <PrivateRoute
                component={ReqDimensions}
                title="Dimensions"
                permission={PERMISSION.CREATE_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.REQ_MY_QUOTE}
            element={
              <PrivateRoute
                component={ReqMyQuote}
                title="My Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.LANE_HISTORY}
            element={
              <PrivateRoute
                component={LaneHistory}
                title="Lane History"
                permission={PERMISSION.LANE_HISTORY}
              />
            }
          />
          <Route
            path={ROUTES.RATE_SHOPPING}
            element={
              <PrivateRoute
                component={RateShopping}
                title="Rate Shopping"
                permission={PERMISSION.RATE_SHOPPING}
              />
            }
          />

          {/* Permission control v2 */}
          <Route
            path={ROUTES.TEAMS}
            element={
              <PrivateRoute
                component={TeamMembers}
                title="Teams"
                permission={PERMISSION.USER_LIST}
              />
            }
          />
          <Route
            path={ROUTES.TEAM_MANAGEMENT}
            element={
              <PrivateRoute
                component={TeamManagement}
                title="Team Management"
                permission={PERMISSION.TEAM_LIST}
              />
            }
          />
          <Route
            path={ROUTES.TEAM_DETAIL}
            element={
              <PrivateRoute
                component={TeamMemberDetail}
                title="Team Detail"
                permission={PERMISSION.TEAM_LIST}
              />
            }
          />
          {/* <Route path={ROUTES.ORGANIZATIONAL_CHART} element={<PrivateRoute component={OrganizationalChart} title="Organizational Chart" permission={PERMISSION.ORGANIZATIONAL_CHART} />} /> */}

          <Route
            path={ROUTES.CARRIERS}
            element={
              <PrivateRoute
                component={Carriers}
                title="Carriers"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />
          <Route
            path={ROUTES.CONNECTIONS}
            element={
              <PrivateRoute
                component={Connections}
                title="Carriers"
                permission={
                  hasRoleV2('admin') ? PERMISSION.CARRIER_VIEW : false
                }
              />
            }
          />

          <Route
            path={ROUTES.ACCOUNT_SETTING}
            element={
              <PrivateRoute
                component={AccountSetting}
                title="Account Settings"
                permission={true}
              />
            }
          />
          {/* Permission control v2 */}

          <Route
            path={ROUTES.SALES_OVERVIEW}
            element={
              <PrivateRoute
                component={OverView}
                title="Sales Dashboard"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.OVERVIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.ORDERS}
            element={
              <PrivateRoute
                component={Orders}
                title="Orders"
                permission={PERMISSION.ORDERS}
              />
            }
          />
          <Route
            path={ROUTES.SALES_HISTORY}
            element={
              <PrivateRoute
                component={SalesBackup}
                title="Sales Backup"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.ADVANCE_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_TARGET}
            element={
              <PrivateRoute
                component={TargetSet}
                title="Sales - Target"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.TARGET_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_TARGET_EDIT}
            element={
              <PrivateRoute
                component={TargetSetEdit}
                title="Sales - Target-Set"
                permission={PERMISSION.TARGET_UPDATE}
              />
            }
          />

          <Route
            path={ROUTES.TRACK_AND_TRACE}
            element={
              <PrivateRoute
                component={TrackAndTrace}
                title="Operations - Track and Trace"
                permission={PERMISSION.TRACK_AND_TRACE}
              />
            }
          />

          <Route
            path={ROUTES.CUSTOMERS}
            element={
              <PrivateRoute
                component={Customers}
                title="Customers"
                permission={PERMISSION.CUSTOMER_LIST}
              />
            }
          />
          <Route
            path={ROUTES.CUSTOMER_DETAILS}
            element={
              <PrivateRoute
                component={CustomerDetail}
                title="Customers Detail"
                permission={
                  hasRoleV2('admin') ? PERMISSION.CUSTOMER_LIST : false
                }
              />
            }
          />
          <Route
            path={ROUTES.INTEGRATION}
            element={
              <PrivateRoute
                component={Integration}
                title="Integration"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />
          <Route
            path={ROUTES.LOAD_INSURANCE}
            element={
              <PrivateRoute
                component={LoadInsurance}
                title="Load Insurance"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />
          <Route
            path={ROUTES.LOAD_INSURANCE_DETAILS}
            element={
              <PrivateRoute
                component={LoadInsuranceDetails}
                title="Load Insurance Details"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />

          <Route
            path={ROUTES.CUSTOMER_DETAILS}
            element={
              <PrivateRoute
                component={CustomerDetail}
                title="Customers Detail"
                permission={
                  hasRoleV2('admin') ? PERMISSION.CUSTOMER_LIST : false
                }
              />
            }
          />

          <Route
            path={ROUTES.MAPIT}
            element={
              <PrivateRoute
                component={MapIt}
                title="Map It"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default Routes;
