import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { getUser } from '../services/AuthService';

export const AuthContext = createContext<any>(null);

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerifyCode, setIsVerifyCode] = useState(false);
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (
      // eslint-disable-next-line no-prototype-builtins
      localStorage.hasOwnProperty('token') &&
      localStorage.getItem('token')
    ) {
      getUser()
        .then((response) => {
          if (response.data) {
            setCurrentUser(response.data);
            setIsAuthenticated(true);
          }
        })
        .finally(() => {
          setIsUpdateProfile(false);
          setLoader(false);
        })
        .catch(console.log);
    } else {
      setLoader(false);
      setIsAuthenticated(false);
      setIsUpdateProfile(false);
    }
  }, [isVerifyCode, isUpdateProfile]);

  const reloadUser = () => {
    getUser()
      .then((response) => {
        if (response.data) {
          setCurrentUser(response.data);
          setIsAuthenticated(true);
        }
      })
      .finally(() => {
        setIsUpdateProfile(false);
      })
      .catch(console.log);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        loader,
        setIsVerifyCode,
        setIsAuthenticated,
        setIsUpdateProfile,
        setCurrentUser,
        reloadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
