import React from 'react';

const LoadInsuranceLoading = () => (
  <>
    <td className="px-5 py-4">
      <span className="custom-loading">#12345</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">Test Comodity</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">12345</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">#</span>
    </td>
  </>
);

export default LoadInsuranceLoading;
