import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getUser = async () => {
  const response = await axiosInterceptor.get(API.USER.GET);

  return response;
};

export const login = async () => {
  const response: any = await axiosInterceptor.get(API.USER.LOGIN);
  const params = new URLSearchParams(response.data.url);
  const verifier: any = params.get('verifier');
  localStorage.clear();
  localStorage.setItem('verifier', verifier);

  return response;
};

export const logout = async () => {
  const response: any = await axiosInterceptor.get(API.USER.LOGOUT);
  localStorage.removeItem('token');
  localStorage.removeItem('accessToken');

  return response;
};

export const code = async (data: any) => {
  const response: any = await axiosInterceptor.post(API.USER.CODE, data);
  localStorage.setItem('token', response.token);
  localStorage.setItem('accessToken', response.accessToken);

  return response;
};
