import { Mail01, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../assets/img/default-image.jpg';
import ButtonCmp from '../../components/ButtonCmp';
import InputText from '../../components/InputText/InputText';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';
// import SelectBox from '../../components/SelectBox/SelectBox';
import { AuthContext } from '../../context/AuthContext';
import { getUser } from '../../services/AuthService';
// import { listRole } from '../../services/RoleService';
import { updateUser } from '../../services/TeamMemberService';
import {
  // getSelectBoxOptions,
  getShortName,
  onError,
  useRolePermission,
} from '../../utils/CommonFunctions';

const AccountSetting = () => {
  const initSelectFormData = {
    role: null,
  };
  const initValidForm = {
    firstName: true,
    lastName: true,
    email: true,
    role: true,
    jobRole: true,
    image: true,
  };

  const { currentUser, setIsUpdateProfile } = useContext(AuthContext);
  const [user, setUser] = useState<any>(currentUser);
  const { hasRoleV2 } = useRolePermission();

  const initFormData = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    jobRole: user.jobRole || '',
    role: user.role,
    image: user.imageUrl + user.image,
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [displayImage, setDisplayImage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFormData({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      jobRole: user.jobRole || '',
      // role: user.role?.id || '',
      image: user.imageUrl + user.image,
    });
  }, [user]);

  useEffect(() => {
    getUser()
      .then((response) => {
        if (response.data) {
          setUser(response.data);
          setFormData(response.data);
        }
      })
      .finally(() => {})
      .catch(console.log);
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setDisplayImage(null);
    const file = acceptedFiles[0];

    if (file.type.match('image')) {
      setFormData((currentFormData: any) => ({
        ...currentFormData,
        image: file, // This sets the new image and keeps the rest of the formData as it is
      }));
      setValidForm({ ...validForm, image: true });
    } else {
      setValidForm({ ...validForm, image: false });
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          setDisplayImage(img.src);
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      firstName: true,
      lastName: true,
      email: true,
      isEmailValid: true,
      jobRole: true,
      role: true,
    };
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

    if (formData.firstName === '' || formData.firstName === null) {
      validFormData.firstName = false;
      valid = false;
    }

    if (formData.lastName === '' || formData.lastName === null) {
      validFormData.lastName = false;
      valid = false;
    }

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      valid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      validFormData.isEmailValid = false;
      valid = false;
    }

    if (formData.role === '' || formData.role === null) {
      validFormData.role = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      appendFormData.append(key, value);
    });

    // pushing image to last
    const imageValueOfFormToAppend = appendFormData.get('image');
    appendFormData.delete('image');

    if (imageValueOfFormToAppend) {
      appendFormData.append('image', imageValueOfFormToAppend);
    }

    updateUser(user.id, appendFormData)
      .then(() => {
        setIsUpdateProfile(true);
        WalToast.success('Profile have been updated successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(() => WalToast.error('Profile have been not updated'));
  };

  return (
    <PageSectionLayout
      header={
        <Header title="Account Settings" desc="Manage your account settings." />
      }
    >
      <form className="h-full w-full" onSubmit={handleSubmit}>
        <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
          <div>
            <h6 className="text-grayLight900 font-semibold text-sm">Profile</h6>
            <p className="text-grayLight600 font-normal text-sm">
              Update your photo and personal details here.
            </p>
          </div>
        </div>

        <div className="mt-4">
          <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Name
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputName="firstName"
                placeholder="Enter first name"
                parentClassName="flex-1"
                value={formData.firstName}
                onChangeFunc={handleInputChange}
                errorText={
                  !validForm.firstName ? 'First name is required' : null
                }
              />

              <InputText
                inputName="lastName"
                placeholder="LastName"
                value={formData.lastName}
                onChangeFunc={handleInputChange}
                className="shadow placeholder:text-gray500  text-black/90"
                parentClassName="w-1/2"
                errorText={!validForm.lastName ? 'Last name is required' : null}
              />
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Email
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputType="email"
                inputName="Email"
                placeholder="Email"
                value={formData.email}
                iconLeft={
                  <Mail01 className="h-4 w-4 cursor-pointer absolute left-2 mr-2 text-black/90" />
                }
                onChangeFunc={handleInputChange}
                className="shadow placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-7"
                parentClassName="w-full"
                disabled={true}
                errorText={!validForm.email ? 'email is required' : null}
              />
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              <div className="flex items-center gap-0.5">Profile picture</div>
              <p className="text-grayLight600 font-normal">
                This will be displayed on your profile.
              </p>{' '}
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
              <div className="flex gap-4">
                {displayImage ? (
                  <img
                    className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover opa"
                    src={displayImage}
                    alt={formData.firstName}
                    title={formData.firstName}
                  />
                ) : formData.image ? (
                  <label className="cursor-pointer">
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                      src={user.imageUrl + user.image}
                      alt={formData.firstName}
                      title={formData.firstName}
                      onError={onError}
                    />
                  </label>
                ) : formData.firstName ? (
                  <label className="flex-none w-14 h-14 rounded-full cursor-pointer text-2xl font-medium bg-primary  text-white border-[0.75px] border-black/[0.08] flex items-center justify-center uppercase">
                    {getShortName(`${formData.firstName} ${formData.lastName}`)}
                  </label>
                ) : (
                  <label className="hover_label cursor-pointer">
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                      src={defaultImage}
                      alt={formData.firstName}
                      title={formData.firstName}
                    />
                  </label>
                )}

                <label
                  className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
                  htmlFor="fileSelect"
                  {...getRootProps()}
                >
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">
                      SVG, PNG or JPG (max. 800x400px)
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="image"
                  id="fileSelect11"
                  className="hidden"
                  {...getInputProps()}
                />
              </div>
              <span className="text-xs font-normal text-red-600 dark:text-red-500">
                {!validForm.image ? 'image type is invalid' : null}
              </span>
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Account type
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputName="role"
                parentClassName="flex-1"
                labelClassName="form_label"
                className="p-2 flex-1 h-[unset] disabled:bg-gray50 disabled:text-gray500"
                value={formData?.role?.displayName}
                disabled={true}
                errorText={!validForm.role ? 'Account type is required' : null}
              />
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Role
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputName="jobRole"
                placeholder="Enter role"
                parentClassName="flex-1"
                className="p-2 flex-1 h-[unset] disabled:bg-gray50 disabled:text-gray500"
                value={formData.jobRole}
                disabled={!hasRoleV2('admin')}
                onChangeFunc={handleInputChange}
                errorText={!validForm.jobRole ? 'Job role is required' : null}
              />
            </div>
          </div>

          <div className="flex justify-end gap-4 py-4">
            <ButtonCmp
              type="button"
              className="btn_secondary_black shadow py-2 px-3"
              onClick={() => {
                setFormData(initFormData);
                setSelectFormData({
                  ...selectFormData,
                  ...initSelectFormData,
                });
              }}
            >
              Cancel
            </ButtonCmp>
            <ButtonCmp
              type="submit"
              className="btn_primary  shadow py-2 px-3"
              loading={isLoading}
            >
              Save
            </ButtonCmp>
          </div>
        </div>
      </form>
    </PageSectionLayout>
  );
};

export default AccountSetting;
