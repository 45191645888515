import { Edit01, Eye } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'src/constants/path';

const LoadInsuranceRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.orderId}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.commodity}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.commercial}</span>
    </td>
    <td>
      <div className="flex gap-2">
        <Edit01 className="w-4 h-4 " />
        <Link to={`${PATH.LOAD_INSURANCE}/${data.id}`}>
          <Eye className="w-4 h-4" />
        </Link>
      </div>
    </td>
  </>
);

export default LoadInsuranceRow;
