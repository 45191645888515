import {
  CheckSquareBroken,
  Copy06,
  DownloadCloud02,
  Edit05,
  InfoCircle,
  Share03,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import {
  downloadPdf,
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

import ServiceDetail from './ServiceDetail';

const QuotedRow = ({
  data,
  handleActionType,
  currency,
  addressDetails,
}: any) => {
  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <>
      <td className="px-5 py-4 min-w-60 w-60">
        <div className=" flex items-center">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(`${data.name}`)}
            </div>
          )}
          <div className="pl-3 max-w-48">
            {(data.name + (data.banyanPostFix || '')).length > 30 ? (
              <TooltipCmp message={`${data.name} ${data.banyanPostFix || ''}`}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">
                  {`${data.name} ${data.banyanPostFix || ''}`}
                </h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">
                {`${data.name} ${data.banyanPostFix || ''}`}
              </h6>
            )}

            <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
              {data.carrierQuoteId.length > 30 ? (
                <TooltipCmp
                  message={`#${data.carrierQuoteId}`}
                  childrenClassName="max-w-36 truncate"
                >
                  <span className="max-w-36 truncate inline-block align-middle  ">
                    #{data.carrierQuoteId}
                  </span>
                </TooltipCmp>
              ) : (
                <span className="inline align-middle truncate max-w-36">
                  #{data.carrierQuoteId}
                </span>
              )}
              &nbsp;
              <div className="flex gap-1">
                <TooltipCmp message="Copy">
                  <Copy06
                    onClick={(event) => {
                      event.stopPropagation();
                      copyDebounce(data.carrierQuoteId);
                    }}
                    className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                  />
                </TooltipCmp>

                {data.quoteUrl && (
                  <TooltipCmp message="Redirect">
                    <a
                      href={data.quoteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                    </a>
                  </TooltipCmp>
                )}
              </div>
            </p>
          </div>
        </div>
      </td>
      {/* <td className="px-5 py-4">
                                                                    {isFinish &&
                                                                        data.tags &&
                                                                        (addressDetails.status === STATUS.OPENED || addressDetails.status === STATUS.ACTIVE) &&
                                                                        data.tags[0] && (
                                                                            <div className="px-2 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center gap-1 inline-flex">
                                                                                {data.tags[0] === "Fastest" ? (
                                                                                    <div className="p-1 bg-green500 rounded-full" />
                                                                                ) : (
                                                                                    <div className="p-1 bg-orange500 rounded-full" />
                                                                                )}

                                                                                <div className="text-center text-textSecondary text-[11px] font-medium leading-[18px]">{data.tags[0]}</div>
                                                                            </div>
                                                                        )}
                                                                </td> */}
      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        {(data.isFreightcom || data.isFreightcom === 1) && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={FreightcomIcon}
              alt="FC"
            />
            <div className="pl-3 font-medium">Freightcom</div>
          </div>
        )}
        {(data.isBanyan || data.isBanyan === 1) && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={BanyanIcon}
              alt="BANYAN"
            />
            <div className="pl-3 font-medium">Banyan</div>
          </div>
        )}
        {!data.isBanyan && !data.isFreightcom && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={data.image ? `${data.imageUrl}${data.image}` : defaultImage}
              alt="CR"
              onError={onError}
            />
            <div className="pl-3 font-medium">Direct</div>
          </div>
        )}
      </td>
      <td className="px-5 py-4">
        {data.transitTime > 0
          ? `Est. ${data.transitTime} days`
          : data.transitTime === 0
          ? ' Est. Today'
          : '-'}
      </td>
      <td className="px-5 py-4">
        {/* {data.projectedDeliveryDate ? moment(data.projectedDeliveryDate).format("MMM. Do - YYYY") : "-"} */}
        {data.transitTime > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment
                      .utc(data.projectedDeliveryDate)
                      .format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4">
        <span>
          {data.totalCharge === 0
            ? '-'
            : currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.totalCharge)}`
            : `$${getFormattedNumber(data.totalChargeUSD)}`}
        </span>
      </td>
      <td className="px-5 py-4">
        <div className="flex items-center gap-1">
          {data.additionalCharges > 0 || data?.services?.length > 0 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={<ServiceDetail carrierServices={data} />}
            >
              {data.additionalCharges === 0
                ? '$0.00'
                : currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.additionalCharges)}`
                : `$${getFormattedNumber(data.additionalChargesUSD)}`}
            </ActionTooltip>
          ) : data.additionalCharges === 0 ? (
            '$0.00'
          ) : currency === CURRENCY.CAD ? (
            `$${getFormattedNumber(data.additionalCharges)}`
          ) : (
            `$${getFormattedNumber(data.additionalChargesUSD)}`
          )}
          {data?.services.some((form: any) => !form.isQuoted) ? (
            <TooltipCmp message="Carrier did not provide a rate for this additional service, for this additional service. Please validate feasibility and custom pricing.">
              <InfoCircle className="text-warning500 w-3.5 h-3.5" />
            </TooltipCmp>
          ) : (
            ''
          )}
        </div>
      </td>
      <td className="px-5 py-4">
        {data.carrierQuoteId
          ? currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.totalCostCAD)}`
            : `$${getFormattedNumber(data.totalCostUSD)}`
          : '-'}
      </td>
      <td className="px-5 py-4">
        {data.margin === 0
          ? '$0.00'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.margin)}`
          : `$${getFormattedNumber(data.marginUSD)}`}
      </td>

      <td className="px-5 py-4 text-sm">
        <span className="text-textSecondary font-bold">
          {data.carrierQuoteId
            ? currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data.finalCharge)} CAD`
              : `$${getFormattedNumber(data.finalChargeUSD)} USD`
            : '-'}
        </span>
      </td>

      <td className="px-5 py-4">
        {addressDetails.status === STATUS.ACTIVE ? (
          data.carrierQuoteId && (
            <div className="flex gap-3 ">
              {/* <ButtonCmp type="submit" className="btn_primary" onClick={handleActionType("informationModel", "", data)}>
                                                                                    Proceed
                                                                                </ButtonCmp> */}
              {data.poq && data.poqUrl ? (
                <TooltipCmp message="Download POQ">
                  <DownloadCloud02
                    className="w-4 h-4"
                    onClick={() =>
                      downloadPdf(
                        `${data.poqUrl}${data.poq}`,
                        `POQ-${data.poq}`
                      )
                    }
                  ></DownloadCloud02>
                </TooltipCmp>
              ) : (
                <span className="w-4 h-4">&nbsp;</span>
              )}
              <TooltipCmp message="Modify Pricing">
                <Edit05
                  className="w-4 h-4"
                  onClick={handleActionType('pricing', 'update', data)}
                ></Edit05>
              </TooltipCmp>
              <TooltipCmp message="Proceed to Book Order">
                <CheckSquareBroken
                  className="w-4 h-4 text-fgSuccessPrimary"
                  onClick={handleActionType('informationModel', '', data)}
                />
              </TooltipCmp>

              {/* <ButtonCmp type="submit" className="btn-outline-primary" onClick={handleActionType("pricing", "update", data)}>
                                                                                    Modify
                                                                                </ButtonCmp> */}
            </div>
          )
        ) : (
          <span className="px-3">
            <a
              className="text-primary underline font-semibold cursor-pointer"
              onClick={handleActionType('pricingDetails', '', data)}
            >
              View Details
            </a>
          </span>
        )}
      </td>
    </>
  );
};
export default QuotedRow;
